import React, { useState, useCallback, useMemo } from 'react'
import _ from 'lodash'
import {
  CardBody, CardFooter,
  Label,
  Col, Row,
  Form, Input, Button
} from 'reactstrap'
import { GrView, GrFormViewHide } from 'react-icons/gr'

import TransComponent from 'components/Trans'

const PWFieldData = [ 'password', 'confirm_password' ]

export default function UpdatePW ({
  selectedUser,
  updatePW
}) {
  const [ passwordForm, onChangeForm ] = useState({
    password: '',
    confirm_password: ''
  })
  const [ showPWList, onChangePWList ] = useState([])

  let onChangeField = useCallback(( context, val ) => {
    onChangeForm({
      ... passwordForm,
      [context]: val
    })
  }, [passwordForm])

  let onShowPWValue = useCallback( val => {
    let temp = _.cloneDeep( showPWList )
    if ( showPWList.indexOf( val ) > -1 ){
    _.remove( temp, listItem => listItem === val )
    } else {
      temp.push( val )
    }

    onChangePWList ( temp )
  }, [showPWList])

  let disableSubmit = useMemo(() => {
    return _.values( passwordForm ).some( item => !item ) || ( passwordForm.password !== passwordForm.confirm_password )
  }, [passwordForm])

  
  return (
    <>
      <CardBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <Row>
            {
              PWFieldData.map( field => (
                <Col md={ 6 }>
                  <Label className={ 'd-flex' }>
                    <TransComponent i18nKey={ `user.fields.${ field }`}/>
                    <div 
                      style={{ width: 14, height: 14, marginLeft: 10, cursor: 'pointer' }}
                      onClick={ () => onShowPWValue( field )}>
                      {
                        showPWList.indexOf( field ) > -1
                          ? <GrView style={{ width: '100%', height: '100%' }}/>
                          : <GrFormViewHide style={{ width: '100%', height: '100%' }}/>
                      }
                    </div>
                  </Label>
                  <Input
                    type={ showPWList.indexOf( field ) > -1 ? 'text' : 'password' }
                    value={ passwordForm[ field ] }
                    onChange={ e => onChangeField( field, e.target.value )} 
                  />
                </Col>
              ))
            }
          </Row>
        </Form>
      </CardBody>
      <CardFooter>
        <Button 
          color="primary"
          className="ml-auto"
          disabled={ disableSubmit }
          onClick={() => updatePW( selectedUser.id, passwordForm )}>
          <TransComponent i18nKey={ `general.update` }/>
        </Button>
      </CardFooter>
    </>
  )
}
