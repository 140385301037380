import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const TransComponent = ({
  i18nKey
}) => {
  const { t } = useTranslation();
  return (
    <Trans
      t={ t }
      i18nKey={ i18nKey }
      defaults=''
    />
  );
};

export default TransComponent;