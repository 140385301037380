export const DefaultForm = {
  first_name: '',
  last_name: '',
  email: '',
  role_id: '',
  password: '',
  confirm_password: '',
  mobile_contact: ''
}

export const DefaultFields = mode => [
  { 
    required: true, value: 'first_name',
    type: 'text' 
  },
  { 
    required: true, value: 'last_name',
    type: 'text' 
  },
  { 
    required: true,
    value: 'email' 
  },
  { 
    value: 'mobile_contact' 
  },
  { 
    value: 'role_id', 
    transKey: 'role',
    type: 'select', 
    required: true,
    options: [
      { value: 1, label: 'Superadmin' }
    ] 
  },
  ... mode === 'create' ? [{ 
    required: true,
    value: 'password',
    type: 'password'
  },
  { 
    required: true,
    value: 'confirm_password',
    type: 'password'
  }] : []
]