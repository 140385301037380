import React, { useState } from 'react'
import CreateNewLang from './createNewLang'
import AssignTranslated from './assignTranslated'

import {
  ModalHeader, ModalBody,
  Button,
  Card, CardBody
} from 'reactstrap'

import TransComponent from 'components/Trans'

export default function Index({
  onClose,
  ... props
}) {

  // const [ currentTab, setCurrentTab ] = useState( 'create_new' )

  return (
    <>
      <ModalHeader toggle={ () => onClose()}>
        <TransComponent i18nKey={ 'form.desc.add_lang' }/> 
      </ModalHeader>
      <ModalBody>
        {/* <div className="d-flex flex-wrap mb-3">
          <Button 
            color={ currentTab === 'translated' ? 'primary' : 'secondary' }
            className={ 'mr-2' }
            onClick={ () => setCurrentTab( 'translated' )}
          >
            <TransComponent i18nKey={ 'form.desc.assign_translated' }/>
          </Button>
          <Button 
            color={ currentTab === 'create_new' ? 'primary' : 'secondary' }
            onClick={ () => setCurrentTab( 'create_new' )}
          >
            <TransComponent i18nKey={ 'form.desc.create_new_lang' }/>
          </Button>
        </div> */}
        <Card>
          <CardBody>
            <CreateNewLang { ... props }/>
            {/* {
              currentTab === 'create_new' && (
                <CreateNewLang { ... props }/>
              )
            }
            {
              currentTab === 'translated' && (
                <AssignTranslated { ... props } />
              )
            } */}
          </CardBody>
        </Card>
      </ModalBody>
    </>
  )
}
