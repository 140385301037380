import React from 'react'
import { 
  Modal, 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label
} from 'reactstrap'
import _ from 'lodash'
import TransComponent from 'components/Trans'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

const Confirmation = ({
  open,
  titleKey,
  confirmKey,
  closeKey,
  loading,
  messageKey,

  onClose,
  onClickConfirm
  }) => {
  
    return (
      <Modal
        isOpen={ open }
        size={ 'xl' }
        toggle={() => onClose() }
      >
        <ModalHeader toggle={() =>onClose()}>
          <TransComponent i18nKey={ titleKey }/>
        </ModalHeader>
        <ModalBody>
          <Label>
            <TransComponent i18nKey={ messageKey }/>
          </Label>
        </ModalBody>
        <ModalFooter>
          <Button 
            color="primary"
            className="mr-2"
            onClick={() => onClickConfirm()}
          >
            <TransComponent i18nKey={ confirmKey || "general.confirm"}/>
          </Button>
          <Button 
            color="danger"
            onClick={() => onClose()}
          >
            <TransComponent i18nKey={ closeKey || "general.dismiss"}/>
          </Button>
        </ModalFooter>
        { loading && <LoadingOverlay/> }
      </Modal>
    )
  }
  
  export default Confirmation