import React from 'react'
import TransComponent from 'components/Trans'

export default function RendererDesc({
  currentTab
}) {
  return (
    <>
      {
        currentTab === 'form_content' && (
          <>
            <p className='mb-0'>
              { `- `}
              <TransComponent i18nKey={ 'form.desc.edit_form_content' }/>
            </p>
            <p className='mb-0'>
              { `- `}
              <TransComponent i18nKey={ 'form.desc.only_new_field_removed'}/>
            </p>
            <p>
              { `- `}
              <TransComponent i18nKey={ 'form.desc.only_new_field_edit_visibility'}/>
            </p>
          </>
        )
      }
      {
        currentTab === 'terms_content' && 
        <p>
          { `- `}
          <TransComponent i18nKey={ 'form.desc.editing_term_content'}/>
        </p>
      }
      {
        currentTab === 'reporting_procedure_modal' && 
        <p>
          { `- `}
          <TransComponent i18nKey={ 'form.desc.editing_reporting_procedure_modal'}/>
        </p>
      }
      {
        currentTab === 'data_remain_anonymous_modal' && 
        <p>
          { `- `}
          <TransComponent i18nKey={ 'form.desc.editing_anonymous_modal'}/>
        </p>
      }
      {
        currentTab === 'case_success_content' && 
        <p>
          { `- `}
          <TransComponent i18nKey={ 'form.desc.editing_submit_success_content'}/>
        </p>
      }
    </>
  )
}
