import React, { Component } from 'react'
import _ from 'lodash'
import { Get, GetFile, Put } from 'utils/axios'
import { requestError, requestSuccess } from 'utils/requestHandler'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      selectedCase: null,
      showDetailsModal: false,
      showCloseCaseModal: false,

      cases: [],
      unseenCases: [],
      inProgressCases: [],
      doneCases: [],
      pinnedCases: [],
      urgentCases: [],
      closedCases: [],

      currentViewCases: [],
      filterMode: 'unseen',
      searchKeyword: ''
    }

    componentDidMount = () => {
      this.debounceService = _.debounce( this.debounceService, 300 );
    }

    onChangeCaseHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    getCases = () => Get(
      `/cases`,
      this.getCasesSuccess,
      this.getCasesError,
      this.load
    )
    getCasesSuccess = payload => {
      let tempUrgent = []
      let tempPinned = []
      let tempUnseen = []
      let tempProgress = []
      let tempDone = []
      let tempClosed = []
      let tempPayload = []

      payload.map( item => {
        let tempCompany = _.find( this.props.companies, companyItem => companyItem.id === item.company_id )
        let tempCategory = _.find( this.props.caseCategory, catItem => catItem.id === item.category_id )
        let tempCase = {
          ... item,
          company_info: tempCompany,
          category_name: tempCategory?.name??'N/A'
        }

        if ( item.is_closed ){  
          tempClosed.push( tempCase )
        }
        
        if ( item.is_urgent && !item.is_closed ){
          tempUrgent.push( tempCase )
        }
        if ( item.is_pinned && !item.is_closed ){
          tempPinned.push( tempCase )
        }

        if ( item.status === 'NEW' && !item.is_closed ){
          tempUnseen.push( tempCase )
        }
        if( item.status === 'VIEWED' && !item.is_closed ){
          tempProgress.push( tempCase )
        }
        if( item.status === 'REPLIED' && !item.is_closed ){
          tempDone.push( tempCase )
        }

        tempPayload.push( tempCase )
      })
      this.setState({
        cases: tempPayload,
        unseenCases: tempUnseen,
        inProgressCases: tempProgress,
        doneCases: tempDone,
        closedCases: tempClosed,
        pinnedCases: tempPinned,
        urgentCases: tempUrgent,
      }, () => {
        this.setState({
          currentViewCases: this.onAssignCurrentViewCases( this.state.filterMode )
        })
      })
    }
    getCasesError = error => requestError( error )

    getCurrentCase = () => Get(
      `/cases/current`,
      this.getCurrentCaseSuccess,
      this.getCurrentCaseError,
      this.load
    )
    getCurrentCaseSuccess = payload => this.setState({ 
      selectedCase: payload
    })
    getCurrentCaseError = error => requestError( error )


    getSelectedCase = id => Get(
      `/cases/${ id }`,
      this.getSelectedCaseSuccess,
      this.getSelectedCaseError,
      this.load
    )
    getSelectedCaseSuccess = payload => {
      let tempCompany = _.find( this.props.companies, companyItem => companyItem.id === payload.company_id )

      this.setState({ 
        selectedCase: {
          ... payload,
          company_info: tempCompany
        },
        showDetailsModal: true
      })
    }
    getSelectedCaseError = error => requestError( error )

    onAssignCurrentViewCases = val => {

      if ( val === 'progress' ){
        return this.state.inProgressCases
      }
      if ( val === 'done' ){
        return this.state.doneCases
      }
      if ( val === 'unseen' ){
        return this.state.unseenCases 
      }

      if ( val === 'closed' ){
        return this.state.closedCases
      }

      return this.state.cases
    }

    updateCaseStatus = ( id, val ) => Put(
      `/cases/${ id }/update_status`,
      { status: val },
      this.updateCaseStatusSuccess,
      this.updateCaseStatusError,
      this.load
    )
    updateCaseStatusSuccess = ( payload, toastFlag ) => {
      this.getCases()
      this.getSelectedCase( payload.id )
      this.setState({ 
        showCloseCaseModal: false
      })
    }
    updateCaseStatusError = error => requestError( error )

    updateCase = ( dataToSubmit, toastFlag ) => Put(
      `/cases/${ dataToSubmit.id }`,
      dataToSubmit,
      payload => this.updateCaseSuccess( payload, toastFlag ),
      this.updateCaseError,
      this.load
    )
    updateCaseSuccess = ( payload, toastFlag ) => {
      this.getCases()
      toastFlag && requestSuccess( 'Case info has been updated successfully.' )
      this.getSelectedCase( payload.id )
      this.setState({ 
        showCloseCaseModal: false
      })
    }
    updateCaseError = error => requestError( error )

    onChangeSearchValue = val => {
      let temp = this.onAssignCurrentViewCases( this.state.filterMode )

      this.setState({
        searchKeyword: val,
      }, () => {
        if ( val.length > 0 ){
          return this.debounceService( val, this.state.cases ); 
        }

        return this.setState({ currentViewCases: temp })
      });
    }

    debounceService = ( val, array ) => {
      let temp = _.filter( array, arrayItem => (
        arrayItem.uid.indexOf( val ) > -1
      ))

      this.setState({ currentViewCases: temp })
    }

    downloadCase = ( selectedCase, lang ) => GetFile(
      `/cases/${ selectedCase.id }/download/${ lang }`,
      lang === 'en' ? `Case_File_${ selectedCase.uid }` : `Akte_zum_Fall_${ selectedCase.uid }`,
      () => {},
      this.downloadCaseError,
      this.load
    )
    downloadCaseError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadCase={ this.state.loading }  
          filterMode={ this.state.filterMode }
          searchKeyword={ this.state.searchKeyword }
          
          cases={ this.state.cases }
          closedCases={ this.state.closedCases }
          unseenCases={ this.state.unseenCases }
          inProgressCases={ this.state.inProgressCases }
          doneCases={ this.state.doneCases }
          pinnedCases={ this.state.pinnedCases }
          urgentCases={ this.state.urgentCases }
          currentViewCases={ this.state.currentViewCases }

          selectedCase={ this.state.selectedCase }
          showCloseCaseModal={ this.state.showCloseCaseModal }
          showDetailsModal={ this.state.showDetailsModal }

          getCurrentCase={ this.getCurrentCase }
          downloadCase={ this.downloadCase }
          onAssignCurrentViewCases={ this.onAssignCurrentViewCases }
          onChangeCaseHOC={ this.onChangeCaseHOC }
          onChangeSearchValue={ this.onChangeSearchValue }
          updateCase={ this.updateCase }
          updateCaseStatus={ this.updateCaseStatus }
          getCases={ this.getCases }
        />
      )
    }
  }
  return WithHOC
}

export default HOC