import React, { useMemo, useState } from 'react'
import { 
  ModalHeader,
  ModalBody,
  Card
} from 'reactstrap'
import _ from 'lodash'
import TransComponent from 'components/Trans'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import UserForm from './form'
import ChangePWForm from './changePw'
import CompanyForm from './company'

const FormContent = props => {
  const [ activeTab, onChangeActiveTab ] = useState( 1 )
  const { role_id: currentRoleID } = props.data.ProfileReducer.profile

  let tempTabs = useMemo(() => {
    return [
      { 
        label: <TransComponent i18nKey="user.tabs.user"/>,
        value: 1
      },
      {
        label: <TransComponent i18nKey="user.tabs.change_pw"/>,
        value: 2
      },
      ... currentRoleID !== 4 && props.selectedUser.role_id === 4 ? [
        {
          label: <TransComponent i18nKey="user.tabs.company"/>,
          value: 3
        }
      ] : []
    ]
  }, [])

  return (
    <>
      <ModalHeader toggle={() => props.onClose()}>
        <TransComponent i18nKey={ `user.title.update`}/>
      </ModalHeader>
      <ModalBody>
        <div className="d-flex mb-3">
          {
            tempTabs.map( tab => (
              <button
                key={ tab }
                className={ `btn mr-2 ${ activeTab === tab.value ? 'btn-primary' : 'btn-outline-primary' }` }
                onClick={ () => onChangeActiveTab( tab.value )}
              >
                { tab.label }
              </button>
            ))
          }
        </div>
        <Card>
          {
            activeTab === 1 && (
              <UserForm { ... props }/>
            )
          }
          {
            activeTab === 2 && (
              <ChangePWForm { ... props }/>
            )
          }
          {
            currentRoleID !== 4 && 
            activeTab === 3 && (
              <CompanyForm { ... props }/>
            )
          }
        </Card>
        { props.onLoadUsers && <LoadingOverlay />}
      </ModalBody>
    </>
  )
}

export default FormContent