import React, { useRef } from 'react'
import _ from 'lodash'
import { 
  Button,
  Card, CardBody, Collapse,
  Form,  FormGroup, Input, Label
} from 'reactstrap'
import { AiOutlinePlus } from 'react-icons/ai'
import { BsFillTrashFill } from 'react-icons/bs'

import TransComponent from 'components/Trans'

function OptionsForm({
  options,
  langOptions,
  
  currentFormLang,
  onChangeTextFormField
}) {

  const contRef = useRef()

  const onChangeOptions = ( index, context, val ) => {
    let temp = _.cloneDeep( options )
    temp[ index ][ context ] = val

    onChangeTextFormField( 'options', temp )
  }

  const onRemoveOption = index => {
    let temp = _.cloneDeep( options )
    _.pullAt( temp, [ index ])

    onChangeTextFormField( 'options', temp )
  } 

  return (
    <>
      <hr />
      <div className="d-flex align-items-center mb-3">
        <h6 className='mb-0'>Options</h6>
        <Button
          size={ 'sm' }
          className={ 'ml-2' }
          style={{ padding: '0.2rem 0.4rem' }}
          onClick={() => {
            let temp = _.cloneDeep( options )
            let tempOption = {
              "value": `option_${ options.length + 1 }`,
              "label": {}
            }

            langOptions.map( lang => {
              tempOption.label = {
                ... tempOption.label,
                [ lang.value ]: `Option`
              }
            })

            temp.push( tempOption )
            onChangeTextFormField( 'options', temp )
            contRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
          }}
          color={ 'primary' }
        >
          <AiOutlinePlus/>
        </Button>
      </div>
      {
        options.map(( optionItem, optionIndex ) => (
          <OptionCard
            key={ `option_${ optionIndex }`}
            optionItem={ optionItem }
            showDelete={ options.length > 2 }
            currentFormLang={ currentFormLang }
            onClickRemoveOption={ () => onRemoveOption( optionIndex )}
            onChangeOptions={( context, val ) => onChangeOptions( optionIndex, context, val ) }
          />
        ))
      }
      <div ref={ contRef }/>
    </>
  )
}

const OptionCard = ({
  showDelete,
  optionItem,
  currentFormLang,
  onChangeOptions,
  onClickRemoveOption
}) => {
  return (
    <Card className='mt-2'>
      <CardBody>
        {
          showDelete && (
            <div className="d-flex">
              <Button 
                color='danger'
                size={ 'sm' }
                className='ml-auto'
                onClick={ () => onClickRemoveOption()}
              >
                <BsFillTrashFill/>
              </Button>
            </div>
          )
        }
        <Form>
          <FormGroup>
            <Label>
              <TransComponent i18nKey={ 'general.value' }/>
            </Label>
            <Input
              type={ 'text' }
              value={ optionItem.value }
              onChange={ e => onChangeOptions( 'value', e.target.value )}
            />
          </FormGroup>
          <FormGroup>
            <Label>
              <TransComponent i18nKey={ 'general.label' }/>
            </Label>
            <Input
              type={ 'text' }
              value={ optionItem.label[ currentFormLang ]}
              onChange={ e => onChangeOptions( 'label', {
                ... optionItem.label,
                [ currentFormLang ]: e.target.value
              })}
            />
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  )
}

export default OptionsForm