import React, { useEffect } from 'react'
import RemarkHOC from '../../../actions/remark'
import { 
  Card, CardHeader, CardBody,
  Modal, Button,
  Input, UncontrolledTooltip
} from 'reactstrap'

import { AiFillDelete } from 'react-icons/ai'
import { BiEditAlt } from 'react-icons/bi'
import { RiAddBoxLine } from 'react-icons/ri'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import ConfirmationModal from 'components/Modal/confirmation'
import TransComponent from 'components/Trans'

import CreateContent from './Create'
import EditContent from './Edit'

import './index.scss'

const RemarkContent = ({
  onLoadRemark,
  remarks = [],
  selectedRemark,
  selectedCase,
  showEditModal,
  showCreateModal,
  showDeleteModal,
  tempCreateRemark,

  getRemarks,
  createRemark,
  updateRemark,
  deleteRemark,
  onChangeRemarkHOC,
  ... props
}) => {

  useEffect(() => {
    getRemarks( selectedCase.id )
  }, [])

  return (
    <>
      <Card> 
        <CardHeader>
          <TransComponent i18nKey={ 'lawyer_case.tabs.remark' }/>
          {
            !selectedCase.is_closed && (
              <>
                <RiAddBoxLine   
                  id="AddType"
                  style={{ width: 20, height: 20, marginLeft: 7, cursor: 'pointer' }}
                  onClick={() => {
                    onChangeRemarkHOC( 'showCreateModal', true )
                  }}
                />
                <UncontrolledTooltip target="AddType" placement="top">
                  <TransComponent i18nKey={ `lawyer_case.tooltip.remark_create` }/>
                </UncontrolledTooltip>
              </>
            )
          }
        </CardHeader>
        <CardBody>
          {
            remarks.length < 1 && (
              <p className='mb-0'>
                <TransComponent i18nKey={ 'lawyer_case.message.remark_empty' }/>
              </p>
            )
          }
          {
            remarks.length > 0 && (
              <div className="bkp-remark-mansory">
                {
                  remarks.map( remarkChild => (
                    <Card key={ remarkChild.id } style={{ cursor: 'pointer' }}>
                      <CardHeader className='d-flex flex-wrap'>
                        <span>
                          <small>
                            <TransComponent i18nKey={ 'lawyer_case.fields.written_by' }/>:
                          </small>
                          <br />
                          <strong>{ remarkChild?.user??'' }</strong>
                        </span>
                        <span className='ml-auto mr-2' style={{ textAlign: 'end' }}>
                          <small>
                            <TransComponent i18nKey={ 'lawyer_case.fields.last_modified' }/>:
                          </small>
                          <br />
                          <strong>
                            { remarkChild?.updated_at??'N/A' }
                          </strong>
                        </span>
                      </CardHeader>
                      <CardBody>
                        <div className="d-flex ml-auto mb-3">
                          <Button style={{ padding: '0.25rem' }} className={ 'mr-2' }  color={ 'secondary' } onClick={ () => {
                            onChangeRemarkHOC( 'selectedRemark', remarkChild )
                            onChangeRemarkHOC( 'showEditModal', true )
                          }}>
                            <BiEditAlt style={{ width: 17, height: 17 }}/>
                          </Button>
                          <Button style={{ padding: '0.25rem' }} color={ 'danger' } onClick={ () => {
                            onChangeRemarkHOC( 'showDeleteModal', true )
                            onChangeRemarkHOC( 'selectedRemark', remarkChild )
                          }}>
                            <AiFillDelete style={{ width: 17, height: 17 }}/>
                          </Button>
                        </div>
                        <Input
                          type={ 'textarea'}
                          style={{ background: 'transparent', fontSize: '0.8rem' }}
                          value={ remarkChild.content } 
                          rows={ 10 }
                          disabled={ true }
                        />
                      </CardBody> 
                    </Card>
                  ))
                }
              </div>
            )
          }
          <Modal size='md' isOpen={ showEditModal }>
            <EditContent
              { ... props }
              selectedCase={ selectedCase }
              selectedRemark={  selectedRemark }
              onLoadRemark={ onLoadRemark }
              updateRemark={ updateRemark }
              onClose={ () => onChangeRemarkHOC( 'showEditModal', false )}
              />
          </Modal>
          <Modal size='md' isOpen={ showCreateModal }>
            <CreateContent
              { ... props }
              selectedCase={ selectedCase }
              onLoadRemark={ onLoadRemark }
              createRemark={ createRemark }
              onClose={ () => onChangeRemarkHOC( 'showCreateModal', false )}
              />
          </Modal>
          <ConfirmationModal
            open={ showDeleteModal }
            titleKey={ 'lawyer_case.title.delete_remark' } 
            messageKey={ 'lawyer_case.desc.delete_remark' } 
            onClose={ () => onChangeRemarkHOC( 'showDeleteModal', false )}
            onClickConfirm={ () => deleteRemark( selectedCase.id, selectedRemark.id )} 
            />
        </CardBody>
      </Card>
      { onLoadRemark && <LoadingOverlay/> }
    </>
  )
}

export default RemarkHOC( RemarkContent )
