import React, { useState } from 'react'
import { 
  ModalHeader,
  ModalBody,
  Card
} from 'reactstrap'
import _ from 'lodash'
import TransComponent from 'components/Trans'

import CompanyForm from './form'

const FormContent = props => {
  return (
    <>
      <ModalHeader toggle={() => props.onClose()}>
        <TransComponent i18nKey={ `company.title.update`}/>
      </ModalHeader>
      <ModalBody>
        <Card>
          <CompanyForm { ... props }/>
        </Card>
      </ModalBody>
    </>
  )
}

export default FormContent