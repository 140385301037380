import React, { useState, useCallback } from 'react'
import _ from 'lodash'
import { 
  ModalHeader, ModalBody, ModalFooter,
  Button,
  Card, CardBody
} from 'reactstrap'

import { DefaultNewField } from '../../assets'
import RenderedField from '../Renderer/RenderedField'
import TransComponent from 'components/Trans'
import EditTextFieldForm from './EditTextFieldForm'
import OptionsForm from '../OptionsForm'

export default function AddTextField ({
  formData,
  onSubmit,
  onClose,
  occupiedKeys
}) {
  const { language_options: langOptions } = formData
  const [ textFieldForm, setFieldForm ] = useState( DefaultNewField )
  const [ currentFormLang, setFormLang ] = useState( 'de' ) 

  const onChangeTextFormField = useCallback(( key, val ) => {
    let temp = _.cloneDeep( textFieldForm )

    temp[ key ] = val

    if ( key === 'variant' ){
      if ( val === 'select' || val === 'radiobutton' || val === 'multi-checkbox' ) {

        let tempLabel = {}
        langOptions.map( lang => {
          tempLabel[ lang.value ] = `Option`
        })
          
        temp = {
          ...temp,
          options: [
            {
              "value": "option_1",
              "label": tempLabel
            },
            {
              "value": "option_2",
              "label": tempLabel
            }
          ]
        }
      } else {
        delete temp.options
      }
    }

    setFieldForm( temp )
  }, [ textFieldForm ])

  return (
    <>
      <ModalHeader toggle={ () => onClose()}>
        <TransComponent i18nKey={ 'form.desc.add_new_field' }/>
      </ModalHeader>
      <ModalBody>
        <LanguageSwitcher  
          langOptions={ langOptions }
          currentFormLang={ currentFormLang }
          setFormLang={ setFormLang }
        />
        <Card 
          className='mt-3' 
          style={{ background: formData.form_content.backgroundStyle.background }}
        >
          <CardBody>
            <RenderedField 
              mode={ 'read' }
              formData={ formData }
              currentLang={ currentFormLang }
              json={ textFieldForm }
            />
          </CardBody>
        </Card>
        <EditTextFieldForm
          occupiedKeys={ occupiedKeys }
          json={ textFieldForm }
          currentLang={ currentFormLang }
          onChangeFieldForm={ onChangeTextFormField }
        />
        {
          ( [ 'select', 'radiobutton', 'multi-checkbox' ].indexOf( textFieldForm.variant ) > -1 ) && (
            <OptionsForm
              langOptions={ langOptions }
              options={ textFieldForm.options }
              currentFormLang={ currentFormLang }
              onChangeTextFormField={ onChangeTextFormField }
            />
          )
        }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          disabled={ !textFieldForm.key || occupiedKeys.indexOf( textFieldForm.key ) > -1 }
          onClick={() => onSubmit( textFieldForm )}>
          <TransComponent i18nKey="general.submit"/>
        </Button>
      </ModalFooter>
    </>
  )
}

const LanguageSwitcher = ({
  langOptions,
  currentFormLang,
  setFormLang
}) => {
  return (
    <div 
      className="d-flex flex-wrap ml-auto justify-content-end p-2 rounded" 
      style={{ width: 'max-content', background: 'rgba(0,0,0,0.2)' }}
    >
      {
        langOptions.map(( option, index )=> (
          <Button
            key={ index }
            size={ 'sm' }
            style={{ textTransform: 'uppercase', position: 'relative' }}
            className={ index !== langOptions.length - 1 ? 'mr-2' : '' }
            onClick={() => setFormLang( option.value )}
            color={ currentFormLang === option.value ? 'primary' : 'secondary' }
          >
            { option.value }
          </Button>
        ))
      }
    </div>  
  )
}