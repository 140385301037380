import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { Get } from 'utils/axios'
import { requestError } from 'utils/requestHandler'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      types: []
    }

    load = param => this.setState({ loading: param })

    getTypes = () => Get(
      `/types`,
      this.getTypesSuccess,
      this.getTypesError,
      this.load
    )
    getTypesSuccess = payload => {
      let temp = _.map( payload, item => ({
        ... item,
        en_name: item?.name?.en??'N/A',
        de_name: item?.name?.de??'N/A'
      }))
      this.setState({ types: temp })
    }
    getTypesError = error => requestError( error )
    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          types={ this.state.types }
          onLoadTypes={ this.state.loading }

          getTypes={ this.getTypes }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC