import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Get, Put } from 'utils/axios'
import { requestError, requestSuccess } from 'utils/requestHandler'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      forms: [],
      selectedForm: {},
      selectedCompany: null,

      showUpdateModal: false,
      searchParams: [
				{
          transKey: 'lawyer_case.fields.company',
					value: 'company_id',
					type: 'select',
					col: 6,
          options: [],
					param: null
				}
			],
      companies: []
    }

    onChangeFormHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getForms = query => {
      this.setState({ query }, () => {
        Get(
          `/forms?query=${query}`,
          this.getFormsSuccess,
          this.getFormsError,
          this.load
        )
      })
    }
    getFormsSuccess = payload => this.setState({ forms: payload.data })
    getFormsError = error => requestError( error )

    getCompanies = () => {
			Get(
        `/companies`,
        this.getCompanysSuccess,
        this.getCompanysError,
        this.load
      );
		}
		getCompanysSuccess = (payload) => this.setState({ companies: payload.data });
		getCompanysError = (error) => requestError(error);

    getCompanyForm = companyId => Get(
      `/companies/${ companyId }/forms`,
      this.getCompanyFormSuccess,
      this.getCompanyFormError,
      this.load
    )
    getCompanyFormSuccess = payload => this.setState({ selectedForm: payload[0] })
    getCompanyFormError = error => requestError ( error )

    getSelectedCompany = companyId => Get(
      `/companies/${companyId}`,
      payload => this.setState({selectedCompany: payload}),
      error => this.requestError(error),
      this.load
    )

    getSelectedForm = id => Get(
      `/forms/${ id }`,
      this.getSelectedFormSuccess,
      this.getSelectedFormError,
      this.load
    )
    getSelectedFormSuccess = payload => this.setState({ 
      selectedForm: payload,
      showUpdateModal: true
    })
    getSelectedFormError = error => requestError ( error )

    updateForm = dataToSubmit => Put(
      `/companies/${ dataToSubmit.company_id }/forms/${ dataToSubmit.id }`,
      dataToSubmit,
      this.updateFormSucces,
      this.updateFormError,
      this.load
    )
    updateFormSucces = payload => {
      this.getSelectedForm( payload.id )
      requestSuccess( 'Form has been updated successfully.' )
    }
    updateFormError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          forms={ this.state.forms }
          companies={this.state.companies}
          selectedCompany={this.state.selectedCompany}
          selectedForm={ this.state.selectedForm }
          onLoadForms={ this.state.loading }
          showUpdateModal={ this.state.showUpdateModal }
          searchParams={this.state.searchParams}

          getCompanies={this.getCompanies}
          getForms={ this.getForms }
          getSelectedCompany={this.getSelectedCompany}
          getCompanyForm={ this.getCompanyForm }
          getSelectedForm={ this.getSelectedForm }
          updateForm={ this.updateForm }
          onChangeFormHOC={ this.onChangeFormHOC }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC