import React, { Component } from 'react'

import { Get } from 'utils/axios'
import { convertObjToBase64 } from 'utils/convertbase64'
import { requestError } from 'utils/requestHandler'

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      companies: [],
      forms: []
    }

    onChangeCompanyHOC = (key, val) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getCompanies = () => Get(
      `/companies`,
      this.getCompanysSuccess,
      this.getCompanysError,
      this.load
    )
    getCompanysSuccess = payload => this.setState({ companies: payload.data })
    getCompanysError = error => requestError(error)

    getForms = () => {
      Get(
        `/forms?query=${convertObjToBase64({ "page": 0 })}`,
        this.getFormsSuccess,
        this.getFormsError,
        this.load
      )
    }
    getFormsSuccess = payload => this.setState({ forms: payload.data })
    getFormsError = error => requestError(error)

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          forms={this.state.forms}
          companies={this.state.companies}
          onLoadCompany={this.state.loading}

          getForms={this.getForms}
          getCompanies={this.getCompanies}
          onChangeCompanyHOC={this.onChangeCompanyHOC}
        />
      )
    }
  }
  return WithHOC;
}

export default HOC