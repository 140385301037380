import React, { useRef } from 'react'
import { 
  Card, CardHeader, CardBody, CardFooter, 
  Button, Input 
} from 'reactstrap'
import { BsFillPersonFill } from 'react-icons/bs'
import { MdSend, MdOutlineAttachFile } from 'react-icons/md'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import Loader  from 'react-loaders'

import TransComponent from 'components/Trans'
import { getItem } from 'utils/tokenStore'

const Message = ({
  data,
  selectedCase,
  caseMessages = [],
  onLoadAttachment,
  onLoadSending,
  typedContent,

  updateCaseStatus,
  createCaseAttachment,
  onChangeMessageHOC,
  sendMessage
}) => {

  let tempUserType = getItem( 'BPK_USER_TYPE' )

  const cardBodyBottom = useRef( null )
  let fileInputRef = useRef( null )

  const onDropFile = event => {
    if( event.target.files[0] ) {
      let reader = new FileReader();
      reader.onload = e => {
        Promise.all([
          createCaseAttachment( 
            selectedCase.id, 
            { 
              file: e.target.result,
              file_name: event.target.files[0].name,
              sender_type_id: tempUserType === 'case'  ? 2 : 1,
              sender_id: tempUserType === 'case' ? selectedCase.id : data.ProfileReducer.profile.id
            }
          )
        ]).then(() => {
          fileInputRef.current.value = ''
        })
      };
      reader.readAsDataURL( event.target.files[0] );
    }
  }

  return (
    <>
      <Card 
        style={ selectedCase.is_closed ? { filter: `brightness(0.7)`, cursor: 'not-allowed' } : {}}
      >
        <CardHeader style={{ position: 'relative' }}>
          <TransComponent i18nKey="lawyer_case.tabs.message"/>
          {/* { ` (${ selectedCase.message_count })`} */}
        </CardHeader>
        <CardBody 
          style={{ 
            maxHeight: '60vh', minHeight: 200, overflowY: 'scroll', 
            display: 'flex', 
            flexDirection: 'column' 
          }}
        >
          {
            caseMessages.map(( item, index ) => (
              <div
                key={ `message=${ index }`}
                style={{
                background: item.sender_type === "Case" ? 'rgb(230 229 229)' : 'rgb(0,132,255)',
                borderRadius: 20,
                marginLeft: item.sender_type === "Case" ? 'initial': 'auto',
                maxWidth: '425px',
                marginBottom: index === ( caseMessages.length -1 ) ? 0 : 10,
                borderBottomRightRadius: item.sender_type === "Case" ? 25 : 3,
                borderBottomLeftRadius: item.sender_type === "Case" ? 3 : 25,
                padding: '10px 20px',
                width: '-webkit-fit-content',
                textAlign: item.sender_type === "Case" ? 'start' : 'end',
                float: item.sender_type === "Case" ? 'left' : 'right',
                cursor: item.messageType === 'file' ? 'pointer' : 'inital'
              }}
              onClick={ () => { 
                item.messageType === 'file' && window.open( item.url )
              }}
            >
                {
                  tempUserType !== 'case' && (
                    <div 
                      className="d-flex justify-content-end"
                      style={{ flexDirection: item.sender_type === "Case" ? 'row-reverse' : 'row' }}
                    >
                      <strong 
                        className={ `${ item.sender_type === "Case" ? 'ml-2' : 'mr-2' }`}
                        style={{ 
                          fontSize: 12,
                          color: item.sender_type === "Case" ? '#000000' : '#fff', 
                        }}
                      >
                        { item.sender }
                      </strong>
                      <BsFillPersonFill style={{ width: 15, height: 15 }}/>
                    </div>
                  )
                }
                {
                  item.messageType === 'file' && (
                    <div className='mb-2 mt-2'>
                      {
                        item.type.indexOf( 'Image' ) > -1 && (
                          <img 
                            src={ item.url } 
                            style={{ 
                              width: 300, height: 120, 
                              objectFit: 'cover',
                              borderRadius: '5px 5px 0px 0px',
                            }} alt={ item.name } 
                          />
                        )
                      }
                      {
                        item.type.indexOf( 'Image' ) < 0 && (
                          <iframe 
                            scrolling="no" src={ item.url } 
                            style={{ width: 300, height: 120, marginBottom: -5 }} 
                            frameBorder="0"/>
                        )
                      }
                      <div 
                        className="d-flex justify-content-between bg-secondary p-2 text-white flex-wrap"
                        style={{
                          gap: 10,
                          maxWidth: 300,
                          borderRadius: '0px 0px 5px 5px'
                        }}
                      >
                        <span style={{ fontSize: 12, fontWeight: 500, textAlign: 'initial' }}>
                          { item.name }
                        </span>
                        <span style={{ fontSize: 12, fontWeight: 500 }}>
                          { item.type }
                        </span>
                      </div>
                    </div>
                  )
                }
                {
                  item.messageType === 'text' && (
                    <p style={{ 
                      color: item.sender_type === "Case" ? '#000000' : '#fff', 
                      fontSize: 16, fontWeight: 300, 
                      marginTop: 5,
                      whiteSpace: 'pre-line'
                    }}>
                      { item.description }
                    </p>
                  )
                }
                <p className='mb-0' style={{ 
                  fontSize: '12px', color: item.sender_type === "Case" ? '#383535' : '#fff', 
                  marginLeft: 'auto', fontWeight: '600', textAlign: 'right' 
                }}>
                  { item.created_at }
                </p>
              </div>
            ))
          }
          <div
            style={{ float:"left", clear: "both" }}
            ref={ cardBodyBottom }/>
          <input
            ref={ fileInputRef }
            type={ 'file' }
            onChange={ event => onDropFile( event ) }
            style={{ display: 'none' }}
            accept={['image/png', 'image/jpg', 'image/jpeg', "application/pdf" ]}
          />
        </CardBody>
        <CardFooter className='d-flex align-items-end'>
          <Input
            type={ 'textarea'}
            value={ typedContent } 
            rows={ 4 }
            disabled={ selectedCase.is_closed || onLoadSending }
            onChange={ e => onChangeMessageHOC( 'typedContent', e.target.value )}
          />
          <div className='d-flex flex-column'>
            <Button
              size="sm"
              color="light"
              disabled={ selectedCase.is_closed }
              style={{ marginLeft: 10, marginBottom: 10 }}
              onClick={() => {
                fileInputRef.current.click()
              }}
            >
              <MdOutlineAttachFile style={{ width: 20, height: 20 }}/>
            </Button>
            {
              onLoadSending && (
                <Loader type="line-scale-party" style={{transform: 'scale(0.5)'}}/>
              )
            }
            {
              !onLoadSending && (
                <Button
                  size="sm"
                  color="primary"
                  disabled={ !typedContent || selectedCase.is_closed || onLoadSending }
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    cardBodyBottom.current.scrollIntoView({ behavior: "smooth", block: "end" })
                    Promise.all([
                      onChangeMessageHOC( 'onLoadSending'. true  )
                    ]).then(() => {
                      sendMessage({
                        description: typedContent,
                        sender_type_id: tempUserType === 'case'  ? 2 : 1,
                        case_id: selectedCase.id,
                        sender_id: tempUserType === 'case' ? selectedCase.id : data.ProfileReducer.profile.id
                      }) 
                      selectedCase.status !== 'VIEWED' && updateCaseStatus( selectedCase.id, 'VIEWED' )
                    })
                  }}
                >
                  <MdSend style={{ width: 20, height: 20 }}/>
                </Button>
              )
            }
          </div>
        </CardFooter>
      </Card>
      { onLoadAttachment && <LoadingOverlay/> }
    </>
  )
}

export default Message;