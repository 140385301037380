import React from "react";
import {
  Switch,
  Route,
  withRouter,
  Redirect
} from "react-router-dom"

import AdminLogin from 'containers/AdminLogin'
import CaseLogin from './containers/CaseLogin'
import Dashboard from './containers/Dashboard'

import LandPage from './containers/LandingPage'

import { getItem } from './utils/tokenStore'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    !getItem( 'BPK_ACCESS_TOKEN' )
      ? getItem( 'BPK_USER_TYPE' ) === 'case' 
        ? <Redirect to='/case/login' />
        : <Redirect to='/admin/login' />
      : <Component {...props} />
  )} />
);

function CustomRouter() {
  return (
    <Switch>
      <Route exact path="/admin/login" component={ AdminLogin }/>
      <Route exact path="/case/login" component={ CaseLogin } />
      <PrivateRoute path="/dashboard" component={ Dashboard } />
      <Route path="/land-page" component={ LandPage  } initialPath/>
      <Redirect from={ '/' } to={ '/land-page' } />
    </Switch>
  );
}

export default withRouter( CustomRouter );