import React, { useEffect } from 'react'
import { 
  UncontrolledTooltip,
  Modal, ModalHeader, ModalBody
} from 'reactstrap'
import { RiAddBoxLine } from 'react-icons/ri'

import TransComponent from 'components/Trans'
import ListView from './listView'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import CreateAttContent from './Create'
import ConfirmationModal from 'components/Modal/confirmation'
import { getItem } from 'utils/tokenStore'

const Attachment = ({
  data,
  selectedCase,
  onLoadAttachment,
  caseAttachements = [],
  showCreateAttachment,
  showDeleteAttachment,

  selectedAttachement,

  onClose,
  onChangeAttachmentHOC,
  deleteCaseAttachment,
  createCaseAttachment
}) => {

  let tempUserType = getItem( 'BPK_USER_TYPE' )

  return (
    <>
      <ModalHeader toggle={ tempUserType !== 'case' ? () => onClose() : false }>
        <TransComponent i18nKey={ 'lawyer_case.title.create_case_attachment' }/>
        {
          ( tempUserType === 'case' || 
          ( tempUserType !== 'case' && data.ProfileReducer.profile.role_id === 3 )) && 
          !selectedCase.is_closed && (
            <>
              <RiAddBoxLine   
                id="AddAttachment"
                style={{ 
                  width: 20, height: 20, marginLeft: 7, 
                  cursor: 'pointer'
                }}
                onClick={ () => onChangeAttachmentHOC( 'showCreateAttachment', true )}
              />
              <UncontrolledTooltip target="AddAttachment" placement="top">
                <TransComponent i18nKey={ `lawyer_case.tooltip.case_attachment_create` }/>
              </UncontrolledTooltip>
            </>
          )
        }
      </ModalHeader>
      <ModalBody>
        {
          caseAttachements.length < 1 && (
            <div 
              className={ 'd-flex justify-content-center align-items-center mt-3 w-100'} 
              style={{ minHeight: 100 }}
            >
              <TransComponent i18nKey={ "lawyer_case.desc.no_attachment" }/>
            </div>
          ) 
        }
        {
          caseAttachements.length > 0 && (
            <ListView
              caseAttachements={ caseAttachements }
              onChangeAttachmentHOC={ onChangeAttachmentHOC }
            />
          )
        }
      </ModalBody>
      <Modal isOpen={ showCreateAttachment }>
        <CreateAttContent
          onLoadAttachment={ onLoadAttachment }
          onClose={() => onChangeAttachmentHOC( 'showCreateAttachment', false )}
          onSubmit={({ fileContent, fileName }) => createCaseAttachment( selectedCase.id, {
            file: fileContent,
            file_name: fileName,
            sender_type_id: tempUserType === 'case'  ? 2 : 1,
            sender_id: tempUserType === 'case' ? selectedCase.id : data.ProfileReducer.profile.id
          }) }
        />
      </Modal>
      <ConfirmationModal
        open={ showDeleteAttachment }
        loading={ onLoadAttachment }
        titleKey={ 'case_type.title.delete' }
        messageKey={ 'case_type.desc.delete' }
        onClose={ () => onChangeAttachmentHOC( 'showDeleteAttachment', false )}
        onClickConfirm={ () => deleteCaseAttachment( selectedAttachement.id )} />
      {
        onLoadAttachment && <LoadingOverlay/>
      }
    </>
  )
}


export default Attachment;