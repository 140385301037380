import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Get, Post, Put, Delete } from 'utils/axios'
import { requestError, requestSuccess } from 'utils/requestHandler'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      translations: [],
      selectedTranslation: {},

      showCreateModal: false,
      showDeleteModal: false,
      showUpdateModal: false
    }

    onChangeTranslationHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getTranslation = () => {
      Get(
        `/translations`,
        this.getTranslationSuccess,
        this.getTranslationError,
        this.load
      )
    }
    getTranslationSuccess = payload => {
      let temp = _.map( payload, translation => {
        let tempRole = _.find( this.props.data.DictionaryReducer.roles, { id: translation.role_id })
        
        return {
          ... translation,
          role_name: tempRole?.name??'test'
        }
      })

      this.setState({ translations: temp })
    }
    getTranslationError = error => requestError( error )

    getSelectedTranslation = id => Get(
      `/translations/${ id }`,
      this.getSelectedTranslationSuccess,
      this.getSelectedTranslationError,
      this.load
    )
    getSelectedTranslationSuccess = payload => this.setState({ selected: payload })
    getSelectedTranslationError = error => requestError ( error )

    createTranslation = ( dataToSubmit, companyId ) => Post(
      `/translations`,
      dataToSubmit,
      payload => this.createTranslationSuccess( payload, companyId ),
      this.createTranslationError,
      this.load
    )
    createTranslationSuccess = ( payload, companyId ) => {
      this.getTranslation()
      this.setState({ showCreateModal: false })
      
      if ( payload.role_id === 4 ){
        this.props.createCompanyTranslation({
          translation_id: payload.id,
          company_id: +companyId
        })
      }
      requestSuccess( 'New translation has been created successfully.' )
    }
    createTranslationError = error => requestError( error )

    deleteTranslation = id => Delete( 
      `/translations/${ id }`,
      this.deleteTranslationSuccess,
      this.deleteTranslationError,
      this.load
    )
    deleteTranslationSuccess = () => {
      this.getTranslation()
      this.setState({ showDeleteModal: false })
      requestSuccess( 'Translation has been deleted succesfully.' )
    }
    deleteTranslationError = error => requestError( error )

    updateTranslation = ( id, dataToSubmit ) => Put(
      `/translations/${ id }`,
      dataToSubmit,
      this.updateTranslationSucces,
      this.updateTranslationError,
      this.load
    )
    updateTranslationSucces = payload => {
      this.getTranslation()
      this.getSelectedTranslation( payload.id )
      requestSuccess( 'Translation has been updated successfully.' )
    }
    updateTranslationError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          translations={ this.state.translations }
          selectedTranslation={ this.state.selectedTranslation }
          onLoadTranslation={ this.state.loading }
          
          showCreateModal={ this.state.showCreateModal }
          showDeleteModal={ this.state.showDeleteModal }
          showUpdateModal={ this.state.showUpdateModal }

          getTranslation={ this.getTranslation }
          getSelectedTranslation={ this.getSelectedTranslation }
          createTranslation={ this.createTranslation }
          deleteTranslation={ this.deleteTranslation }
          updateTranslation={ this.updateTranslation }
          updatePW={ this.updatePW }
          updateCompanyID={ this.updateCompanyID }
          onChangeTranslationHOC={ this.onChangeTranslationHOC }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC