import React, { createRef, useEffect } from 'react'
import {
	Card, CardBody
} from 'reactstrap'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'

import CustomButton from './CustomButton'

import Field from './RenderedField'

const RenderedCard = ({
  mode,
  editIndex,
  json, jsonIndex,
  onHandleChangeSelectedEdit,
  formData,

  selectedEdit,
  onChangeFieldForm
}) => {

  const cardRef = createRef( null )

  const isEditing = () => {
    if ( 
      selectedEdit !== null && 
      selectedEdit.cardIndex === null && 
      selectedEdit.fieldIndex === editIndex.fieldIndex
    ) {
      return true
    } else {
      return false
    }
  }
  
  useEffect(() => {
    if ( isEditing() ){
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }, [ selectedEdit ])
  
  return (
    <div 
      ref={ cardRef }
      className={ `transition_200  ${ !selectedEdit ? 'bkp-form-renderer-card-hover' : '' }` }
      style={{
        ... isEditing() && {
          boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
          border: '1px solid #467bc6',
          borderStyle: 'dotted'
        }
      }}
    >
      <div className='d-flex'>
        <h5>
          { ReactHTMLParser( json.label ) }
        </h5>
        {
          mode !== 'view' && (
            <CustomButton
              type={ 'edit' }
              onClick={() => {
                onHandleChangeSelectedEdit({
                  cardIndex: null,
                  contentContext: 'content',
                  fieldIndex: jsonIndex
                })
              }}
            />
          )
        }
      </div>
      <Card className='mb-4'>
        <CardBody>
        {
          json.content.map(( contentJSON, contentIndex ) => (
            <div className={ 'rounded' }>
              <Field 
                mode={ mode }
                editIndex={{
                  cardIndex: jsonIndex,
                  contentContext: 'content',
                  fieldIndex: contentIndex
                }}
                index={ contentIndex }
                formData={ formData }
                selectedEdit={ selectedEdit }
                hasGroupedInCard={ true }
                json={ contentJSON }
                onClickField={ () => {
                  onHandleChangeSelectedEdit({
                    cardIndex: jsonIndex,
                    contentContext: 'content',
                    fieldIndex: contentIndex
                  })
                }}
                onChangeFieldForm={( key, val ) => onChangeFieldForm( key, val, jsonIndex, contentIndex )}
              />  
            </div>
          ))
        }
        </CardBody>
      </Card>
    </div>
  )
}

export default RenderedCard;