import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { 
  CardBody, 
  CardFooter,
  Button,
  Form,
  Row, Col,
  FormGroup, Label, Input
} from 'reactstrap'
import _ from 'lodash'
import TransComponent from 'components/Trans'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import {
  DefaultForm,
  DefaultFields
} from '../asset'

const FormContent = ({
  data,
  onLoadUsers,

  selectedUser,
  onClickSubmitUser
}) => {
  const [ userForm, onChangeUserForm ] = useState( DefaultForm )
  const { roles: rolesLib } = data.DictionaryReducer

  const onChangeField = useCallback(( context, val ) => {
    onChangeUserForm({
      ... userForm,
      [context]: val
    })
  }, [userForm])


  useEffect(() => {
    onChangeUserForm({
      id: selectedUser.id,
      first_name: selectedUser.first_name,
      last_name: selectedUser.last_name,
      email: selectedUser.email,
      role_id: selectedUser.role_id,
      mobile_contact: selectedUser.mobile_contact
    })
  }, [ selectedUser ])

  let disableSubmit = useMemo(() => {
    return _.keys( userForm ).some( key => key !== 'mobile_contact' && !userForm[key] ) || 
    userForm.role_id === 4 && !userForm.mobile_contact
  }, [userForm])
  

  return (
    <>
      <CardBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <Row>
            {
              DefaultFields( 'edit' ).map(( field, index ) => (
                <Col key={ `field-${ index }`} lg={ 6 }>
                  <FormGroup>
                    <Label className={ 'd-flex' }>
                      <TransComponent i18nKey={ `user.fields.${ field.transKey ? field.transKey : field.value }`}/>
                      {
                        (( field.value !== 'mobile_contact' && field.required )|| 
                        (field.value === 'mobile_contact' && userForm.role_id === 4 )
                        ) && (
                          <span className="text-danger">*</span>
                         )
                      }
                    </Label>
                    {
                      field.type !== 'select' && (
                        <Input
                          type={ 'text' }
                          // disabled={ field.value === 'email' }
                          value={ userForm[ field.value ] }
                          onChange={ e => {
                            onChangeField( field.value, e.target.value )
                          }} 
                        />
                      )
                    }
                    {
                      field.type === 'select' && (
                        <Input
                          type={ 'select' }
                          disabled={ true }
                          value={ userForm[ field.value ] }
                          onChange={ e => onChangeField( field.value, e.target.value )}
                        >
                          <option value=""></option>
                          { rolesLib.map( data => (
                            <option key={ data.id } value= { data.id }>{ data.name }</option>
                          ))}
                        </Input>
                      )
                    }
                  </FormGroup>
                </Col>
              ))
            }
          </Row>
        </Form>
      </CardBody>
      <CardFooter>
        <Button 
          color="primary"
          className="ml-auto"
          disabled={ disableSubmit }
          onClick={() => onClickSubmitUser({
            ... userForm,
            role_id: +userForm.role_id
          })}>
          <TransComponent i18nKey={ `general.submit`}/>
        </Button>
      </CardFooter>
      { onLoadUsers && <LoadingOverlay />}
    </>
  )
}

export default FormContent