import React, { useState, useCallback, useMemo } from 'react'
import { 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Row, Col,
  FormGroup, Label, Input
} from 'reactstrap'
import _ from 'lodash'
import { GrView, GrFormViewHide } from 'react-icons/gr'
import TransComponent from 'components/Trans'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import {
  DefaultForm,
  DefaultFields
} from './asset'

const FormContent = ({
  data,
  onLoadUsers,
  companies,

  onClose,
  onClickSubmit
}) => {
  const [ userForm, onChangeUserForm ] = useState( DefaultForm )
  const [ showPWList, onChangePWList ] = useState([])
  const [ companyId, onChangeCompanyID ] = useState( null )
  const { roles: rolesLib } = data.DictionaryReducer

  const onChangeField = useCallback(( context, val ) => {
    onChangeUserForm({
      ... userForm,
      [context]: val
    })
  }, [userForm])

  let onShowPWValue = useCallback( val => {
    let temp = _.cloneDeep( showPWList )
    if ( showPWList.indexOf( val ) > -1 ){
    _.remove( temp, listItem => listItem === val )
    } else {
      temp.push( val )
    }

    onChangePWList ( temp )
  }, [showPWList])

  let disableSubmit = useMemo(() => {
    return _.keys( userForm ).some( key => key !== 'mobile_contact' && !userForm[key] ) || 
    ( userForm.password !== userForm.confirm_password ) ||
    userForm.role_id === 4 && ( !companyId || !userForm.mobile_contact )
  }, [userForm, companyId ])

  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        <TransComponent i18nKey={ `user.title.create`}/>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <Row>
            {
              DefaultFields( 'create' ).map(( field, index ) => (
                <Col key={ `field-${ index }`} lg={ 6 }>
                  <FormGroup>
                    <Label className={ 'd-flex' }>
                      <TransComponent i18nKey={ `user.fields.${ field.transKey ? field.transKey : field.value }`}/>
                      {
                        (( field.value !== 'mobile_contact' && field.required )|| 
                        (field.value === 'mobile_contact' && userForm.role_id === 4 )
                        ) && (
                          <span className="text-danger">*</span>
                         )
                      }
                      {
                        field.type === 'password' && (
                          <div 
                            style={{ width: 14, height: 14, marginLeft: 10, cursor: 'pointer' }}
                            onClick={ () => onShowPWValue( field.value )}>
                            {
                              showPWList.indexOf( field.value ) > -1
                                ? <GrView style={{ width: '100%', height: '100%' }}/>
                                : <GrFormViewHide style={{ width: '100%', height: '100%' }}/>
                            }
                          </div>
                        )
                      }
                    </Label>
                    {
                      field.type === 'select' && (
                        <Input
                          type={ 'select' }
                          value={ userForm[ field.value ] }
                          onChange={ e => onChangeField( field.value, +e.target.value )}
                        >
                          <option value=""></option>
                          { rolesLib.map( data => (
                            <option key={ data.id } value= { data.id }>{ data.name }</option>
                          ))}
                        </Input>
                      )
                    }
                     {
                      field.type !== 'select' && (
                        <Input
                          type={ 
                            field.type === 'password' 
                              ? showPWList.indexOf( field.value ) > -1 ? 'text' : 'password'
                              : 'text' 
                          }
                          value={ userForm[ field.value ] }
                          onChange={ e => onChangeField( field.value, e.target.value )} 
                        />
                      )
                    }
                  </FormGroup>
                </Col>
              ))
            }
            {
              userForm.role_id === 4 && (
                <Col md={ 6 }>
                  <Label className={ 'd-flex' }>
                    <TransComponent i18nKey={ `user.fields.company_id`}/>
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type={ 'select' }
                    value={ companyId }
                    onChange={ e => onChangeCompanyID( e.target.value )}
                  >
                    <option value=""></option>
                    { companies.map( data => (
                      <option key={ data.id } value= { data.id }>{ data.name }</option>
                    ))}
                  </Input>
                </Col>
              )
            }
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          className="ml-auto"
          disabled={ disableSubmit }
          onClick={() => onClickSubmit({
            ... userForm,
            role_id: +userForm.role_id
          }, companyId
        )}>
          <TransComponent i18nKey={ `general.submit`}/>
        </Button>
      </ModalFooter>
        { onLoadUsers && <LoadingOverlay />}
    </>
  )
}

export default FormContent