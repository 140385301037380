import React, { useState } from 'react'
import { 
  Button, Form, FormGroup, Label,
  ModalBody, ModalHeader, ModalFooter 
} from 'reactstrap'

import { FilePond, registerPlugin } from "react-filepond"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"

import TransComponent from 'components/Trans'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

registerPlugin( 
  FilePondPluginImageExifOrientation, 
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
)

export default function CreateAttachement ({
  onLoadAttachment,
  onClose,
  onSubmit
}) {

  const [ tempFile, onChangeFile ] = useState( false )
  
  const onDropFile = fileItems => {
    if( fileItems && fileItems.length > 0 && fileItems[ 0 ].file ) {
      const reader = new FileReader()
      reader.onload = e => {
        onChangeFile({ 
          fileContent: e.target.result,
          fileName: fileItems[0].file.name
        })
      }
      reader.readAsDataURL( fileItems[ 0 ].file )
    }
    else {
      onChangeFile( null )
    }
  }

  return (
    <>
      <ModalHeader toggle={ () => onClose()}>
        <TransComponent i18nKey="lawyer_case.title.create_case_attachment"/>
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>
              <TransComponent i18nKey="lawyer_case.fields.case_attachment"/>
            </Label>
            <FilePond
              fullWidth
              acceptedFileTypes={['image/png', 'image/jpg', 'image/jpeg', "application/pdf" ]}
              maxFiles={ 1 }
              onremovefile={ () => onDropFile( null )}
              onupdatefiles={ onDropFile }
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button 
          color={ !tempFile ? 'secondary': 'primary' }
          disabled={ !tempFile }
          onClick={() => onSubmit( tempFile )}
        >
          <TransComponent i18nKey={ `general.create` }/>
        </Button>
      </ModalFooter>
      { onLoadAttachment && <LoadingOverlay/> }
    </>
  )
}
