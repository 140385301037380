import React, { Component } from 'react'
import { connect } from 'react-redux'
import MetisMenu from 'react-metismenu'
import _ from 'lodash'

import { setEnableMobileMenu } from 'reducers/ThemeOptions'
import { getItem } from 'utils/tokenStore'

import { MainNav } from './dataMainNav'

class VerticalNavWrapper extends Component {

  render = () => {
    let tempUserType = getItem( 'BPK_USER_TYPE' )
    const { role_id } = this.props.data.ProfileReducer.profile
    let tempMenu = []

    if ( role_id === 4 ){
      MainNav.map( item => {
        if ( item.to === '#/dashboard/case-mngmt'|| item.to === '#/dashboard/admin/users' ) {
          tempMenu.push( item )
        }
      })
    } else { 
      tempMenu = MainNav
    }

    return (
      <>
        {
          tempUserType === 'non-case' && (
            <>
              <br />
              <MetisMenu
                content={ tempMenu }
                onSelected={ () => this.props.setEnableMobileMenu( !this.props.enableMobileMenu ) }
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down" />
            </>   
          )
        }
      </>
    )
  }
}

const mapStateToProps = state => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenum,
  data: state
})

export default connect( mapStateToProps, {
  setEnableMobileMenu
})( VerticalNavWrapper )