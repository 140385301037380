export const DefaultForm = {
  name: '',
  email: '',
  contact: '',
  lawyer_contact: '',
  locale: 'de'
}

export const DefaultFields = [
  { 
    value: 'name',
    type: 'text' 
  },
  { 
    col: 6,
    required: true, value: 'contact',
    type: 'text' 
  },
  { 
    col: 6,
    required: true, value: 'lawyer_contact',
    type: 'text' 
  },
  { 
    col: 6,
    required: true, value: 'email',
    type: 'text' 
  }
]