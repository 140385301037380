import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Modal, Row, Col, Card, CardBody, Button, UncontrolledTooltip } from 'reactstrap'
import _ from 'lodash'
import TransComponent from 'components/Trans'

import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import BKPTable from 'components/Table'

import TranslationForm from './Form'
import ConfirmationModal from 'components/Modal/confirmation'
import { getItem } from 'utils/tokenStore'

import TranslationHOC from './action'

class Translation extends Component {

  componentDidMount = () => {
    this.props.getTranslation()

    getItem( 'BPK_USER_TYPE' ) !== 'non-case' &&
    this.props.history.push( '/dashboard/case-mngmt' )
  }

  render = () => {

    let isAdmin = [ 1, 2 ].indexOf( this.props.data.ProfileReducer.profile.role_id ) > -1

    return (
      <>
        <PageTitle
          heading={ <TransComponent i18nKey={ `translation.title.main`}/>}
          subheading={ <TransComponent i18nKey={ `translation.desc.main`}/> }
          icon="pe-7s-user icon-gradient bg-happy-itmeo"
          buttons={ isAdmin ? [
            {
              color: 'primary',
              onClick: () => {
                this.props.onChangeTranslationHOC( 'showCreateModal', true )
              },
              content: <TransComponent i18nKey={ `translation.title.new`}/>
            }
          ] : []}
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <BKPTable
                    pageSize={ 10 }
                    data={ this.props.translations }
                    columns={[
                      {
                        Header: <TransComponent i18nKey={ `translation.fields.key` }/>,
                        accessor: 'key'
                      },
                      {
                        Header: <TransComponent i18nKey={ `translation.fields.english` }/>,
                        accessor: 'email',
                        Cell: (row) => (
                          <p>{ row.original.content[ 'en' ]}</p>
                        )
                      },
                      {
                        Header: <TransComponent i18nKey={ `translation.fields.german` }/>,
                        Cell: (row) => (
                          <p>{ row.original.content[ 'de' ]}</p>
                        )
                      },
                      ... isAdmin ? [
                        {
                          Header: <TransComponent i18nKey={ `general.action`}/>,
                          Cell: (row) => {
                            return (
                              <>
                                <Button
                                  id={ `UpdateWeeklyLog_${ row.original.id }`}
                                  className="mr-2 btn-icon btn-icon-only"
                                  color="primary"
                                  onClick={() => {
                                    this.props.onChangeTranslationHOC( 'showUpdateModal', true )
                                    this.props.onChangeTranslationHOC( 'selectedTranslation', row.original )
                                  }}>
                                  <i className="pe-7s-pen btn-icon-wrapper"> </i>
                                </Button>
                                <UncontrolledTooltip target={ `UpdateWeeklyLog_${ row.original.id }`} placement="top">Update Role</UncontrolledTooltip>
                                <Button
                                  id={ `DeleteTranslation_${ row.original.id }` }
                                  className="mr-2 btn-icon btn-icon-only"
                                  color="danger"
                                  onClick={() => {
                                    this.props.onChangeTranslationHOC( 'showDeleteModal', true )
                                    this.props.onChangeTranslationHOC( 'selectedTranslation', row.original )
                                  }}>
                                  <i className="pe-7s-close btn-icon-wrapper"> </i>
                                </Button>
                                <UncontrolledTooltip target={ `DeleteTranslation_${ row.original.id }` } placement="top">Delete Translation</UncontrolledTooltip>
                              </>
                            )
                          }
                        }
                      ] : []
                    ]
                  }
                  showPagination={ true } />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        <Modal
          isOpen={ this.props.showCreateModal }
          size={ 'xl' }
        >
          <TranslationForm
            mode={ 'create' }
            onClickSubmit={ this.props.createTranslation }
            onClose={ () => this.props.onChangeTranslationHOC( 'showCreateModal', false )}
          />
        </Modal>
        <Modal
          isOpen={ this.props.showUpdateModal }
          size={ 'xl' }
        >
          <TranslationForm
            mode={ 'edit' }
            selectedTranslation={ this.props.selectedTranslation }
            onClickSubmitTranslation={ val => this.props.updateTranslation( this.props.selectedTranslation.id, val )}
            onClose={ () => this.props.onChangeTranslationHOC( 'showUpdateModal', false )}
          />
        </Modal>
        <ConfirmationModal
          open={ this.props.showDeleteModal }
          titleKey={ `translation.title.delete` }
          loading={ this.props.onLoadAdvisor }
          messageKey={ `translation.desc.delete` }
          onClose={ () => this.props.onChangeTranslationHOC( 'showDeleteModal', false )}
          onClickConfirm={ () => this.props.deleteTranslation( this.props.selectedTranslation.id )}/>
        { this.props.onLoadTranslation && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  TranslationHOC
)(Translation)