import React from 'react'
import { 
  Card, CardBody
} from 'reactstrap'
import ReactHTMLParser from 'react-html-parser'

import CustomButton from './CustomButton'

export default function DataRemainAnonymous({
  formData,
  currentLang,
  selectedEdit,
  
  onHandleChangeSelectedEdit
}) {

  const { data_remain_anonymous_modal } = formData
  return (
    <Card style={{ background: data_remain_anonymous_modal.backgroundStyle[ 'background' ]}}>
      <CardBody>
        <div 
          className={ `d-flex align-items-center flex-wrap transition_200 ${ selectedEdit === null ? 'bkp-form-renderer-title-hover' : '' }` }
          style={{
            marginBottom: 20,
            ... selectedEdit && 
            selectedEdit === 'title' && {
              boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
              border: '1px solid #467bc6',
              borderStyle: 'dotted'
            }
          }}
        >
        { ReactHTMLParser( data_remain_anonymous_modal.title.label[ currentLang ] ) }
        <CustomButton
          type={ 'edit' }
          onClick={() => onHandleChangeSelectedEdit( 'title' )}
        />
      </div>
      {
        data_remain_anonymous_modal.content.map(( contentItem, contentIndex ) => (
          <>
            <div 
              className={ `d-flex align-items-center flex-wrap transition_200 ${ selectedEdit === null ? 'bkp-form-renderer-title-hover' : '' }` }
              style={{
                ... selectedEdit && 
                selectedEdit.fieldIndex === contentIndex && {
                  boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
                  border: '1px solid #467bc6',
                  borderStyle: 'dotted'
                }
              }}
            >
              { ReactHTMLParser( contentItem.label[ currentLang ] ) }
              <CustomButton
                type={ 'edit' }
                onClick={() => {
                  onHandleChangeSelectedEdit({
                    cardIndex: null,
                    contentContext: 'content',
                    fieldIndex: contentIndex
                  })
                }}
              />
            </div>
            {
              contentItem.type === 'title' && (
                <hr />
              ) 
            }
          </>
        ))
      }
      </CardBody>
    </Card>
  )
}
