import React, { createRef, useEffect } from 'react'
import { 
  Input, Form, Label, FormGroup, CustomInput, Button,
  Card, CardBody
} from 'reactstrap'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'

import CustomButton from './CustomButton'
import VisibleIndicator from './VisibleIndicator'

const DragField = ({ 
  editIndex,
  mode, index, 
  duplicatedKeyError,
  currentLang,
  json, hasGroupedInCard, groupMode,
  selectedToGroup,
  selectedEdit,

  onClickRemove,
  onClickField,
  onChangeFieldForm, onHandleCheckGroup
}) => {

  const isEditing = () => {
    if ( selectedEdit !== null && editIndex &&
      selectedEdit.fieldIndex === editIndex.fieldIndex && 
      editIndex.cardIndex === selectedEdit.cardIndex
    ){
      return true 
    } else {
      return false
    }
  }

  const fieldRef = createRef( null )
  
  
  // useEffect(() => {
  //   if ( isEditing() ){
  //     fieldRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
  //   }
  // }, [ selectedEdit ])

  // hover effect class will be handled and checked based on if its GROUPING  mode and is it EDIT mode or not
  //hasGroupedInCard check if a field in grouped under a card or not.
  return (
    <div
      ref={ fieldRef }
      className={ `rounded transition_200  ${( selectedEdit === null && mode === 'edit' && !hasGroupedInCard && !groupMode ) ? `bkp-form-renderer-form-hover` : '' }` }
      style={{ 
        cursor: !groupMode ? 'pointer' : 'unset',
        position: 'relative',
        ... isEditing() && {
          boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
          border: '1px solid #467bc6',
          borderStyle: 'dotted'
        },
        ... groupMode && {
          boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
          margin: '25px 0px'
        },
        ... !groupMode && 
          duplicatedKeyError?.[0] &&
          duplicatedKeyError.indexOf( json.key ) > - 1 && {
          border: '1px solid red',
          borderStyle: 'dotted'
        }
      }}
    >
      {
        !hasGroupedInCard && groupMode && (
          <div className='d-flex' style={{ position: 'absolute', right: '3%', top: -15, zIndex: 2 }}>
            <Card className='ml-2'>
              <CardBody style={{ display: 'grid', placeItems: 'center' }}>
              <Input 
                  style={{ width: 17, height: 17, margin: 'auto' }}
                  type='checkbox'
                  checked={ selectedToGroup.indexOf( json.key ) > -1 }
                  onChange={ e => onHandleCheckGroup( json.key )}
              />
              </CardBody>
            </Card>
          </div>  
        )
      }
      {
        json.variant !== 'file' && (
          <Form className={ `mb-3` }>
            <FormGroup>
              {
                json.variant === 'checkbox' && (
                  <CustomInput
                    key={ json.key }
                    id={ json.key }
                    type='checkbox'
                    className='mr-3'
                    label={ 
                      <Label style={{ fontSize: '1rem' }}>
                        { ReactHTMLParser( json.label[ currentLang ] )}
                        {
                          mode === 'edit' && 
                          !groupMode && (
                            <CustomButton
                              type={ 'edit' }
                              onClick={ onClickField }
                            />
                          )
                        }
                        {
                          !groupMode && (
                            <VisibleIndicator
                              id={ `${ json.key }_${ index }`}
                              visible={ json.isAddtionalField ? json.visible : true }
                            />
                          )
                        }
                      </Label>
                    }
                    checked={ json.is_required }
                  />
                )
              }
              {
                json.variant !== 'checkbox' && (
                  <>
                    <Label style={{ fontSize: '1rem' }}>
                      { ReactHTMLParser( json.label[ currentLang ] )}
                      {/* {
                        json.required && (
                          <span className='text-danger'>*</span>
                        )
                      } */}
                      {
                        mode === 'edit' && 
                        !groupMode && (
                          <CustomButton
                            type={ 'edit' }
                            onClick={ onClickField }
                          />
                        )
                      }
                      {
                        json.isAddtionalField &&
                        mode === 'edit' &&
                        !groupMode && (
                          <CustomButton
                            type={ 'delete' }
                            onClick={ onClickRemove }
                          />
                        )
                      }
                      {
                        !groupMode && (
                          <VisibleIndicator
                            id={ `${ json.key }_${ index }`}
                            visible={ json.visible }
                          />
                        )
                      }
                    </Label>
                    {
                      json.variant === 'text' && (
                        <Input
                          placeholder={ json.placeholder[ currentLang ] }
                          type={ json.variant }
                          disabled={ true }
                          onChange={ e => onChangeFieldForm( 'key', e.target.value )}
                        />
                      )
                    }
                    {
                      json.variant === 'multi-checkbox' && (
                        <>
                          {
                            json.options.map(( optionChild, optionIndex ) => (
                              <FormGroup key={ `select_option_${ optionIndex }` } check>
                                <Input
                                  id={ optionChild.value }
                                  type={ 'checkbox' }
                                  disabled={ true }
                                />
                                {' '}
                                <Label check>
                                  { optionChild[ 'label' ][ currentLang ] }
                                </Label>
                              </FormGroup>
                            ))
                          }
                        </>
                      )
                    }
                    {
                      json.variant === 'select' && (
                        <Input
                          placeholder={ json.placeholder[ currentLang ] }
                          type={ json.variant }
                          disabled={ true }
                          onChange={ e => onChangeFieldForm( 'key', e.target.value )}
                        >
                          <option value=""></option>
                          {
                            json.options.map(( optionChild, optionIndex ) => (
                              <option key={ `select_option_${ optionIndex }` } value={ optionChild.value }>
                                { optionChild[ currentLang ] }
                              </option>
                            ))
                          }
                        </Input>
                      )
                    }
                    {
                      json.variant === 'radiobutton' && (
                        <>
                          {
                            json.options.map(( optionChild, optionIndex ) => (
                              <FormGroup key={ `select_option_${ optionIndex }` } check>
                                <Label check> 
                                  <Input 
                                    type="radio" name={ optionChild.value } 
                                    disabled={ true }
                                  />
                                  { optionChild[ 'label' ][ currentLang ] }
                                </Label>
                              </FormGroup>
                            ))
                          }
                        </>
                      )
                    }
                  </>
                ) 
              }
            </FormGroup>
          </Form>
        )
      }
      {
        json.variant === 'file' && (
          <div 
            style={{ minHeight: groupMode ? 70 : 'unset' }} 
            className='d-flex flex-wrap justify-content-between align-items-center mb-3'
          >
            
            <div className='d-flex'>
              <Label style={{ fontSize: '1rem' }}>
                { ReactHTMLParser( json.label[ currentLang ] ) }  
              </Label>  
              {
                mode === 'edit' && 
                !groupMode && (
                  <CustomButton
                    type={ 'edit' }
                    onClick={ onClickField }
                  />
                )
              }
              {
                !groupMode && (
                  <VisibleIndicator
                    id={ `${ json.key }_${ index }`}
                    visible={ json.isAddtionalField ? json.visible : true }
                  />
                )
              }
            </div>
            <Button style={{ height: 'fit-content' }}>
              {  ReactHTMLParser( json.btn_label[ currentLang ] ) }
            </Button>
          </div>
        )
      }
    </div>
  )
}


export default DragField;