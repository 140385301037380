import React, { Fragment } from 'react'
import { 
  Card, CardBody
} from 'reactstrap'
import ReactHTMLParser from 'react-html-parser'

import CustomButton from './CustomButton'

export default function ReportingProcedure({
  mode,
  formData,
  selectedEdit,

  onHandleChangeSelectedEdit
}) {

  const { reporting_procedure_modal } = formData
  return (
    <Card>
      <CardBody>
        <div 
          className={ `d-flex align-items-center flex-wrap transition_200 ${ selectedEdit === null ? 'bkp-form-renderer-title-hover' : '' }` }
          style={{
            marginBottom: 20,
            ... selectedEdit && 
            selectedEdit === 'title' && {
              boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
              border: '1px solid #467bc6',
              borderStyle: 'dotted'
            }
          }}
        >
          { ReactHTMLParser( reporting_procedure_modal.title.label ) }
          {
            mode !== 'view' && (
              <CustomButton
                type={ 'edit' }
                onClick={() => onHandleChangeSelectedEdit( 'title' )}
              />
            )
          }
        </div>
        {
          reporting_procedure_modal.content.map(( contentItem, contentIndex ) => (
            <Fragment key={ `${ contentItem.key }_${ contentIndex }` }>
              <div
                className={ `d-flex align-items-center flex-wrap transition_200 ${ selectedEdit === null ? 'bkp-form-renderer-title-hover' : '' }` }
                style={{
                  ... selectedEdit && 
                  selectedEdit.fieldIndex === contentIndex && {
                    boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
                    border: '1px solid #467bc6',
                    borderStyle: 'dotted'
                  }
                }}
              >
                {
                  ReactHTMLParser( contentItem.label )
                }
                {
                  mode !== 'view' && (
                    <CustomButton
                      type={ 'edit' }
                      onClick={() => {
                        onHandleChangeSelectedEdit({
                          cardIndex: null,
                          contentContext: 'content',
                          fieldIndex: contentIndex
                        })
                      }}
                    />
                  )
                }
              </div>
              {
                contentItem.type === 'title' && (
                  <hr />
                ) 
              }
            </Fragment>
          ))
        }
      </CardBody>
    </Card>
  )
}
