import React, { Component } from 'react'
import { Col, Row, Button, Label, UncontrolledTooltip } from 'reactstrap'
import Slider from "react-slick"
import _ from 'lodash'
import {
  AvForm,
  AvGroup,
  AvInput
} from 'availity-reactstrap-validation'
import DocumentMeta from 'react-document-meta';
import { GrView, GrFormViewHide } from 'react-icons/gr'
import { BsFillBriefcaseFill } from 'react-icons/bs'
import { compose } from 'redux'

import TransComponent from 'components/Trans'

import TemplateContainerMain from 'components/Template'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import LanguageSwitcher from 'components/LanguageSwitcher'

import bg1 from 'assets/TemplateImages/originals/bg1.jpeg';
import bg2 from 'assets/TemplateImages/originals/bg2.jpeg'
import bg3 from 'assets/TemplateImages/originals/bg3.jpeg'

import WithLogin from './action'

import { getItem } from 'utils/tokenStore';

const meta = {
  meta: {
    name: {
      robots: 'noindex'
    }
  }
};

const SliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true
}

class Login extends Component {
  state = {
    case_uid: '',
    password: ''
  }

  componentDidMount = () => {
    if( getItem( 'BPK_ACCESS_TOKEN' )) {
      this.props.history.push( '/dashboard/case-mngmt' );
    } else {
      // this.props.mountAcceptedCookies()
    }
  }

  handleKeyPress = e => {
    if(e.key === 'Enter'){
      e.preventDefault();
      
      if ( this.state.case_uid.length < 1 || this.state.password.length < 1){
        return false
      }

      this.props.onClickLogin({
        case_uid: this.state.case_uid,
        password: this.state.password,
        encode_type: 'JWT'
      })
    }
  }

  render = () => {
    const { 
      onChangeHOC,
      showPassword
    } = this.props
    return (
      <DocumentMeta { ... meta  }>
        <TemplateContainerMain>
          <div className="h-100">
            <Row className="h-100 no-gutters">
              <Col lg="4" className="d-none d-lg-block">
                <div className="slider-light">
                  <Slider { ...SliderSettings }>
                    <div
                      className="h-100 d-flex justify-content-center align-items-center">
                      <div
                        className="slide-img-bg"
                        style={{ backgroundImage: 'url(' + bg1 + ')' }}/>
                      <div className="slider-content"></div>
                    </div>
                    <div
                      className="h-100 d-flex justify-content-center align-items-center">
                      <div
                        className="slide-img-bg"
                        style={{ backgroundImage: 'url(' + bg2 + ')' }}/>
                      <div className="slider-content"></div>
                    </div>
                    <div
                      className="h-100 d-flex justify-content-center align-items-center">
                      <div
                        className="slide-img-bg"
                        style={{ backgroundImage: 'url(' + bg3 + ')' }}/>
                      <div className="slider-content"></div>
                    </div>
                  </Slider>
                </div>
              </Col>
              <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                  <div className="d-flex flex-wrap">
                    <img 
                      id="companylogo"
                      className='shadow-sm rounded'
                      src={ 'https://whistleblower-center.proof-point.com/assets/BKP-Logo.png' } 
                      style={{ 
                        width: 150, marginBottom: 30, 
                        cursor: 'pointer'
                      }} 
                      onClick={ () => this.props.history.push( '/land-page' )}
                    />
                    <UncontrolledTooltip target="companylogo" placement="top">
                      <TransComponent i18nKey={ `general.bring_me_to_langing_page` }/>
                    </UncontrolledTooltip>
                    <LanguageSwitcher
                      buttonClass={'text-black'}
                      userType={'case'}
                      containerStyle={{ height: 'min-content', borderRadius: 10, marginLeft: 'auto'}}
                      iconStyle={{ color: '#6390cf' }}
                    />
                  </div>
                  <h4 className="mb-0">
                    <BsFillBriefcaseFill style={{ width: 30, height: 30, marginRight: 15 }}/>
                    <TransComponent i18nKey={ `login.title.case`}/>
                  </h4>
                  <Row className="divider"/>
                  <AvForm>
                    <Row form>
                      <Col md={6}>
                        <AvGroup>
                          <Label for="caseLabel">
                            <TransComponent i18nKey={ `login.fields.case`}/>  
                          </Label>
                          <AvInput
                            onChange={ e => {
                              this.setState({ case_uid: e.target.value })
                            }}
                            type="case_uid"
                            name="case_uid"
                            id="caseLabel"
                            value={ this.state.case_uid }
                            onKeyPress={this.handleKeyPress}
                            required />
                        </AvGroup>
                      </Col>
                      <Col md={6}>
                        <AvGroup>
                          <div className={ 'd-flex' }>
                            <Label for="pwLabel">
                              <TransComponent i18nKey={ `login.fields.password`}/>  
                            </Label>
                            <div 
                              style={{ width: 14, height: 14, marginLeft: 10, cursor: 'pointer' }}
                              onClick={ () => onChangeHOC( !showPassword, 'showPassword' )}>
                              {
                                showPassword 
                                  ? <GrView style={{ width: '100%', height: '100%' }}/>
                                  : <GrFormViewHide style={{ width: '100%', height: '100%' }}/>
                              }
                            </div>
                          </div>
                          <AvInput
                            onChange={ e => {
                              this.setState({ password: e.target.value })
                            }}
                            type={ showPassword ? 'text' : 'password' }
                            name="password"
                            id="pwLabel"
                            value={ this.state.password }
                            onKeyPress={this.handleKeyPress}
                            required />
                        </AvGroup>
                      </Col>
                    </Row>
                    { 
                      this.props.errorMessage && (
                        <p style={{ color: '#ff0000' }}>
                          { this.props.errorMessage }
                        </p>
                    )}
                    <Row className="divider" />
                    <Row 
                      className="d-flex align-items-center"
                      style={{ flexDirection: "row-reverse" }}>
                      <Col md={6}>
                        <div className="mr-md-0 mx-auto" style={{ width: 'fit-content' }}>
                          {/* <Button
                            size="lg"
                            className='mr-2'
                            onClick={() => this.props.history.push( '/admin/login' )}>
                            <FaUser style={{ width: 17, height: 17, marginRight: 10 }}/>
                            <TransComponent i18nKey={ `login.buttons.admin-login`}/>    
                          </Button> */}
                          <Button
                            color="primary"
                            size="lg"
                            disabled={ 
                              !this.state.case_uid || !this.state.password || 
                              this.props.showCookieConsentModal 
                            }
                            onClick={() => this.props.showCookieConsentModal 
                              ? {}
                              : this.props.onClickLogin({
                                  case_uid: this.state.case_uid,
                                  password: this.state.password,
                                  encode_type: 'JWT'
                                })
                              }
                            >
                            <TransComponent i18nKey={ `login.buttons.login`}/>    
                          </Button>
                        </div>
                      </Col>
                      <Col md={6} className="my-3">
                        <div className="mx-md-0 mx-auto" style={{ width: 'fit-content', textAlign: 'center' }}>
                        <button 
                          onClick={ () => window.open( `https://www.whistleblowing-compliant.eu/datenschutz` )}
                          style={{ 
                            fontSize: '0.8rem',
                            color: '#6390cf',
                            background: 'transparent', border: 'none' ,
                          }}
                          className="ml-2 mr-2"
                        >
                          <TransComponent i18nKey={ 'general.data-protection' }/>
                        </button> | 
                        <button 
                          onClick={ () => window.open( `https://www.whistleblowing-compliant.eu/impressum` )}
                          style={{ 
                            fontSize: '0.8rem',
                            color: '#6390cf',
                            background: 'transparent', border: 'none' ,
                          }}
                          className="ml-2"
                        >
                          <TransComponent i18nKey={ 'general.imprint' }/>
                        </button>
                          {/* <a 
                            href="" target="_blank" 
                            className='ml-2'
                            onClick={ e => {
                              e.preventDefault()
                              this.props.onChangeCookieHOC( 'isManualOpen', true )
                              this.props.onChangeCookieHOC( 'showCookieConsentModal', true )
                            }}>
                            <TransComponent i18nKey={ 'general.cookie_settings' }/>
                          </a> */}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </Col>
              </Col>
              </Row>
          </div>
          { this.props.onLoadLogin && <LoadingOverlay /> }
        </TemplateContainerMain>
      </DocumentMeta>
    )
  }
}   

export default compose( 
  WithLogin
)( Login )