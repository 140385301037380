import React, { createRef, useEffect } from 'react'
import {
	Card, CardBody
} from 'reactstrap'
import _ from 'lodash'
import {
  Draggable, Droppable,
} from 'react-beautiful-dnd'

import CustomButton from './CustomButton'
import ReactHTMLParser from 'react-html-parser'

import Field from './RenderedField'

const RenderedCard = ({
  editIndex,
  json, jsonIndex,
  duplicatedKeyError,
  currentLang,
  onHandleChangeSelectedEdit,
  setSelectedDelete,
  setDeletePath,
  formData,

  selectedEdit,
  selectedToGroup,
  groupMode,
  onChangeFieldForm,
  onHandleCheckGroup,
  getListStyle,
  getItemStyle
}) => {

  const cardRef = createRef( null )

  const isEditing = () => {
    if ( 
      selectedEdit !== null && 
      selectedEdit.cardIndex === null && 
      selectedEdit.fieldIndex === editIndex.fieldIndex
    ) {
      return true
    } else {
      return false
    }
  }
  
  useEffect(() => {
    if ( isEditing() ){
      cardRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }, [ selectedEdit ])
  
  return (
    <div 
      ref={ cardRef }
      className={ `transition_200  ${ !selectedEdit || groupMode ? 'bkp-form-renderer-card-hover' : '' }` }
      style={{
        ... isEditing() && {
          boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
          border: '1px solid #467bc6',
          borderStyle: 'dotted'
        }
      }}
    >
      <div className='d-flex'>
        { ReactHTMLParser( json.label[ currentLang ] )}
        {
          !groupMode && (
            <CustomButton
              type={ 'edit' }
              onClick={() => {
                onHandleChangeSelectedEdit({
                  cardIndex: null,
                  contentContext: 'content',
                  fieldIndex: jsonIndex
                })
              }}
            />
          )
        }
      </div>
      <Card 
        className='mb-4' 
        style={{
          background: json.style.background
        }}
      >
        <CardBody>
          <Droppable 
            type={`droppableSubItem`}
            droppableId={ `${ jsonIndex }` } 
          >
            {( provided, snapshot ) => (
              <div
                ref={provided.innerRef}
                className={ 'rounded' }
                style={{ 
                    ... getListStyle( snapshot.isDraggingOver ), 
                    minHeight: snapshot.isDraggingOver ? `calc(${ json.content.length } * 80px)` : 'unset'
                }}
                {...provided.droppableProps}
              >
                {
                  json.content.map(( contentJSON, contentIndex ) => (
                    <Draggable
                      key={ contentJSON.key }
                      draggableId={ contentJSON.key }
                      isDragDisabled={ groupMode || json.content.length < 2 || selectedEdit !== null }
                      index={ contentIndex }>
                      {( provided, snapshot ) => (
                        <div
                          ref={ provided.innerRef }
                          { ...provided.dragHandleProps }
                          { ...provided.draggableProps }
                          className={ 'rounded' }
                          style={ getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <Field 
                            editIndex={{
                              cardIndex: jsonIndex,
                              contentContext: 'content',
                              fieldIndex: contentIndex
                            }}
                            mode={ 'edit' } index={ contentIndex }
                            duplicatedKeyError={ duplicatedKeyError }
                            formData={ formData }
                            currentLang={ currentLang }
                            selectedEdit={ selectedEdit }
                            hasGroupedInCard={ true }
                            selectedToGroup={ selectedToGroup }
                            groupMode={ groupMode }
                            json={ contentJSON }
                            onClickRemove={ () => {
                              setSelectedDelete( json.key )
                              setDeletePath({
                                cardIndex: jsonIndex,
                                contentContext: 'content',
                                fieldIndex: contentIndex
                              })
                            }}
                            onClickField={ () => {
                              onHandleChangeSelectedEdit({
                                cardIndex: jsonIndex,
                                contentContext: 'content',
                                fieldIndex: contentIndex
                              })
                            }}
                            onChangeFieldForm={( key, val ) => onChangeFieldForm( key, val, jsonIndex, contentIndex )}
                            onHandleCheckGroup={ onHandleCheckGroup }
                          />  
                        </div>
                      )}
                    </Draggable>
                  ))
                }
              </div>
            )}
          </Droppable>
        </CardBody>
      </Card>
    </div>
  )
}

export default RenderedCard;