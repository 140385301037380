import React from 'react'
import {
  Row, Col, UncontrolledTooltip,
  Card, CardBody
} from 'reactstrap'

import { FiEdit } from 'react-icons/fi'
import { FaUserTie } from 'react-icons/fa'
import { VscPinnedDirty } from 'react-icons/vsc'
import { GoAlert } from 'react-icons/go'
import { RiCloseFill, RiBuilding2Fill } from 'react-icons/ri'
import { AiFillFile } from 'react-icons/ai'
import { BsCloudDownloadFill } from 'react-icons/bs'

import TransComponent from 'components/Trans'

export default function AdminContent({
  selectedCase,
  companyRepresentative,
  resolvedLanguage,

  setShowAttachment,
  setCompanyDetails,
  downloadCase,
  updateCase,
  onChangeCaseHOC
}) {
  return (
    <>
      <Row className={ 'mb-3' } >
          <Col xl={ 6 } lg={ 7 } md={ 12 }>
            <Card
              style={{  
                backgroundColor: `rgb(108 117 125 / 10%)`, marginBottom: 15,
                width: '97%', marginLeft: 'auto'
              }}
            >
              <CardBody style={{ padding: '0.8rem', display: 'flex', alignItems: 'center' }}>
                <RiBuilding2Fill 
                  id='CompanyInfo'
                  style={{ 
                    width: 42, height: 42, 
                    marginRight: 25,
                    padding: '0.6rem', borderRadius: '0.2rem',
                    marginLeft: -30,
                    cursor:  selectedCase.company_info ? 'pointer' : 'initial'
                  }}
                  className={ `text-white bg-primary` }
                  onClick={ () => setCompanyDetails( true )}
                />
                {
                  selectedCase.company_info?.id && (
                    <>
                      <UncontrolledTooltip target="CompanyInfo" placement="top">
                        <TransComponent i18nKey="lawyer_case.tooltip.company_details"/>
                      </UncontrolledTooltip>
                      <h6 className='mb-0 fw-bold' style={{ fontWeight: 500 }}>
                        { selectedCase.company_info.name || 'N/A' }
                      </h6>
                    </>
                  )
                }
                {
                  !selectedCase.company_info && (
                    <h6 className='mb-0 fw-bold' style={{ fontWeight: 500 }}>
                      <TransComponent i18nKey={ `lawyer_case.desc.assign` }/>  
                    </h6>
                  )
                } 
                {/* {
                  isAdmin && !selectedCase.is_closed && (
                    <>
                      <FiEdit 
                        id="CompanyAssign"
                        className='ml-auto' 
                        style={{ width: 20, height: 20, cursor: 'pointer' }}
                        onClick={() => onToggleAssign( true )}
                      />
                      <UncontrolledTooltip target="CompanyAssign" placement="top">
                        <TransComponent i18nKey="lawyer_case.tooltip.company_assign"/>
                      </UncontrolledTooltip>
                    </>
                  )
                } */}
              </CardBody>
            </Card>
            <Card
              style={{  
                backgroundColor: `rgb(108 117 125 / 10%)`,
                width: '97%', marginLeft: 'auto'
              }}
            >
              <CardBody style={{ padding: '0.8rem', display: 'flex', alignItems: 'center' }}>
                <FaUserTie 
                  id='CompanyRep'
                  style={{ 
                    width: 42, height: 42, 
                    marginRight: 25,
                    padding: '0.6rem', borderRadius: '0.2rem',
                    marginLeft: -30
                  }}
                  className={ `text-white bg-primary` }
                />
                <UncontrolledTooltip target="CompanyRep" placement="top">
                  <TransComponent i18nKey="lawyer_case.tooltip.company_rep"/>
                </UncontrolledTooltip>
                <div>
                  <h6 className='mb-2 fw-bold' style={{ fontWeight: 500 }}>
                    { `${ companyRepresentative?.first_name??'' } ${ companyRepresentative?.last_name??'' }` }
                  </h6>
                  <p className='mb-1'>
                    { companyRepresentative?.email??'N/A' }
                  </p>
                  <p className='mb-0'>
                    { companyRepresentative?.mobile_contact??'N/A' }
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={ 6 } lg={ 5 } md={ 12 }>
            <Card 
              className='bkp-case-card'
              style={{  backgroundColor: `rgb(108 117 125 / 10%)` }}
            >
              <CardBody>
                <div className="bkp-case__actions mb-3">
                  <div 
                    className={ `bkp-case__actions-child ${ selectedCase.is_closed ? 'bg-danger text-white' : 'bg-white text-secondary' } ` }
                    style={{ 
                      cursor: selectedCase.is_closed ? 'not-allowed' : 'pointer', 
                      filter: selectedCase.is_closed ? 'brightness(0.7)' : 'brightness(1)' 
                    }}
                    onClick={ () => {
                      !selectedCase.is_closed && onChangeCaseHOC( 'showCloseCaseModal', true )
                    }}
                  >
                    <RiCloseFill/>
                    <span>
                      {
                        selectedCase.is_closed 
                          ? <TransComponent i18nKey="lawyer_case.tabs.case_closed"/>
                          : <TransComponent i18nKey="lawyer_case.title.close_case"/>

                      }
                    </span>
                  </div>
                  <div 
                    className={ `bkp-case__actions-child ${ selectedCase.is_pinned ? 'bg-warning text-white' : 'bg-white text-secondary' } ` }
                    onClick={ () => {
                      updateCase({
                        ... selectedCase,
                        is_pinned: !selectedCase.is_pinned
                      })
                    }}
                  >
                    <VscPinnedDirty/>
                    <span>
                      <TransComponent i18nKey="lawyer_case.tabs.pinned"/>
                    </span>
                  </div>
                  <div 
                    className={ `bkp-case__actions-child ${ selectedCase.is_urgent ? 'bg-danger text-white' : 'bg-white text-secondary' } ` }
                    onClick={ () => {
                      updateCase({
                        ... selectedCase,
                        is_urgent: !selectedCase.is_urgent
                      })
                    }}
                  >
                    <GoAlert/>
                    <span>
                      <TransComponent i18nKey="lawyer_case.tabs.urgent"/>
                    </span>
                  </div>
                </div>
                <div className="bkp-case__actions">
                  <div 
                    className={ `bkp-case__actions-child bg-primary text-white` }
                    onClick={ () => setShowAttachment( true )}
                  >
                    <AiFillFile/>
                    <span>
                      <TransComponent i18nKey="lawyer_case.title.case_attachment"/>
                    </span>
                  </div>
                  <div 
                    className={ `bkp-case__actions-child bg-success text-white` }
                    onClick={ () => {
                      downloadCase( selectedCase, resolvedLanguage )
                    }}
                  >
                    <BsCloudDownloadFill />
                    <span>
                      <TransComponent i18nKey="lawyer_case.title.download_case"/>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>      
    </>
  )
}
