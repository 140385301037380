import React, { Component } from 'react'
import { Button } from 'reactstrap'
import TransComponent from 'components/Trans'
import { clearItem } from 'utils/tokenStore';
import { getItem } from 'utils/tokenStore'
import { RiLogoutBoxLine } from 'react-icons/ri'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import WithForgotPassword from './actions'

class Userbox extends Component {
  render = () => {
    let tempUserType = getItem( 'BPK_USER_TYPE' )

    return (
      <div className="header-btn-lg pr-0 ml-0" style={{ paddingLeft: '1rem' }}>
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left d-flex align-items-center">
              <Button 
                className="btn-pill btn-shadow btn-shine" 
                color="focus" 
                onClick = { () => {
                  Promise.all([
                    clearItem( 'BPK_ACCESS_TOKEN' )
                  ]).then(() =>{
                    tempUserType === 'non-case' 
                      ? this.props.history.push( '/admin/login' )
                      : this.props.history.push( '/case/login' )
                  })
                }}>
                <RiLogoutBoxLine style={{ width: 20, height: 20, marginRight: 10 }}/>
                <TransComponent i18nKey="general.logout"/>
              </Button>
            </div>
          </div>
        </div>
        {
          this.props.onLoadForgotPassword && <LoadingOverlay />
        }        
      </div>
    )
  }
}

export default WithForgotPassword( Userbox )