import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { Modal, Row, Col, Card, CardBody, Button, UncontrolledTooltip } from 'reactstrap'
import _ from 'lodash'

import PageTitle from 'components/Title/PageTitle'
import BKPTable from 'components/Table'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import RoleForm from './Form'
import ConfirmationModal from 'components/Modal/confirmation'
import CryptoJS from 'crypto-js'
import TransComponent from 'components/Trans'
import { getItem } from 'utils/tokenStore'
import RolesHOC from './action'

class Roles extends Component {

  componentDidMount = () => {
    // let tempJSON = CryptoJS.AES.encrypt( '/v1/cases', `engineEr16sWay78globUlar` ).toString()
    // console.log (tempJSON )
    // let tempDescrypt = CryptoJS.AES.decrypt( `U2FsdGVkX19sljhEchJDrtEaDB3NGCg44j+9HtvMxAsTx4GH+uEVSPoAg//VfH9VOBa1vGr51p+eArGF4ot9+ZhuLkn3y8SpWFJEI0qH+G7+p5Pnx/MuyAIYxfGuTP5ZPDzqLJS0lZqvM4bAK2Keo/e6ZNvDhjJAe5QZkWOvqosNgUW7jAxTgqoTZHe2sjakL0fqbDyP8v0SDszc0Yt1CAaRr6ETky+RMmyrvEED+13ZkC3lTo+ykP9YYzvtd7opzNdtpXBBM/6UdMHVjO0fHQ2w/Jm/EHSaw2H82a7h6r5iOmhfe1+NI7F2srKjnRr9nm+IQFLvZMxwpXP+0uuoGfdvdXFqbuNXaIfnvYQoaqk=`, 'sowbellY83seated72sTitch' )
    // console.log( tempDescrypt.toString(CryptoJS.enc.Utf8) )

    const { role_id } = this.props.data.ProfileReducer.profile

    if ( role_id === 4 || getItem( 'BPK_USER_TYPE' ) !== 'non-case'){
      return this.props.history.push( '/dashboard/case-mngmt' )
    }
    
    this.props.getRoles()
  }
  render = () => {
    return (
      <>
        <PageTitle
          heading={ <TransComponent i18nKey="role.title.main"/> }
          subheading={ <TransComponent i18nKey="role.desc.main"/> }
          icon="pe-7s-user icon-gradient bg-happy-itmeo"
          buttons={[]}
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md={12}>
              <Card className="main-card mb-3">
                <CardBody>
                  <BKPTable
                    pageSize={ 10 }
                    data={ this.props.roles }
                    columns={[
                      {
                        Header: <TransComponent i18nKey="role.fields.name"/>,
                        accessor: 'name'
                      },
                      // {
                      //   Header: <TransComponent i18nKey="general.action"/>,
                      //   Cell: (row) => {
                      //     return (
                      //       <>
                      //         <Button
                      //           id="UpdateRole"
                      //           className="mr-2 btn-icon btn-icon-only"
                      //           color="primary"
                      //           onClick={() => {
                      //             this.props.onChangeRoleHOC( 'showUpdateModal', true )
                      //             this.props.onChangeRoleHOC( 'selectedRole', row.original )
                      //           }}>
                      //           <i className="pe-7s-pen btn-icon-wrapper"> </i>
                      //         </Button>
                      //         <UncontrolledTooltip target="UpdateRole" placement="top">
                      //           <TransComponent i18nKey="role.tooltip.update"/>
                      //         </UncontrolledTooltip>
                      //       </>
                      //     )
                      //   }
                      // }
                    ]
                  }
                  showPagination={ true } />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        <Modal
          isOpen={ this.props.showCreateModal }
          size={ 'xl' }
        >
          <RoleForm
            mode={ 'Create' }
            onClickSubmit={ val => this.props.createRole( val )}
            onClose={ () => this.props.onChangeRoleHOC( 'showCreateModal', false )}
          />
        </Modal>
        <Modal
          isOpen={ this.props.showUpdateModal }
          size={ 'xl' }
        >
          <RoleForm
            mode={ 'Edit' }
            selectedRole={ this.props.selectedRole }
            onClickSubmit={ val => this.props.updateRole( this.props.selectedRole.id, val )}
            onClose={ () => this.props.onChangeRoleHOC( 'showUpdateModal', false )}
          />
        </Modal>
        <ConfirmationModal
          open={ this.props.showDeleteModal }
          titleKey={ 'role.title.main' }
          loading={ this.props.onLoadAdvisor }
          messageKey={ 'role.desc.delete' }
          onClose={ () => this.props.onChangeRoleHOC( 'showDeleteModal', false )}
          onClickConfirm={ () => this.props.deleteRole( this.props.selectedRole.id )}/>
        { this.props.onLoadRoles && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  RolesHOC
)(Roles)