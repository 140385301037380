  import React, { useState } from 'react'
  import { 
    Row, Col,
    Input, Form, Label, FormGroup, CustomInput, Button,
    Card, CardBody, Collapse
  } from 'reactstrap'
  import _ from 'lodash'
  import { BiSelectMultiple } from 'react-icons/bi'
  import { GrRadialSelected } from 'react-icons/gr'
  import { IoIosClose } from 'react-icons/io'
  import { CompactPicker } from 'react-color'
  import { AiOutlineCloudUpload } from 'react-icons/ai'
  import { IoMdCheckbox } from 'react-icons/io'
  import { GiPlayButton } from 'react-icons/gi'
  import { MdTextFields, MdArrowDropDown } from 'react-icons/md'
  import { RiLockPasswordFill } from 'react-icons/ri'
  import { BsTextParagraph } from 'react-icons/bs'
  import { useTranslation } from 'react-i18next'

  import RTEInput from 'components/Input/RTE'
  import TransComponent from 'components/Trans'
  import OptionsForm from '../OptionsForm'

  import { DisplayFieldTypeOptions } from '../../assets'

  const TermsContentKeys = [
    "hundred_anonymous_reporting",
    "certified_data_security",
    "independent_ombudsman",
    "what_is_reporting_procedure",
    "will_data_remain_anonymous"
  ]

  const EditCard = ({ 
    json,
    languageOptions,
    currentLang,
    onChangeFieldForm,
    onClickDismiss
  }) => {
    const { t } = useTranslation()
    const [ showContent, setShowContent ] = useState( true )

    return (
      <div style={{ 
        position: '-webkit-sticky',
        position: 'sticky', 
        top: 0
      }}>
        <Card className='mb-3 mt-4'>
          <CardBody>
            <div 
              style={{ marginTop: -40 }}
              className={ 'd-flex align-items-center justify-content-around' }
            >
              <div className='d-flex align-items-center shadow rounded bg-white'  
                style={{ 
                  width: 'max-content', 
                  padding: '0.4rem',
                  minWidth: 120, 
                }}
              >
                <div className='card p-2 bg-warning'>
                  {
                    json.variant === 'checkbox' && (
                      <IoMdCheckbox className='text-white' style={{ width: 20, height: 20 }}/>
                    )
                  }
                  {
                    json.variant === 'textarea' && (
                      <BsTextParagraph className='text-white' style={{ width: 20, height: 20 }}/>
                    )
                  }
                  {
                    json.variant === 'file' && (
                      <AiOutlineCloudUpload className='text-white' style={{ width: 20, height: 20 }}/>
                    )
                  } 
                  {
                    json.variant === 'password' && (
                      <RiLockPasswordFill className='text-white' style={{ width: 20, height: 20 }}/>
                    )
                  }
                  {
                    json.type === 'card' && (
                      <RiLockPasswordFill className='text-white' style={{ width: 20, height: 20 }}/>
                    )
                  }
                  {
                    json.type === 'button' && (
                      <GiPlayButton className='text-white' style={{ width: 20, height: 20 }}/>
                    )
                  }
                  {
                    json.variant === 'select' && (
                      <BiSelectMultiple className='text-white' style={{ width: 20, height: 20 }}/>
                    )
                  }
                  {
                    json.variant === 'radiobutton' && (
                      <GrRadialSelected style={{ width: 20, height: 20, filter: 'invert(1)' }}/>
                    )
                  }
                  {
                    (( json.type !== 'button' && json.type !== 'card' && json.type !== 'field' )|| json.variant === 'text' ) && (
                      <MdTextFields className='text-white' style={{ width: 20, height: 20 }}/>
                    )
                  }
                </div>
                <span className='ml-2 mr-1 text-warning' style={{ fontWeight: '600', fontSize: '1rem' }}>
                  {
                    ( json.type !== 'button' && json.type !== 'card' && json.type !== 'field' ) && (
                      <TransComponent i18nKey={ 'general.text' }/>
                    )
                  }
                  {
                    ( json.type === 'button' || json.type === "card" || json.type === 'field' ) && (
                      <TransComponent i18nKey={ `form.desc.${ json.type === 'field' ? json.variant : json.type }`}/>
                    )
                  }
                </span>
              </div>
              <div className="d-flex ml-auto">
                <Button 
                  color={ 'warning' }
                  style={{ padding: '0.35rem' }}
                  onClick={ () => setShowContent( !showContent )}
                >
                  <MdArrowDropDown 
                    className='text-white'
                    style={{ 
                      width: 20, height: 20, 
                      transform: showContent ? 'rotate(180deg)' : 'rotate(0deg)' 
                    }}
                  />
                </Button>
                <Button 
                  color={ 'danger' } 
                  style={{ padding: '0.35rem' }}
                  className={ 'ml-2' }
                  onClick={ () => onClickDismiss()}
                >
                  <IoIosClose style={{ width: 20, height: 20 }}/>
                </Button>
              </div>
            </div>
            <Collapse isOpen={ showContent }>
              <Form className='mt-4'>
                <Row>
                  <Col lg={ 12 }>
                    <FormGroup>
                      <Label>
                        <TransComponent i18nKey={ `form.fields.field-label`}/>
                      </Label>
                      <RTEInput
                        data= { json.label }
                        objectName= { currentLang }
                        onChange={ val => onChangeFieldForm( 'label', val )}
                      />
                    </FormGroup>
                  </Col>
                  {
                    json.key === 'bkp-case-attachment' && (
                      <Col lg={ 12 }>
                        <FormGroup>
                          <Label>
                            <TransComponent i18nKey={ `form.fields.button-label`}/>
                          </Label>
                          <RTEInput
                            data= { json.btn_label }
                            objectName= { currentLang }
                            onChange={ val => onChangeFieldForm( 'btn_label', val )}
                          />
                        </FormGroup>
                      </Col>
                    )
                  }
                  {/* {
                    json.type === 'field' && 
                    json.variant === 'file' && (
                      <Col lg={ 12 }>
                        <FormGroup>
                          {
                            console.log( json.btn_label[ currentLang ] )
                          }
                          <Label className='d-block'>
                            <TransComponent i18nKey={ `form.fields.button-label`}/>
                          </Label>
                          <RTEInput
                            value={ json.btn_label[ currentLang ] }
                            onChange={( _, editor ) => onChangeFieldForm( 'btn_label', {
                              ... json.btn_label,
                              [ currentLang ]: editor.getData()
                            })}
                          />
                        </FormGroup>
                      </Col>
                    )
                  } */}
                  {
                    ( json.type === 'card' || json.type === 'button' ) && (
                      <Col lg={ 12 }>
                        <FormGroup>
                          <Label className='d-block'>
                            <TransComponent i18nKey={ `form.fields.bg-color`}/>
                          </Label>
                          <CompactPicker
                            color={ json.style.background }
                            onChange={ col => {
                              let tempStyle = _.cloneDeep( json.style )
                              tempStyle.background = col.hex
                              
                              onChangeFieldForm( 'style', tempStyle )
                            }}
                          />
                        </FormGroup>
                      </Col>
                    )
                  }
                  {
                    typeof json.visible === 'boolean' && (
                      <Col lg={ 12 }>
                        <FormGroup>
                          <CustomInput
                            key={ `form-visible-key` }
                            id={ `form-visible-id` }
                            type='checkbox'
                            className='mr-3'
                            disabled={
                              (!json.isAddtionalField && [ 'company_department', 'country', 'city', ... TermsContentKeys ].indexOf( json.key ) < 0  )
                          }
                            label={ <TransComponent i18nKey={ `form.fields.is-visible` }/> }
                            checked={ json.visible }
                            onChange={ e => onChangeFieldForm( 'visible', e.target.checked )}
                          />
                        </FormGroup>
                      </Col>
                    )
                  }
                  {
                    json.type === 'field' && (
                      <>
                        <Col lg={ 12 }>
                          <FormGroup>
                            <Label>
                              <TransComponent i18nKey={ `form.fields.field-key`}/>
                            </Label>
                            <Input
                              value={ json.key }
                              type={ 'text' }
                              disabled={ !json.isAddtionalField }
                              onChange={ e => onChangeFieldForm( 'key', e.target.value.replace(/ /g, "_") )}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={ 12 }>
                          <FormGroup>
                            <CustomInput
                              key={ `form-required-key` }
                              id={ `form-required-id` }
                              disabled={ !json.isAddtionalField }
                              type='checkbox'
                              className='mr-3'
                              label={ <TransComponent i18nKey={ `form.fields.is-required` }/> }
                              checked={ json.required }
                              onChange={ e => onChangeFieldForm( 'required', e.target.checked )}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>
                              <TransComponent i18nKey={ `form.fields.field-type`}/>
                            </Label>
                            <Input
                              value={ json.variant }
                              type={ 'select' }
                              disabled={ true }
                              onChange={ e => onChangeFieldForm( 'variant', e.target.value )}
                            >
                              <option></option>
                              {
                                DisplayFieldTypeOptions.map( type => (
                                  <option value={ type }>{ t( `form.desc.${ type }` ) }</option>
                                ))
                              }
                            </Input>
                          </FormGroup>
                        </Col>
                      </>
                    )
                  }
                  {
                    ( 
                      json.variant === 'select' || 
                      json.variant === 'radiobutton' || 
                      json.variant === 'multi-checkbox' 
                    ) && (
                      <Col lg={ 12 }>
                        <OptionsForm
                          options={ json.options }
                          langOptions={ languageOptions }
                          
                          currentFormLang={ currentLang }
                          onChangeTextFormField={ onChangeFieldForm }
                        />
                      </Col>
                    )
                  }
                </Row>
              </Form>
            </Collapse>
          </CardBody>
        </Card>
      </div>
    )
  }

  export default EditCard
