import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Get, Post, Put, Delete } from 'utils/axios'
import { requestError, requestSuccess } from 'utils/requestHandler'
import TransComponent from 'components/Trans'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      caseTypes: [],
      selectedType: {},
      showCreateType: false,
      showUpdateType: false,
      showConfirmDeleteModal: false,
      searchParams: [
				{
          transKey: 'case_type.fields.en_name',
					value: 'en_name',
					type: 'text',
					col: 6,
					param: ''
				},
        {
          transKey: 'case_type.fields.de_name',
					value: 'de_name',
					type: 'text',
					col: 6,
					param: ''
				}
			],
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeTypesHOC = ( key, val ) => this.setState({ [ key ]: val })

    getTypes = query => Get(
      `/types?query=${query}`,
      this.getTypesSuccess,
      this.getTypesError,
      this.load
    )
    getTypesSuccess = payload => {
      let temp = _.map( payload.data, item => ({
        ... item,
        en_name: item?.name?.en??'',
        de_name: item?.name?.de??''
      }))
      this.setState({ caseTypes: temp })
    }
    getTypesError = error => requestError( error )

    createType = data => Post(
      `/types`,
      data,
      this.createTypeSuccess,
      this.createTypeError,
      this.load
    )
    createTypeSuccess = payload => {
      requestSuccess( 'Case type is created successfully.' )
      this.setState({ showCreateType: false })
      this.getTypes()
    }
    createTypeError = error => requestError( error )

    updateType = ( id, data ) => Put(
      `/types/${ id }`,
      data,
      this.updateTypeSuccess,
      this.updateTypeError,
      this.load
    )
    updateTypeSuccess = payload => {
      requestSuccess( 'Case type is updated successfully.' )
      this.getTypes()
    }
    updateTypeError = error => requestError( error )

    deleteType = id => Delete(
      `/types/${ id }`,
      this.deleteTypeSuccess,
      this.deleteTypeError,
      this.load
    )
    deleteTypeSuccess = () => {
      requestSuccess( 'Case type is removed successfully.' )
      this.setState({ showConfirmDeleteModal: false })
      this.getTypes()
    }
    deleteTypeError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          caseTypes={ this.state.caseTypes }
          selectedType={ this.state.selectedType }
          searchParams={ this.state.searchParams }
          showCreateType={ this.state.showCreateType }
          showUpdateType={ this.state.showUpdateType }
          onLoadTypes={ this.state.loading }
          showConfirmDeleteModal={ this.state.showConfirmDeleteModal }

          onChangeTypesHOC={ this.onChangeTypesHOC }
          getTypes={ this.getTypes }
          createType={ this.createType }
          updateType={ this.updateType }
          deleteType={ this.deleteType } />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC