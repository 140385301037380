import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { 
  ModalHeader, ModalBody, ModalFooter,
  Button,
  Form, FormGroup, Label, Input,
} from 'reactstrap'
import _ from 'lodash'

import TransComponent from 'components/Trans'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

const DefaultForm = {
  key: '',
  content: {
    "en": '',
    "de": '',
  }
}

const FormContent = ({
  mode,
  onLoadCompanies,
  selectedTranslation,

  onClose,
  onClickSubmit
}) => {
  const [ translationForm, onChangeTranslationForm ] = useState( DefaultForm )
  const [ currentLang, setCurrentLang ] = useState( 'de' )
  
  useEffect(() => {
    mode === 'edit' && selectedTranslation && 
    onChangeTranslationForm( selectedTranslation )
  }, [selectedTranslation])
  

  let disableSubmit = useMemo(() => {
    // return _.values( translationForm ).some( item => !item )
    return !translationForm.key || _.values( translationForm.content ).some( val => !val )
  }, [translationForm])

  const onChangeField = useCallback(( context, val ) => {
    onChangeTranslationForm({
      ... translationForm,
      [context]: val
    })
  }, [translationForm])
  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        <TransComponent i18nKey={ `translation.title.create` }/>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label className={ 'd-flex' }>
              <TransComponent i18nKey={ `translation.fields.key` }/>
              <span className="text-danger">*</span>
            </Label>
            <Input
              type={ 'text' }
              value={ translationForm[ 'key' ] }
              onChange={ e => {
                onChangeField( 'key', e.target.value )
              }} 
            />
          </FormGroup>
          <FormGroup>
            <Label className={ 'd-flex flex-wrap' }>
              <Button 
                className='mr-2' 
                color={ currentLang === 'de' ? 'primary' : "secondary" }
                onClick={ () => setCurrentLang( 'de' )}
              >
                <TransComponent i18nKey={ `translation.fields.german` } />
              </Button>
              <Button 
                color={ currentLang === 'en' ? 'primary' : "secondary" }
                onClick={ () => setCurrentLang( 'en' )}
              >
                <TransComponent i18nKey={ `translation.fields.english` } />
              </Button>
            </Label>
            <Input
              value={ translationForm[ 'content' ][ currentLang ] }
              type={ 'textarea' }
              onChange={ e => {
                let tempContent = {
                  ...translationForm.content,
                  [ currentLang ]: e.target.value
                }

                onChangeField( 'content', tempContent )
              }} 
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          className="mr-2"
          disabled={ disableSubmit }
          onClick={() => onClickSubmit( translationForm)
        }>
          <TransComponent i18nKey={ `general.submit`}/>
        </Button>
      </ModalFooter>
      { onLoadCompanies && <LoadingOverlay/> }
    </>
  )
}

export default FormContent