import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai' 
import { UncontrolledTooltip } from 'reactstrap'
import TransComponent from 'components/Trans'

const VisibleIndicator = ({
  visible,
  id
}) => {
  return (
    <span 
      id={ `field_visibility_${ id }` }
      className={ `badge ml-2 text-center badge-${ visible ? 'primary' : 'secondary' }` }
      style={{ 
        padding: "6px 7px",
        borderRadius: '50%',
        height: 'fit-content'
      }}>
      { 
        visible && (
          <AiFillEye className='text-white' style={{ width: 13, height: 13 }}/>
        )
      }
      {
        !visible && (
          <AiFillEyeInvisible className='text-white' style={{ width: 13, height: 13 }}/>
        )
      }
      <UncontrolledTooltip
        target={ `field_visibility_${ id }` }
      >
        <TransComponent i18nKey={ `general.${ visible ? 'visible' : 'hidden' }`}/>
      </UncontrolledTooltip>
    </span>   
  )
}

export default VisibleIndicator;