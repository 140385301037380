import React, { Component } from 'react'
import { Col, Row, Button, Label, UncontrolledTooltip } from 'reactstrap'
import Slider from "react-slick"
import {
  AvForm,
  AvGroup,
  AvInput
} from 'availity-reactstrap-validation'
import { GrView, GrFormViewHide } from 'react-icons/gr'
import { FaUser } from 'react-icons/fa'
import _ from 'lodash'
import { compose } from 'redux'
import DocumentMeta from 'react-document-meta';

import TransComponent from 'components/Trans'
import TemplateContainerMain from 'components/Template'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

import bg1 from 'assets/TemplateImages/originals/bg1.jpeg';
import bg2 from 'assets/TemplateImages/originals/bg2.jpeg'
import bg3 from 'assets/TemplateImages/originals/bg3.jpeg'

import WithLogin from './action'

import { getItem } from 'utils/tokenStore';

const meta = {
  meta: {
    name: {
      robots: 'noindex'
    }
  }
};

const SliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  initialSlide: 0,
  autoplay: true,
  adaptiveHeight: true
}

class Login extends Component {
  state = {
    email: '',
    password: ''
  }

  componentDidMount = () => {
    if( getItem( 'BPK_ACCESS_TOKEN' )) {
      this.props.history.push( '/dashboard/admin/users' );
    } else {
      // this.props.mountAcceptedCookies()
    }
  }

  handleKeyPress = e => {
    
    if(e.key === 'Enter'){
      e.preventDefault();
      
      if ( this.state.email.length < 1 || this.state.password.length < 1){
        return false
      }

      this.props.onClickLogin({
        email: this.state.email, 
        password: this.state.password
      })
    }
  }

  render = () => {
    const { 
      onChangeHOC,
      showPassword
    } = this.props
    return (
      <DocumentMeta { ... meta  }>
        <TemplateContainerMain>
          <div className="h-100">
            <Row className="h-100 no-gutters">
              <Col lg="4" className="d-none d-lg-block">
                <div className="slider-light">
                  <Slider { ...SliderSettings }>
                    <div
                      className="h-100 d-flex justify-content-center align-items-center">
                      <div
                        className="slide-img-bg"
                        style={{ backgroundImage: 'url(' + bg1 + ')' }}/>
                      <div className="slider-content"></div>
                    </div>
                    <div
                      className="h-100 d-flex justify-content-center align-items-center">
                      <div
                        className="slide-img-bg"
                        style={{ backgroundImage: 'url(' + bg2 + ')' }}/>
                      <div className="slider-content"></div>
                    </div>
                    <div
                      className="h-100 d-flex justify-content-center align-items-center">
                      <div
                        className="slide-img-bg"
                        style={{ backgroundImage: 'url(' + bg3 + ')' }}/>
                      <div className="slider-content"></div>
                    </div>
                  </Slider>
                </div>
              </Col>
              <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                  {/* <img src={ IQIIcon } style={{ width: '70px', marginBottom: '15px' }} /> */}
                  <img 
                    id="companylogo"
                    className='shadow-sm rounded'
                    src={ 'https://whistleblower-center.proof-point.com/assets/BKP-Logo.png' } 
                    style={{ 
                      width: 150, marginBottom: 30, 
                      cursor: 'pointer'
                    }} 
                    onClick={ () => this.props.history.push( '/land-page' )}
                  />
                  <UncontrolledTooltip target="companylogo" placement="top">
                    <TransComponent i18nKey={ `general.bring_me_to_langing_page` }/>
                  </UncontrolledTooltip>
                  <h4 className="mb-0">                  
                    <FaUser style={{ width: 25, height: 25, marginRight: 15 }}/>
                    <TransComponent i18nKey={ `login.title.admin` }/>
                  </h4>
                  <Row className="divider"/>
                  <AvForm>
                    <Row form>
                      <Col md={6}>
                        <AvGroup>
                          <Label for="exampleEmail">
                            <TransComponent i18nKey={ `login.fields.email`}/>  
                          </Label>
                          <AvInput
                            onChange={ e => {
                              this.setState({ email: e.target.value })
                            }}
                            type="email"
                            name="email"
                            id="exampleEmail"
                            onKeyPress={this.handleKeyPress}
                            required />
                        </AvGroup>
                      </Col>
                      <Col md={6}>
                        <AvGroup>
                          <div className={ 'd-flex' }>
                            <Label for="examplePassword">
                              <TransComponent i18nKey={ `login.fields.password`}/>  
                            </Label>
                            <div 
                              style={{ width: 14, height: 14, marginLeft: 10, cursor: 'pointer' }}
                              onClick={ () => onChangeHOC( !showPassword, 'showPassword' )}>
                              {
                                showPassword 
                                  ? <GrView style={{ width: '100%', height: '100%' }}/>
                                  : <GrFormViewHide style={{ width: '100%', height: '100%' }}/>
                              }
                            </div>
                          </div>
                          <AvInput
                            onChange={ e => {
                              this.setState({ password: e.target.value })
                            }}
                            type={ showPassword ? 'text' : 'password' }
                            name="password"
                            id="examplePassword"
                            value={ this.state.password }
                            onKeyPress={this.handleKeyPress}
                            required />
                        </AvGroup>
                      </Col>
                    </Row>
                    { 
                      this.props.errorMessage && (
                        <p style={{ color: '#ff0000' }}>
                          { this.props.errorMessage }
                        </p>
                    )}
                    <Row className="divider" />
                    <Row 
                      className="d-flex align-items-center"
                      style={{ flexDirection: "row-reverse" }}>
                      <Col md={6}>
                        <div className="mr-md-0 mx-auto" style={{ width: 'fit-content' }}>
                          {/* <Button
                            size="lg"
                            className='mr-2'
                            onClick={() => this.props.history.push( '/case/login' )}>
                            <BsFillBriefcaseFill style={{ width: 17, height: 17, marginRight: 10 }}/>
                            <TransComponent i18nKey={ `login.buttons.case-login`}/>    
                          </Button> */}
                          <Button
                            color="primary"
                            size="lg"
                            disabled={ 
                              !this.state.email || !this.state.password || 
                              this.props.showCookieConsentModal 
                            }
                            onClick={() => this.props.showCookieConsentModal 
                              ? {}
                              : this.props.onClickLogin({
                                email: this.state.email,
                                password: this.state.password
                              })
                            }>
                            <TransComponent i18nKey={ `login.buttons.login`}/>    
                          </Button>
                        </div>
                      </Col>
                      <Col md={6} className="my-3">
                        <div className="mx-md-0 mx-auto" style={{ width: 'fit-content', textAlign: 'center' }}>
                          <button 
                            onClick={ () => window.open( `https://www.whistleblowing-compliant.eu/datenschutz` )}
                            style={{ 
                              fontSize: '0.8rem',
                              color: '#6390cf',
                              background: 'transparent', border: 'none' ,
                            }}
                            className="ml-2 mr-2"
                          >
                            <TransComponent i18nKey={ 'general.data-protection' }/>
                          </button> | 
                          <button 
                            onClick={ () => window.open( `https://www.whistleblowing-compliant.eu/impressum` )}
                            style={{ 
                              fontSize: '0.8rem',
                              color: '#6390cf',
                              background: 'transparent', border: 'none' ,
                            }}
                            className="ml-2"
                          >
                            <TransComponent i18nKey={ 'general.imprint' }/>
                          </button>
                          {/* <a 
                            href="" target="_blank" 
                            className='ml-2'
                            onClick={ e => {
                              e.preventDefault()
                              this.props.onChangeCookieHOC( 'isManualOpen', true )
                              this.props.onChangeCookieHOC( 'showCookieConsentModal', true )
                            }}>
                            <TransComponent i18nKey={ 'general.cookie_settings' }/>
                          </a> */}
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </Col>
              </Col>
            </Row>
          </div>
          { this.props.onLoadLogin && <LoadingOverlay /> }
        </TemplateContainerMain>
      </DocumentMeta>
    )
  }
}   


export default compose( 
  WithLogin
)( Login )