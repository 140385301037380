
import { useState } from 'react'
import _ from 'lodash'
import {
  Button,
  Card,
  Modal,
  CardBody,
  ModalBody,
  ModalHeader,
  FormGroup, Label, Input, Form
} from 'reactstrap'

import BKPTable from 'components/Table'
import TransComponent from 'components/Trans'

const EditLangModal = ({selectedLangLabel, onSubmit}) => {
  const [ langLabel, setLangLabel ] = useState( selectedLangLabel )

  return (
    <ModalBody>
      <Form>
        <FormGroup>
          <Label>
            <TransComponent i18nKey={ `form.fields.lang_label` }/>
          </Label>
          <Input
            value={ langLabel }
            type={ 'text' }
            onChange={ e => setLangLabel( e.target.value )}
          />
        </FormGroup>
      </Form>
      <Button 
        disabled={ !langLabel }
        color={ 'primary' } onClick={ () => onSubmit(langLabel)}
      >
        <TransComponent i18nKey={ `general.submit` }/>
      </Button>
    </ModalBody>
  )
}

const EditLang = ({
  onClose,

  LangOptions,
  onSubmit
}) => {

  const [editLang, setEditLang] = useState(null)

  return (
    <>
      <ModalHeader toggle={ () => onClose()}>
        <TransComponent i18nKey={ 'form.desc.edit_lang' }/> 
      </ModalHeader>
      <ModalBody>
        <Card>
          <CardBody>
            <BKPTable
              pageSize={ 10 }
              data={ LangOptions }
              columns={[
                {
                  Header: <TransComponent i18nKey="form.fields.lang_label"/>,
                  accessor: 'label'
                },
                {
                  Header: <TransComponent i18nKey="form.fields.lang_key"/>,
                  accessor: 'value'
                },
                {
                  Header: <TransComponent i18nKey="general.action"/>,
                  Cell: (row, index) => {
                    return (
                      <>
                        <Button
                          className="mr-2 btn-icon btn-icon-only"
                          color="primary"
                          onClick={() => setEditLang( row.original )}
                        >
                          <i className={ `btn-icon-wrapper pe-7s-pen` }> </i>
                        </Button>
                      </>
                    )
                  }
                }
              ]
            }
            showPagination={ true } />
          </CardBody>
        </Card>
        <Modal
          isOpen={ editLang !== null }
          >
          <ModalHeader toggle={ () => setEditLang(null)}/>
          <EditLangModal
            selectedLangLabel={editLang?.label??''}
            onSubmit={val => {
              onSubmit(editLang.value, val)
              setEditLang(null)
            }}
          />
        </Modal>
      </ModalBody>
    </>
  )
}

export default EditLang;