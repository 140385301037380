import React from 'react'
import { 
  Row, Col,
  Input, Form, Label, FormGroup, CustomInput
} from 'reactstrap'
import _ from 'lodash'
import { CompactPicker } from 'react-color'
import { useTranslation } from 'react-i18next'
import RTEInput from 'components/Input/RTE'

import TransComponent from 'components/Trans'

import { FieldTypeOptions } from '../../assets'

const EditTextFieldForm = ({ 
  json,
  occupiedKeys,
  currentLang,
  onChangeFieldForm
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Form className='mt-4'>
        <Row>
          <Col lg={ 6 }>
            <FormGroup>
              <Label>
                <TransComponent i18nKey={ `form.fields.field-label`}/>
              </Label>
              <RTEInput
                data= { json.label }
                objectName= { currentLang }
                height={ '70px' }
                onChange={ val => onChangeFieldForm( 'label',  val ) }
              />
            </FormGroup>
          </Col>
          {
            json.type === 'field' && 
            json.variant === 'file' && (
              <Col lg={ 12 }>
                <FormGroup>
                  <Label className='d-block'>
                    <TransComponent i18nKey={ `form.fields.bg-color`}/>
                  </Label>
                  <Input
                    value={ json.btn_label[ currentLang ] }
                    onChange={ e => {
                      let temp = _.cloneDeep( json.btn_label )
                      temp[ currentLang ] = e.target.value

                      onChangeFieldForm( 'btn_label', temp )
                    }}
                  />
                </FormGroup>
              </Col>
            )
          }
          {
            json.type === 'card' && (
              <Col lg={ 12 }>
                <FormGroup>
                  <Label className='d-block'>
                    <TransComponent i18nKey={ `form.fields.bg-color`}/>
                  </Label>
                  <CompactPicker
                    color={ json.style.background }
                    onChange={ col => {
                      let tempStyle = _.cloneDeep( json.style )
                      tempStyle.background = col.hex
                      
                      onChangeFieldForm( 'style', tempStyle )
                    }}
                  />
                </FormGroup>
              </Col>
            )
          }
          {
            json.type === 'field' && (
              <>
                <Col lg={ 6 }>
                  <FormGroup>
                    <Label>
                      <TransComponent i18nKey={ `form.fields.field-key`}/>
                    </Label>
                    <Input
                      value={ json.key }
                      invalid={ occupiedKeys.indexOf( json.key ) > -1 }
                      type={ 'text' }
                      onChange={ e => onChangeFieldForm( 'key', e.target.value.replace(/ /g,"_") )}
                    />
                    {
                      occupiedKeys.indexOf( json.key ) > -1 && (
                        <small style={{ color: '#d92550' }}>
                          <TransComponent i18nKey={ 'form.desc.field_key_duplicate' }/>
                        </small>
                      )
                    }
                  </FormGroup>
                  <FormGroup>
                    <CustomInput
                      key={ `form-visible-key` }
                      id={ `form-visible-id` }
                      type='checkbox'
                      label={ <TransComponent i18nKey={ `form.fields.is-visible` }/> }
                      checked={ json.visible }
                      onChange={ e => onChangeFieldForm( 'visible', e.target.checked )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <CustomInput
                      key={ `form-required-key` }
                      id={ `form-required-id` }
                      type='checkbox'
                      label={ <TransComponent i18nKey={ `form.fields.is-required` }/> }
                      checked={ json.required }
                      onChange={ e => onChangeFieldForm( 'required', e.target.checked )}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      <TransComponent i18nKey={ `form.fields.field-type`}/>
                    </Label>
                    <Input
                      value={ json.variant }
                      type={ 'select' }
                      onChange={ e => onChangeFieldForm( 'variant', e.target.value )}
                    >
                      <option></option>
                      {
                        FieldTypeOptions.map( type => (
                          <option key={ `type_${ type }`} value={ type }>{ t( `form.desc.${ type }` ) }</option>
                        ))
                      }
                    </Input>
                  </FormGroup>
                </Col>
              </>
            )
          }
        </Row>
      </Form>
    </>
  )
}

export default EditTextFieldForm
