import React, { Component } from 'react'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { Get } from 'utils/axios'
import { requestError } from 'utils/requestHandler'

const sampleData = [
  {
    id: 1,
    de_name: 'Belästigung',
    en_name: 'Harassment'
  },
  {
    id: 2,
    de_name: 'Test',
    en_name: 'Test'
  }
]

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      // caseCategory: [],
      caseCategory: sampleData
    }

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeCategoryHOC = ( key, val ) => this.setState({ [ key ]: val })

    getCategory = () => Get(
      ``,
      this.getCategorySuccess,
      this.getCategoryError,
      this.load
    )
    getCategorySuccess = payload => {
      let temp = _.map( payload, item => ({
        ... item,
        en_name: item?.name?.en??'',
        de_name: item?.name?.de??''
      }))
      this.setState({ caseCategory: temp })
    }
    getCategoryError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          caseCategory={ this.state.caseCategory }
          onLoadCategory={ this.state.loading }

          onChangeCategoryHOC={ this.onChangeCategoryHOC }
          getCategory={ this.getCategory } 
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC