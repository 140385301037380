import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import { Get, Post, Put, Delete } from "utils/axios";
import { requestError, requestSuccess } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			users: [],
			selectedUser: {},
			searchParams: [
				{
					transKey: 'user.fields.first_name',
					value: 'first_name',
					type: 'text',
					col: 6,
					param: ''
				},
				{
					transKey: 'user.fields.last_name',
					value: 'last_name',
					type: 'text',
					col: 6,
					param: ''
				},
				{
					transKey: 'user.fields.email',
					value: 'email',
					type: 'text',
					col: 6,
					param: ''
				},
				{
					transKey: 'user.fields.role',
					value: 'role_id',
					type: 'select',
					col: 6,
					param: null,
					options: this.props.data.DictionaryReducer.roles
				},
			],

			showCreateModal: false,
			showDeleteModal: false,
			showUpdateModal: false,
			query: null
		};

		onChangeUserHOC = (key, val) => this.setState({ [key]: val });

		load = (param) => this.setState({ loading: param });

		getUsers = (id, query) => {
			if (id) {
				Get(
					`/users/${id}`,
					(payload) => this.getUsersSuccess([payload]),
					this.getUsersError,
					this.load
				);
			} else {
				this.setState({query}, () => {
					Get(`/users${query ? `?query=${query}` : ''}`, this.getUsersSuccess, this.getUsersError, this.load);
				})
			}
		};
		getUsersSuccess = (payload) => {
			let temp = _.map(payload.data, (user) => {
				let tempRole = _.find(this.props.data.DictionaryReducer.roles, {
					id: user.role_id,
				});

				return {
					...user,
					role_name: tempRole?.name ?? "test",
				};
			});

			this.setState({ users: temp });
		};
		getUsersError = (error) => requestError(error);

		getSelectedUser = (id) =>
			Get(
				`/users/${id}`,
				this.getSelectedUserSuccess,
				this.getSelectedUserError,
				this.load
			);
		getSelectedUserSuccess = (payload) => this.setState({ selected: payload });
		getSelectedUserError = (error) => requestError(error);

		createUser = (dataToSubmit, companyId) =>
			Post(
				`/users`,
				dataToSubmit,
				(payload) => this.createUserSuccess(payload, companyId),
				this.createUserError,
				this.load
			);
		createUserSuccess = (payload, companyId) => {
			const { role_id, id } = this.props.data.ProfileReducer.profile;

			if (role_id === 4) {
				this.getUsers(id);
			} else {
				this.getUsers(null, this.state.query);
			}

			this.setState({ showCreateModal: false });

			if (payload.role_id === 4) {
				this.props.createCompanyUser({
					user_id: payload.id,
					company_id: +companyId,
				});
			}
			requestSuccess("New user has been created successfully.");
		};
		createUserError = (error) => requestError(error);

		deleteUser = (id) =>
			Delete(
				`/users/${id}`,
				this.deleteUserSuccess,
				this.deleteUserError,
				this.load
			);
		deleteUserSuccess = () => {
			const { role_id, id } = this.props.data.ProfileReducer.profile;

			if (role_id === 4) {
				this.getUsers(id);
			} else {
				this.getUsers(null, this.state.query);
			}

			this.setState({ showDeleteModal: false });
			requestSuccess("User has been deleted succesfully.");
		};
		deleteUserError = (error) => requestError(error);

		updateUser = (id, dataToSubmit) =>
			Put(
				`/users/${id}`,
				dataToSubmit,
				this.updateUserSucces,
				this.updateUserError,
				this.load
			);
		updateUserSucces = (payload) => {
			const { role_id, id } = this.props.data.ProfileReducer.profile;

			if (role_id === 4) {
				this.getUsers(id);
			} else {
				this.getUsers(null, this.state.query);
			}

			this.getSelectedUser(payload.id);
			requestSuccess("User has been updated successfully.");
		};
		updateUserError = (error) => requestError(error);

		updatePW = (id, dataToSubmit) =>
			Put(
				`/users/${id}/reset_password`,
				dataToSubmit,
				this.updatePWSuccess,
				this.updatePWError,
				this.load
			);
		updatePWSuccess = () =>
			requestSuccess("Password has been updated successfully.");
		updatePWError = (error) => requestError(error);

		updateCompanyID = (dataToSubmit) =>
			Put(
				``,
				dataToSubmit,
				this.updateCompanyIDSuccess,
				this.updateCompanyIDError,
				this.load
			);
		updateCompanyIDSuccess = () =>
			requestSuccess("Company ID has been updated successfully.");
		updateCompanyIDError = (error) => requestError(error);

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					users={this.state.users}
					selectedUser={this.state.selectedUser}
					onLoadUsers={this.state.loading}
					showCreateModal={this.state.showCreateModal}
					searchParams={this.state.searchParams}
					showDeleteModal={this.state.showDeleteModal}
					showUpdateModal={this.state.showUpdateModal}
					getUsers={this.getUsers}
					getSelectedUser={this.getSelectedUser}
					createUser={this.createUser}
					deleteUser={this.deleteUser}
					updateUser={this.updateUser}
					updatePW={this.updatePW}
					updateCompanyID={this.updateCompanyID}
					onChangeUserHOC={this.onChangeUserHOC}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};

export default HOC;
