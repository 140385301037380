import React from 'react'
import { 
  Card, CardBody
} from 'reactstrap'
import ReactHTMLParser from 'react-html-parser'

import CustomButton from './CustomButton'
import RenderedButton from './RenderedButton'
import VisibleIndicator from './VisibleIndicator'

export default function TermsContent({
  formData,
  currentLang,
  selectedEdit,
  selectedCompany,

  onHandleChangeSelectedEdit
}) {
  const { 
    terms_content
  } = formData

  return (
    <>
      <Card style={{ background: terms_content.backgroundStyle[ 'background' ]}}>
        <CardBody>
          <RenderedButton
            currentLang={ currentLang }
						selectedEdit={ selectedEdit }
            buttonProps={ terms_content.case_login_button }
            buttonStyle={{ width: "100%", fontSize: 16 }}
						buttonKey={ 'case_login_button' }
            onClickEditButton={ () => onHandleChangeSelectedEdit( 'case_login_button' )}
          />
          <div 
            className={ `d-flex align-items-center flex-wrap transition_200 ${ selectedEdit === null ? 'bkp-form-renderer-title-hover' : '' }` }
            style={{
              marginBottom: 20, marginTop: 20,
              ... selectedEdit && 
              selectedEdit === 'title' && {
                boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
                border: '1px solid #467bc6',
                borderStyle: 'dotted'
              }
            }}
          >
          { ReactHTMLParser( terms_content.title.label[ currentLang ] )}
          <CustomButton
            type={ 'edit' }
            onClick={() => onHandleChangeSelectedEdit( 'title' )}
          />
        </div>
          {
            terms_content.content.map(( contentItem, contentIndex ) => (
              <div 
                className={ `d-flex align-items-center transition_200 ${ selectedEdit === null ? ' bkp-form-renderer-title-hover' : '' }` }
                style={{
                  ... selectedEdit && 
                  selectedEdit.contentContext === 'content' &&
                  selectedEdit.fieldIndex === contentIndex && {
                    boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
                    border: '1px solid #467bc6',
                    borderStyle: 'dotted'
                  }
                }}
              >
                {
                  contentItem.type === 'icon_list_item' && (
                    <div className='bkp-info-subcont'>
                      <img src={ `https://whistleblower-center.proof-point.com/assets/secure-icon.png` } alt="" />
                      { ReactHTMLParser( contentItem.label[ currentLang ] )}
                    </div>
                  )
                }
                {
                  contentItem.type === 'anchor' && (
                    <u className='bkp-anchor'>
                      { ReactHTMLParser( contentItem.label[ currentLang ] )}
                    </u>
                  )
                }
                {
                  (
                    contentItem.type !== 'icon_list_item' &&
                    contentItem.type !== 'anchor' 
                  ) && (
                    ReactHTMLParser( contentItem.label[ currentLang ] )
                  )
                }
                <CustomButton
                  type={ 'edit' }
                  onClick={() => {
                    onHandleChangeSelectedEdit({
                      cardIndex: null,
                      contentContext: 'content',
                      fieldIndex: contentIndex
                    })
                  }}
                />
                <VisibleIndicator
                  id={ contentItem.key }
                  visible={ contentItem.visible }
                />
              </div>
            ))
          }
          <div className="d-flex align-items-end mt-3">
            <span className='ml-auto mr-1' style={{ fontSize: 18 }}>
              power by:
            </span>
            <img style={{ width: 120 }} src={ `https://whistleblower-center.proof-point.com/assets/BKP-Logo.png` } alt="" />
          </div>
        </CardBody>
      </Card>
      <div className='mt-3' style={{ background: 'transparent' }}>
        <div 
          className={ `d-flex align-items-center transition_200 ${ selectedEdit === null ? ' bkp-form-renderer-title-hover' : '' }` }
          style={{
            ... selectedEdit && 
            selectedEdit === 'lawyer_phone_number' && {
              boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
              border: '1px solid #467bc6',
              borderStyle: 'dotted'
            }
          }}
        >
          {
            formData.lawyer_phone_number[currentLang].use_individual_number
              ? formData.lawyer_phone_number[currentLang].individual_number
              : selectedCompany?.lawyer_contact
          }
          <CustomButton
            type={ 'edit' }
            onClick={() => {
              onHandleChangeSelectedEdit('lawyer_phone_number')
            }}
          />
        </div>
        {
          terms_content.lawyer_contact_content.map(( laywerContent, laywerContentIndex ) => (
            <div 
              className={ `d-flex align-items-center transition_200 ${ selectedEdit === null ? ' bkp-form-renderer-title-hover' : '' }` }
              style={{
                ... selectedEdit && 
                selectedEdit.contentContext === 'lawyer_contact_content' &&
                selectedEdit.fieldIndex === laywerContentIndex && {
                  boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
                  border: '1px solid #467bc6',
                  borderStyle: 'dotted'
                }
              }}
            >
              { ReactHTMLParser( laywerContent.label[ currentLang ] ) }
              <CustomButton
                type={ 'edit' }
                onClick={() => {
                  onHandleChangeSelectedEdit({
                    cardIndex: null,
                    contentContext: 'lawyer_contact_content',
                    fieldIndex: laywerContentIndex
                  })
                }}
              />
            </div>
          ))
        }
      </div>
    </>
  )
}
