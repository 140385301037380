import React, { useEffect, useState } from 'react'
import { 
  UncontrolledTooltip, Button,
  Modal, ModalHeader, ModalBody
} from 'reactstrap' 

import FormBuilderForm from 'containers/Dashboard/FormLang/Form'
import FormLangHOC from '../actions/formLang'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import TransComponent from 'components/Trans'
import BKPTable from 'components/Table'

const AssignTranslated = ({
  getFormsLang,
  onSubmitAssign,

  formLangs,
  onLoadFormLang
}) => {

  useEffect(() => {
    getFormsLang()
  }, [])

  const [ selectedViewForm, setSelectedViewForm  ] = useState( null )

  return (
    <>
      <BKPTable
        pageSize={ 10 }
        data={ formLangs }
        columns={[
          {
            Header: <TransComponent i18nKey="form.fields.lang_label"/>,
            accessor: 'language_value'
          },
          {
            Header: <TransComponent i18nKey="form.fields.lang_key"/>,
            accessor: 'language_key'
          },
          {
            Header: <TransComponent i18nKey="general.action"/>,
            accessor: 'id',
            Cell: (row) => {
              return (
                <>
                  <Button
                    id={ `ViewForm_${ row.original.id }`}
                    className="mr-2 btn-icon btn-icon-only"
                    color="secondary"
                    onClick={() => setSelectedViewForm( row.original )}
                  >
                    <i className={ `btn-icon-wrapper pe-7s-info` }> </i>
                  </Button>
                  <UncontrolledTooltip target={ `ViewForm_${ row.original.id }`} placement="top">
                    <TransComponent i18nKey="form.tooltip.view_form_translation"/>
                  </UncontrolledTooltip>
                  <Button
                    color="primary"
                    onClick={() => onSubmitAssign( row.original )}
                  >
                    <TransComponent i18nKey={ 'general.assign' }/>
                  </Button>
                </>
              )
            }
          }
        ]
      }
      showPagination={ true } />
      <Modal
        isOpen={ selectedViewForm ? true : false }
        size={ 'xl' }
        style={{ maxWidth: '100%', margin: 0 }}
        >
        <ModalHeader toggle={ () => setSelectedViewForm( null )}>
          <TransComponent i18nKey={ 'form.title.view' }/>
        </ModalHeader>
        <ModalBody>
          <FormBuilderForm
            mode={ 'view' }
            selectedForm={ selectedViewForm }
          />
        </ModalBody>
      </Modal>
      { onLoadFormLang && <LoadingOverlay/> }
    </>
  )
}

export default FormLangHOC( AssignTranslated );