import React, { Component } from 'react'
import Moment from 'moment'
import _ from 'lodash'

import TransComponent from 'components/Trans'
import { Get, Post } from 'utils/axios'
import { requestError } from 'utils/requestHandler'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      onLoadSending: false,
      caseMessages: [],

      typedContent: ''
    }

    onChangeMessageHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getMessage = id => Get(
      `/cases/${ id }/messages`,
      this.getMessageSuccess,
      this.getMessageError,
      this.load
    )
    getMessageSuccess = payload => {
      let tempMessage = _.map( payload, item => (
        {
          ... item,
          sender: item.sender 
            ? <span>
                <span>{ item.sender }</span>
                { this.props.selectedCase.name === item.sender ? <span>(<TransComponent i18nKey={ 'general.whistleblower' }/>)</span> : '' }
              </span>
            : <span><TransComponent i18nKey={ 'general.anonymous' }/> (<TransComponent i18nKey={ 'general.whistleblower' }/>)</span>,
          messageType: 'text',
          created_at: Moment( item.created_at ).format( 'DD-MM-YY HH:mm' ),
          createAtUnix: Moment( item.created_at ).unix()
        }
      ))

      let tempCombined = [
        ... tempMessage,
        ... this.props.caseAttachements
      ]

      tempCombined = _.orderBy( tempCombined, 'createAtUnix', 'asc' )
      this.setState({ caseMessages: tempCombined })
    }
    getMessageError = error => requestError( error )

    sendMessage = dataToSubmit => {
      Post(
        `/cases/${ dataToSubmit.case_id }/messages`,
        dataToSubmit,
        () => this.sendMessageSuccess( dataToSubmit.case_id ),
        this.sendMessageError,
        val => this.setState({ onLoadSending: val })
      )
    }
    sendMessageSuccess = caseId => {
      if ( this.props.selectedCase.status === 'VIEWED' ) {
        this.props.getCases()
      }
      this.getMessage( caseId )
      this.setState({ typedContent: '' })
    }
    sendMessageError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          caseMessages={ this.state.caseMessages }
          onLoadMessage={ this.state.loading }
          onLoadSending={ this.state.onLoadSending }
          typedContent={ this.state.typedContent }

          onChangeMessageHOC={ this.onChangeMessageHOC }
          getMessage={ this.getMessage }
          sendMessage={ this.sendMessage }
        />
      )
    }
  }
  return WithHOC
}

export default HOC