import React from 'react'
import { 
  Button, UncontrolledTooltip
} from 'reactstrap'
import { AiFillFilePdf, AiFillFileImage } from 'react-icons/ai' 
import BKPTable from 'components/Table'

import TransComponent from 'components/Trans'

export default function ListView ({
  caseAttachements
}) {
  return (
    <>
      <BKPTable
        data={ caseAttachements }
        columns={[
          {
            Header: <TransComponent i18nKey="lawyer_case.fields.file_name"/>,
            Cell: (row) => (
              <span>{ row.original.name || 'N/A' }</span>
            )
          },
          {
            Header: <TransComponent i18nKey="lawyer_case.fields.file_type"/>,
            Cell: row => (
              <div className='d-flex align-items-center'>
                {
                  row.original.type.indexOf( 'Image' ) > -1 && (
                    <AiFillFileImage style={{ height: 23, width: 'max-content' }}/>
                  )
                }
                {
                  row.original.type.indexOf( 'PDF' ) > -1 && (
                    <AiFillFilePdf style={{ height: 23, width: 'max-content' }}/>
                  )
                }
                  <p className='mb-0 ml-2'>{ row.original.type }</p>
              </div> 
            )
          },
          {
            Header: <TransComponent i18nKey={ `general.action`}/>,
            Cell: row => (
              <>
                <Button
                  id={ `Download_${ row.original.id }` }
                  className="mr-2 btn-icon btn-icon-only"
                  color="primary"
                  onClick={() => window.open( row.original.url )}
                >
                  <i className="pe-7s-cloud-download btn-icon-wrapper"> </i>
                </Button>
                <UncontrolledTooltip target={ `Download_${ row.original.id }` } placement="top">Delete User</UncontrolledTooltip>
              </>
            ) 
          }
        ]
      }
      showPagination={false} 
    />  
  </>
  )
}
