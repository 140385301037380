import React, { useState, Fragment } from 'react'
import { ModalBody, Card } from 'reactstrap'
import _ from 'lodash'

import CaseInfo from './CaseInfo'
import Message from './Message'
import Remarks from './Remark'
import Attachment from '../Attachment'
import TransComponent from 'components/Trans'

const tabOptions = [
  { label: <TransComponent i18nKey="lawyer_case.tabs.message"/>, value: 1 },
  { label: <TransComponent i18nKey="lawyer_case.tabs.info"/>, value: 2 },
  { label: <TransComponent i18nKey="lawyer_case.tabs.remark"/>, value: 3 }
]

const DetailsModal = ({
  onClose,
  userType,
  ... props
}) => {

  const [ activeTab, onChangeActiveTab ] = useState( 1 )
  return (
    <>
      <ModalBody>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-wrap mb-3">
            {
              tabOptions.map( tab => (
                <Fragment key={ `Tab_${ tab.value }` }>
                  {
                    ( tab.value !== 3 ||
                    ( tab.value === 3 && userType === 'non-case' )) && (
                      <button
                        key={ tab.value }
                        className={ `btn mr-2 ${ activeTab === tab.value ? 'btn-primary' : 'btn-outline-primary' }` }
                        onClick={ () => onChangeActiveTab( tab.value )}
                      >
                        { tab.label }
                      </button>
                    )
                  }
                </Fragment>
              ))
            }
            {
              userType === 'case' && (
                <button
                  className={ `btn mr-2 ${ activeTab === 4 ? 'btn-primary' : 'btn-outline-primary' }` }
                  onClick={ () => onChangeActiveTab( 4 )}
                >
                  <TransComponent i18nKey="lawyer_case.tabs.attachment"/>
                </button>
              )
            }
          </div>
        </div>
        {
          activeTab === 1 && (
            <Message { ... props }/>
          )
        }
        {
          activeTab === 2 && (
            <CaseInfo { ... props }/>
          )
        }
        {
          activeTab === 3 && (
            <Remarks { ... props }/>
          )
        }
        {
          activeTab === 4 && (
            <Card>
              <Attachment { ... props }/>
            </Card>
          )
        }
      </ModalBody> 
    </>
  )
}


export default DetailsModal