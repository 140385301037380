import React from 'react'
import ReactTable from 'react-table'

import TransComponent from 'components/Trans'
import { useTranslation } from 'react-i18next'

export default function Table ({
  pageSize,
  data,
  columns,
  showPagination
}) {
  const { t } = useTranslation()
  return (
    <>
      <ReactTable
        pageSize={ pageSize }
        rowsText={ t( 'general.rows' ) }
        noDataText={ <TransComponent i18nKey="general.no_rows_found"/> }
        previousText={ <TransComponent i18nKey="general.previous"/> }
        nextText={ <TransComponent i18nKey="general.next"/> }
        pageText={ <TransComponent i18nKey="general.page"/>  }
        data={ data }
        columns={ columns }
        showPagination={ showPagination } /> 
    </>
  )
}
