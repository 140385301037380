import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  FormGroup, Input,
  Row, Col, Label,
  Card, CardHeader, CardBody,
  UncontrolledTooltip,
  Modal
} from 'reactstrap'
import ReactHTMLParser from 'react-html-parser'

import { getItem } from 'utils/tokenStore'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FaUserSecret } from 'react-icons/fa'
import { RiAddBoxLine } from 'react-icons/ri'
import { useTranslation } from 'react-i18next';

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import AddTypeModal from '../../Modal/AddTypeModal'
import AddLawyerModal from '../../Modal/AddLawyer'
import TransComponent from 'components/Trans'
import ConfirmationModal from 'components/Modal/confirmation'

import UserHOC from '../../actions/user'

const WhistleBlowerInfo = ['name', 'email', 'contact']

const CaseInfo = [
  {
    type: 'textarea',
    label: 'description',
    value: 'description',
    col: 12
  },
  'country',
  'city',
  'company_department'
]

const Info = ({
  data,
  forms,
  types = [],
  caseTypes = [],
  caseLawyer = [],

  selectedCase,
  selectedRemoveId,
  selectedDeleteCaseLawyer,

  onLoadCaseTypes,
  onLoadCase,
  onLoadUser,

  showAddTypeModal,
  showRemoveTypeModal,
  showAddLawyerModal,
  showDeleteLawyerModal,

  onChangeUserHOC,
  onChangeTypesHOC,
  addCaseType,
  getCaseType,
  deleteCaseType,
  getSelectCaseLawyers,
  deleteCaseLawyer,

  ...props
}) => {
  const { i18n } = useTranslation()
  let tempForm = _.find(forms, formChild => formChild.company_id === selectedCase.company_id)

  let isAdmin = [1, 2].indexOf(data.ProfileReducer.profile.role_id) > -1
  let tempUserType = getItem('BPK_USER_TYPE')
  const [caseInfo, onChangeCaseInfo] = useState({ is_anonymous: false })

  useEffect(() => {
    isAdmin && getCaseType(selectedCase.id)
  }, [])

  useEffect(() => {
    onChangeCaseInfo(selectedCase)
    getSelectCaseLawyers(selectedCase.id)
  }, [selectedCase])


  return (
    <>
      {
        tempUserType === 'non-case' && isAdmin && (
          <Card className="mb-3" >
            <CardHeader>
              <TransComponent i18nKey={'lawyer_case.title.case_type'} />
              {
                (tempUserType === 'non-case' && !selectedCase.is_closed) && (
                  <>
                    <RiAddBoxLine
                      id="AddType"
                      style={{ width: 20, height: 20, marginLeft: 7, cursor: 'pointer' }}
                      onClick={() => onChangeTypesHOC('showAddTypeModal', true)}
                    />
                    <UncontrolledTooltip target="AddType" placement="top">
                      <TransComponent i18nKey={`lawyer_case.tooltip.create_type`} />
                    </UncontrolledTooltip>
                  </>
                )
              }
            </CardHeader>
            <CardBody className='flex-wrap'>
              {
                caseTypes.length < 1 && (
                  <TransComponent i18nKey={`case.desc.case_type`} />
                )
              }
              {
                caseTypes.map(item => (
                  <span
                    className="p-2 badge badge-secondary mr-2"
                    style={{ fontSize: 11, width: 'max-content', verticalAlign: 'center' }}
                  >
                    {i18n.language === 'de' ? item.de_name : item.en_name}
                    {
                      (tempUserType === 'non-case' && !selectedCase.is_closed) && (
                        <AiOutlineCloseCircle
                          style={{ cursor: 'pointer', fontSize: 20, marginLeft: 8 }}
                          className={'color-warning'}
                          onClick={() => {
                            onChangeTypesHOC('showRemoveTypeModal', true)
                            onChangeTypesHOC('selectedRemoveId', item.id)
                          }}
                        />
                      )
                    }
                  </span>
                ))
              }
            </CardBody>
          </Card>
        )
      }
      <Card className='mb-3 mt-3'>
        <CardHeader>
          <TransComponent i18nKey={`lawyer_case.fields.case_card`} />
        </CardHeader>
        <CardBody>
          <Row>
            {
              CaseInfo.map(fieldInfo => (
                <Col lg={fieldInfo.col || 6}>
                  <FormGroup>
                    <Label>

                      {
                        _.find(tempForm?.form_json?.form_content?.content, contentChild => contentChild.key === fieldInfo)?.label?.[i18n.resolvedLanguage]
                          ? ReactHTMLParser(_.find(tempForm?.form_json?.form_content?.content, contentChild => contentChild.key === fieldInfo)?.label?.[i18n.resolvedLanguage] ?? '')
                          : <TransComponent i18nKey={`lawyer_case.fields.${typeof fieldInfo === 'string' ? fieldInfo : fieldInfo.label}`} />
                      }
                    </Label>
                    {
                      typeof fieldInfo === 'string' && (
                        <Input
                          type={'text'}
                          value={caseInfo[fieldInfo]}
                          disabled={true}
                        />
                      )
                    }
                    {
                      typeof fieldInfo !== 'string' && fieldInfo.type === 'textarea' && (
                        <Input
                          type={'textarea'}
                          rows="5"
                          value={caseInfo[fieldInfo.value]}
                          disabled={true}
                        />
                      )
                    }
                  </FormGroup>
                </Col>
              ))
            }
          </Row>
        </CardBody>
      </Card>
      {
        selectedCase.extra_fields && (
          <Card className='mb-3'>
            <CardBody>
              <Row>
                {
                  _.keys(selectedCase.extra_fields).map(key => (
                    <Col lg={6}>
                      <FormGroup>
                        <Label style={{ textTransform: 'capitalize' }}>
                          {key}
                        </Label>
                        {
                          typeof selectedCase.extra_fields[key] === 'string' && (
                            <Input
                              disabled
                              readOnly
                              value={selectedCase.extra_fields[key]}
                            />
                          )
                        }
                        {
                          typeof selectedCase.extra_fields[key] === 'object' && (
                            <div className="card p-3">
                              <ul style={{ paddingLeft: 12 }}>
                                {
                                  selectedCase.extra_fields[key].map(val => (
                                    <li>{val}</li>
                                  ))
                                }
                              </ul>
                            </div>
                          )
                        }
                      </FormGroup>
                    </Col>
                  ))
                }
              </Row>
            </CardBody>
          </Card>
        )
      }
      <Card className='mb-3'>
        <CardHeader>
          <TransComponent i18nKey={`lawyer_case.fields.lawyer`} />
          {
            (
              tempUserType === 'non-case' &&
              [1, 2, 3].indexOf(data.ProfileReducer.profile.role_id) > -1 &&
              !selectedCase.is_closed
            ) && (
              <>
                <RiAddBoxLine
                  id="AddLawyer"
                  style={{ width: 20, height: 20, marginLeft: 7, cursor: 'pointer' }}
                  onClick={() => onChangeUserHOC('showAddLawyerModal', true)}
                />
                <UncontrolledTooltip target="AddLawyer" placement="top">
                  <TransComponent i18nKey={`lawyer_case.tooltip.create_lawyer`} />
                </UncontrolledTooltip>
              </>
            )
          }
        </CardHeader>
        <CardBody>
          {
            caseLawyer.map(item => (
              <span
                className="p-2 badge badge-secondary mr-2"
                style={{ fontSize: 11, width: 'max-content', verticalAlign: 'center' }}
              >
                {item.user}
                {
                  (
                    tempUserType === 'non-case' &&
                    [1, 2, 3].indexOf(data.ProfileReducer.profile.role_id) > -1 &&
                    !selectedCase.is_closed
                  ) && (
                    <AiOutlineCloseCircle
                      style={{ cursor: 'pointer', fontSize: 20, marginLeft: 8 }}
                      className={'color-warning'}
                      onClick={() => {
                        onChangeUserHOC('showDeleteLawyerModal', true)
                        onChangeUserHOC('selectedDeleteCaseLawyer', item)
                      }}
                    />
                  )
                }
              </span>
            ))
          }
        </CardBody>
      </Card>
      {
        tempUserType === 'non-case' && (
          <>
            {
              caseInfo.is_anonymous && (
                <Card className="bg-secondary text-center color-white mb-3" >
                  <CardBody style={{ opacity: 0.7 }}>
                    <FaUserSecret style={{ width: 40, height: 40, color: 'white' }} />
                    <h6 className='mt-3' style={{ fontWeight: 600, color: 'white' }}>Anonymous User</h6>
                  </CardBody>
                </Card>
              )
            }
            {
              !selectedCase.is_anonymous && (
                <Card className='mb-3'>
                  <CardHeader>
                    <TransComponent i18nKey={`lawyer_case.fields.user_card`} />
                  </CardHeader>
                  <CardBody>
                    <Row>
                      {
                        WhistleBlowerInfo.map(fieldInfo => (
                          <Col lg={6}>
                            <FormGroup>
                              <Label>
                                <TransComponent i18nKey={`lawyer_case.fields.${fieldInfo}`} />
                              </Label>
                              <Input
                                type={'text'}
                                value={caseInfo[fieldInfo]}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        ))
                      }
                    </Row>
                  </CardBody>
                </Card>
              )
            }
          </>
        )
      }
      <Modal isOpen={showAddTypeModal}>
        <AddTypeModal
          types={types}
          onLoadCaseTypes={onLoadCaseTypes}
          onClickClose={() => onChangeTypesHOC('showAddTypeModal', false)}
          onClickSubmit={val => addCaseType({
            case_id: selectedCase.id,
            type_id: +val
          })}
        />
      </Modal>
      <Modal isOpen={showAddLawyerModal}>
        <AddLawyerModal
          onClose={() => onChangeUserHOC('showAddLawyerModal', false)}
          caseLawyer={caseLawyer}
          onLoadUser={onLoadUser}
          selectedCase={selectedCase}
          {...props}
        />
      </Modal>
      <ConfirmationModal
        open={showDeleteLawyerModal}
        loading={onLoadUser}
        titleKey={'lawyer_case.title.delete_lawyer'}
        messageKey={'lawyer_case.message.lawyer_delete'}
        onClose={() => onChangeUserHOC('showDeleteLawyerModal', false)}
        onClickConfirm={() => deleteCaseLawyer(selectedCase.id, selectedDeleteCaseLawyer.user_id)}
      />
      <ConfirmationModal
        open={showRemoveTypeModal}
        loading={onLoadCaseTypes}
        titleKey={'case_type.title.delete'}
        messageKey={'case_type.desc.delete'}
        onClose={() => onChangeTypesHOC('showRemoveTypeModal', false)}
        onClickConfirm={() => deleteCaseType(selectedRemoveId, selectedCase.id)} />
      {onLoadCase && <LoadingOverlay />}
    </>
  )
}

export default UserHOC(Info);