import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { 
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Row, Col,
  FormGroup, Label, Input,
  UncontrolledTooltip,
  Alert
} from 'reactstrap'
import _ from 'lodash'
import '../index.scss'

import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"

import TransComponent from 'components/Trans'
import { toast } from 'react-toastify'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import { DefaultFields, DefaultForm } from '../asset'

const FormContent = ({
  isAdmin,
  selectedCompany,
  
  onClickSubmit,
  onLoadCompanies
}) => {
  const [ companyForm, onChangeCompanyForm ] = useState( DefaultForm )
  
  useEffect(() => {
    onChangeCompanyForm({
      id: selectedCompany.id,
      name: selectedCompany.name,
      email: selectedCompany.email,
      contact: selectedCompany.contact,
      lawyer_contact: selectedCompany.lawyer_contact,
      unique_token: selectedCompany.unique_token,
      locale: selectedCompany.locale
    })
  }, [ selectedCompany ])
  
  let disableSubmit = useMemo(() => {
    return _.values( companyForm ).some( item => !item )
  }, [companyForm])

  const onChangeField = useCallback(( context, val ) => {
    onChangeCompanyForm({
      ... companyForm,
      [context]: val
    })
  }, [companyForm])

  const generateEmbedCode = () => {
    return (`<div id="bkp-plugin" data-access-token="${ companyForm.unique_token }"></div>
    <script src="${ getFormScriptURL() }"></script>`
    )
  }

  const getFormScriptURL = (() => {
    if ( [ 'localhost', 'staging' ].some( substr => { return window.location.hostname.indexOf(substr) >= 0 }) ) {
      return "https://staging.whistleblowing-compliant.eu/bkp-script.js"
    } else {
      return "https://portal.whistleblowing-compliant.eu/bkp-script.js"
    }
  })

  
  return (
    <>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <Row>
            {
              DefaultFields.map(( field, index ) => (
                <Col key={ `field-${ index }`} lg={ field.col || 12 }>
                  <FormGroup>
                    <Label className={ 'd-flex' }>
                      <TransComponent i18nKey={ `company.fields.${ field.transKey ? field.transKey : field.value }`}/>
                      {
                         field.required && (
                          <span className="text-danger">*</span>
                         )
                      }
                    </Label>
                    {
                      [ 'text', 'number' ].indexOf( field.type ) > -1 && (
                        <Input
                          disabled={ field.value === 'unique_token' || !isAdmin }
                          type={ field.type }
                          value={ companyForm[ field.value ] || '' }
                          onChange={ e => onChangeField( field.value, e.target.value )} 
                        />
                      )
                    }
                  </FormGroup>
                </Col>
              ))
            }
          </Row>
          { 
            companyForm.unique_token && companyForm.unique_token.length > 0 && (
              <>
                <Label className={ 'd-flex' }>
                  <TransComponent i18nKey={ "general.form_script" }/>
                </Label>
                <div 
                  style={{ cursor: 'pointer' }} id={ 'CopyScript' }
                  onClick={ () => {
                    navigator.clipboard.writeText( generateEmbedCode() )
                    toast.success( 'Script has been copied to the clipboard successfully.')
                  }}
                >
                  <Alert 
                    color="secondary"
                    style={{ whiteSpace: "pre-wrap" }}>
                    <code className="d-block" style={{ fontSize: '14px', color: '#495057' }}>
                      { generateEmbedCode() }
                    </code>
                  </Alert>
                </div>
                <UncontrolledTooltip target="CopyScript" placement="top">
                  <TransComponent i18nKey="company.tooltip.copy"/>
                </UncontrolledTooltip>
              </>
            ) 
          }
        </Form>
      </ModalBody>
      {
        isAdmin && (
          <ModalFooter>
            <Button 
              color="primary"
              disabled={ disableSubmit }
              className="mr-2"
              onClick={() => onClickSubmit( companyForm )}
            >
              <TransComponent i18nKey={ `general.submit`}/>
            </Button>
          </ModalFooter>
        )
      }
      { onLoadCompanies && <LoadingOverlay/> }
    </>
  )
}

export default FormContent