import React, { useState } from 'react'
import {
	Card, CardBody,
  Label, FormGroup, Input, Button, Form,
  Collapse
} from 'reactstrap'
import { BiCube } from 'react-icons/bi'
import { MdArrowDropDown } from 'react-icons/md'
import _ from 'lodash'
import { CompactPicker } from 'react-color'

import TransComponent from 'components/Trans'

export default function GeneralEditCard({
  formData,
  currentTab,
  selectedEdit,
  
  onChangeFormData
}) {

  const [ showContent, setShowContent ] = useState( true )

  return (
    <>
      <Card 
        className={ 'w-100 mt-4 mb-3' } 
        style={{ 
          ... !selectedEdit && {
            position: '-webkit-sticky',
            position: 'sticky', 
            top: 40
          }
        }}>
        <CardBody>
          <div 
            style={{ marginTop: -40 }}
            className={ 'd-flex align-items-center justify-content-around' }
          >
            <div className='d-flex align-items-center shadow rounded bg-white'  
              style={{ 
                width: 'max-content', 
                padding: '0.4rem',
                minWidth: 120, 
              }}
            >
              <div className='card p-2 bg-warning'>
                <BiCube className='text-white' style={{ width: 20, height: 20 }}/>
              </div>
              <span className='ml-2 mr-1 text-warning' style={{ fontWeight: '600', fontSize: '1rem' }}>
              <TransComponent i18nKey={ `general.general`}/>
              </span>
            </div>
            <div className="d-flex ml-auto">
              <Button 
                color={ 'warning' }
                style={{ padding: '0.35rem' }}
                onClick={ () => setShowContent( !showContent )}
              >
                <MdArrowDropDown 
                  className='text-white'
                  style={{ width: 20, height: 20, transform: showContent ? 'rotate(180deg)' : 'rotate(0deg)' }}
                />
              </Button>
            </div>
          </div>
          <Collapse isOpen={ showContent }>
            <Form className="mt-4">
              <FormGroup>
                <Label className='d-block'>
                  <TransComponent i18nKey={ `general.locale`}/>
                </Label>
                <Input
                  type={ 'select' }
                  value={ formData.locale }
                  onChange={ e => onChangeFormData({
                    ... formData,
                    locale: e.target.value
                  })}
                >
                  <option value=""></option>
                  {
                    formData.language_options.map(( lang, langIndex ) => (
                      <option key={ `lang_${ langIndex }` } value={ lang.value }>
                        { lang[ 'label' ] }
                      </option>
                    ))
                  }
                </Input>
              </FormGroup>
              <FormGroup>
                <Label className='d-block'>
                  <TransComponent i18nKey={ `general.background`}/>
                </Label>
                <CompactPicker
                  color={ formData[ currentTab ].backgroundStyle[ 'background' ] }
                  onChange={ col => {
                    let tempContent = _.cloneDeep( formData[ currentTab ] )
                    tempContent.backgroundStyle.background = col.hex
                    
                    onChangeFormData({
                      ... formData,
                      [ currentTab ]: tempContent
                    })
                  }}
                />
              </FormGroup>
            </Form>
          </Collapse>
        </CardBody>
      </Card>
    </>
  )
}
