import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Get, Post, Put, Delete } from 'utils/axios'
import { requestError, requestSuccess } from 'utils/requestHandler'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      roles: [],
      selectedRole: {},

      showCreateModal: false,
      showDeleteModal: false,
      showUpdateModal: false
    }

    onChangeRoleHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getRoles = () => {
      Get(
        `/roles`,
        this.getRolesSuccess,
        this.getRolesError,
        this.load
      )
    }
    getRolesSuccess = payload => this.setState({ roles: payload })
    getRolesError = error => requestError( error )

    getSelectedRole = id => Get(
      `/roles/${ id }`,
      this.getSelectedRoleSuccess,
      this.getSelectedRoleError,
      this.load
    )
    getSelectedRoleSuccess = payload => this.setState({ selectedRole: payload })
    getSelectedRoleError = error => requestError ( error )

    createRole = dataToSubmit => Post(
      `/roles`,
      dataToSubmit,
      this.createRoleSuccess,
      this.createRoleError,
      this.load
    )
    createRoleSuccess = () => {
      this.getRoles()
      requestSuccess( 'New role has been created successfully.' )
      this.setState({ showCreateModal: false })
    }
    createRoleError = error => requestError( error )

    deleteRole = id => Delete( 
      `/roles/${ id }`,
      this.deleteRoleSuccess,
      this.deleteRoleError,
      this.load
    )
    deleteRoleSuccess = () => {
      this.getRoles()
      requestSuccess( 'Role has been deleted succesfully.' )
      this.setState({ showDeleteModal: false })
    }
    deleteRoleError = error => requestError( error )

    updateRole = ( id, dataToSubmit ) => Put(
      `/roles/${ id }`,
      dataToSubmit,
      this.updateRoleSucces,
      this.updateRoleError,
      this.load
    )
    updateRoleSucces = payload => {
      this.getRoles()
      this.getSelectedRole( payload.id )
      requestSuccess( 'Role has been updated successfully.' )
    }
    updateRoleError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          roles={ this.state.roles }
          selectedRole={ this.state.selectedRole }
          onLoadRoles={ this.state.loading }
          
          showCreateModal={ this.state.showCreateModal }
          showDeleteModal={ this.state.showDeleteModal }
          showUpdateModal={ this.state.showUpdateModal }

          getRoles={ this.getRoles }
          getSelectedRole={ this.getSelectedRole }
          createRole={ this.createRole }
          deleteRole={ this.deleteRole }
          updateRole={ this.updateRole }
          onChangeRoleHOC={ this.onChangeRoleHOC }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC