import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'

import { requestError, requestSuccess } from 'utils/requestHandler'
import { Post, Delete, Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showAddTypeModal: false,
      showRemoveTypeModal: false,

      caseTypes: [],
      selectedRemoveId: null,
    }

    load = param => this.setState({ loading: param })

    onChangeTypesHOC = ( key, val ) => this.setState({ [ key ]: val })

    getCaseType = id => Get(
      `/cases_types/by_case/${ id }`,
      this.getCaseTypeSuccess,
      this.getCaseTypeError,
      this.load
    )
    getCaseTypeSuccess = payload => {
      let tempCaseTypes = _.map( payload, item => {
        let tempType = _.find( this.props.types, { id: item.type_id })
        return {
          ... item,
          en_name: tempType.en_name,
          de_name: tempType.de_name
        }
      })
      this.setState({ caseTypes: tempCaseTypes })
    }
    getCaseTypeError = error => requestError( error )

    addCaseType = dataToSubmit => Post(
      `/cases_types`,
      dataToSubmit,
      this.addCaseTypeSuccess,
      this.addCaseTypeError,
      this.load
    )
    addCaseTypeSuccess = payload => {
      this.getCaseType( payload.case_id )
      requestSuccess( 'New type has been added to the case.')
      this.setState({ showAddTypeModal: false })
    }
    addCaseTypeError = error => requestError( error )

    deleteCaseType = ( id, caseId ) => Delete( 
      `/cases_types/${ id }`,
      () => this.deleteCaseTypeSuccess( caseId ),
      this.deleteCaseTypeError,
      this.load
    )
    deleteCaseTypeSuccess = caseId => {
      this.getCaseType( caseId )
      this.setState({ showRemoveTypeModal: false })
      requestSuccess( 'Case Type has been removed succesfully.' )
    }
    deleteCaseTypeError = error => requestError( error )
    
    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          caseTypes={ this.state.caseTypes }
          onLoadCaseTypes={ this.state.loading }
          showAddTypeModal={ this.state.showAddTypeModal }
          showRemoveTypeModal={ this.state.showRemoveTypeModal }
          selectedRemoveId={ this.state.selectedRemoveId }

          onChangeTypesHOC={ this.onChangeTypesHOC }
          deleteCaseType={ this.deleteCaseType }
          addCaseType={ this.addCaseType }
          getCaseType={ this.getCaseType }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return  connect( mapStateToProps )( WithHOC )
}

export default HOC