import React, { createRef } from 'react'
import {
	Card, CardBody
} from 'reactstrap'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'

import CustomButton from './CustomButton'

import RenderedButton from './RenderedButton'
import RenderedField from './RenderedField'
import RenderedCard from './RenderedCard'
import '../../index.scss'

const FormRenderer = ({
	mode,
	formData,
	selectedEdit,

	onHandleChangeSelectedEdit,
	onChangeFieldForm
}) => {
	const cardBottomRef = createRef( null )

  return (
		<>
			<Card>
				<CardBody>
					<div 
						className={ `d-flex align-items-center transition_200 bkp-form-renderer-title-hover` }
						style={{
							... selectedEdit === 'title' && {
								boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
								border: '1px solid #467bc6',
								borderStyle: 'dotted'
							}
						}}
					>
						<h2 
							className={ `mb-2` }
							style={{ fontWeight: 600 }}
						>
							{
								ReactHTMLParser( formData.form_content.title.label)
							}
						</h2>
						{
							mode !== 'view' && (
								<CustomButton
									type={ 'edit' }
									onClick={() => onHandleChangeSelectedEdit( 'title' )}
								/>
							)
						}
					</div> 
					{
						formData.form_content.content.map(( json, jsonIndex ) => (
							<div className={ 'rounded' }>
								{
									json.type === 'card' && (
										<RenderedCard
											editIndex={{
												cardIndex: null,
												contentContext: 'content',
												fieldIndex: jsonIndex
											}}
											mode={ mode }
											formData={ formData }
											json={ json } jsonIndex={ jsonIndex }
											onHandleChangeSelectedEdit={ onHandleChangeSelectedEdit }
											selectedEdit={  selectedEdit }

											onChangeFieldForm={ onChangeFieldForm }
										/>
									)
								}
								{
									json.type === 'field' && (
										<RenderedField 
											mode={ mode }
											editIndex={{
												cardIndex: null,
												contentContext: 'content',
												fieldIndex: jsonIndex
											}}
											formData={ formData }
											index={ jsonIndex }
											selectedEdit={ selectedEdit }
											json={ json }
											onClickField={ () => {
												onHandleChangeSelectedEdit({
													cardIndex: null,
                          contentContext: 'content',
													fieldIndex: jsonIndex
												})
											}}
											onChangeFieldForm={( key, val ) => onChangeFieldForm( key, val, jsonIndex )}
										/>
									)
								}
							</div>
						))
					}
					<RenderedButton
						mode={ mode }
						buttonProps={ formData.form_content.submit_button }
						buttonKey={ 'submit_button' }
						selectedEdit={ selectedEdit }
						onClickEditButton={ () => onHandleChangeSelectedEdit( 'submit_button' )}
					/>
					<div 
						className={ `d-flex align-items-center transition_200` }
						style={{
							... selectedEdit === 'title' && {
								boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
								border: '1px solid #467bc6',
								borderStyle: 'dotted'
							}
						}}
					>
						<div 
							className={ `mb-2` }
							style={{ fontWeight: 600 }}
						>
							{
								ReactHTMLParser( formData.form_content.term_content.label )
							}
						</div>
						<CustomButton
							type={ 'edit' }
							onClick={() => onHandleChangeSelectedEdit( 'term_content' )}
						/>
					</div> 
				</CardBody>
			</Card>
			<div style={{ float:"left", clear: "both" }} ref={ cardBottomRef }/>
		</>
	)
}

export default FormRenderer