import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Get,  } from 'utils/axios'
import { requestError } from 'utils/requestHandler'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      formLangs: []
    }

    load = param => this.setState({ loading: param })

    getFormsLang = () => Get(
      `/default_forms`,
      this.getFormsLangSuccess,
      this.getFormsLangError,
      this.load
    )
    getFormsLangSuccess = payload => this.setState({ formLangs: payload })
    getFormsLangError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          formLangs={ this.state.formLangs }
          onLoadFormLang={ this.state.loading }

          getFormsLang={ this.getFormsLang }
        />
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC )
}

export default HOC