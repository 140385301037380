import React from 'react'
import { Button } from 'reactstrap'
import CustomButton from './CustomButton'
import ReactHTMLParser from 'react-html-parser'

export default function RenderedButton({
  buttonKey,
  currentLang,
  buttonProps,
  buttonStyle,
  groupMode,
  selectedEdit,
  
  onClickEditButton
}) {
  return (
    <div 
      className={ `d-flex align-items-center flex-wrap transition_200 ${ selectedEdit === null ? 'bkp-form-renderer-title-hover' : '' }` }
      style={{
        marginBottom: 20, marginTop: 20,
        ... selectedEdit && 
        selectedEdit === buttonKey && {
          boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
          border: '1px solid #467bc6',
          borderStyle: 'dotted'
        }
      }}
    >
      <div
        className='bnt btn-primary' 
        onClick={ e => e.preventDefault()}
        style={{ 
          padding: '0.375rem 0.75rem',
          borderRadius: '0.25rem',
          ... buttonStyle,
          color: buttonProps.style.color, 
          background: buttonProps.style.background,
          borderColor: buttonProps.style.background
        }}
      >
        {
          ReactHTMLParser( buttonProps.label[ currentLang ] )
        }
        {
          !groupMode && (
            <CustomButton
              type={ 'edit' }
              onClick={ onClickEditButton }
            />
          )
        }
      </div>
    </div>
  )
}
