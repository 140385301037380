  import React, { useState } from 'react'
  import { 
    Row, Col,
    Form, Label, FormGroup, Button,
    Card, CardBody, Collapse
  } from 'reactstrap'
  import _ from 'lodash'
  import { IoIosClose } from 'react-icons/io'
  import { AiOutlineCloudUpload } from 'react-icons/ai'
  import { IoMdCheckbox } from 'react-icons/io'
  import { GiPlayButton } from 'react-icons/gi'
  import { MdTextFields, MdArrowDropDown } from 'react-icons/md'
  import { RiLockPasswordFill } from 'react-icons/ri'
  import { BsTextParagraph } from 'react-icons/bs'
  import { useTranslation } from 'react-i18next'
  import RTEInput from 'components/Input/RTE'

  import TransComponent from 'components/Trans'

  const EditCard = ({ 
    json,
    onChangeFieldForm,
    onClickDismiss
  }) => {
    const { t } = useTranslation()
    const [ showContent, setShowContent ] = useState( true )

    return (
      <div style={{ 
        position: '-webkit-sticky',
        position: 'sticky', 
        top: 0
      }}>
        <Card className='mb-3 mt-4'>
          <CardBody>
            <div 
              style={{ marginTop: -40 }}
              className={ 'd-flex align-items-center justify-content-around' }
            >
              <div className='d-flex align-items-center shadow rounded bg-white'  
                style={{ 
                  width: 'max-content', 
                  padding: '0.4rem',
                  minWidth: 120, 
                }}
              >
                <div className='card p-2 bg-warning'>
                  {
                    json.variant === 'checkbox' && (
                      <IoMdCheckbox className='text-white' style={{ width: 20, height: 20 }}/>
                    )
                  }
                  {
                    json.variant === 'textarea' && (
                      <BsTextParagraph className='text-white' style={{ width: 20, height: 20 }}/>
                    )
                  }
                  {
                    json.variant === 'file' && (
                      <AiOutlineCloudUpload className='text-white' style={{ width: 20, height: 20 }}/>
                    )
                  } 
                  {
                    json.variant === 'password' && (
                      <RiLockPasswordFill className='text-white' style={{ width: 20, height: 20 }}/>
                    )
                  }
                  {
                    json.type === 'card' && (
                      <RiLockPasswordFill className='text-white' style={{ width: 20, height: 20 }}/>
                    )
                  }
                  {
                    json.type === 'button' && (
                      <GiPlayButton className='text-white' style={{ width: 20, height: 20 }}/>
                    )
                  }
                  {
                    (( json.type !== 'button' && json.type !== 'card' && json.type !== 'field' )|| json.variant === 'text' ) && (
                      <MdTextFields className='text-white' style={{ width: 20, height: 20 }}/>
                    )
                  }
                </div>
                <span className='ml-2 mr-1 text-warning' style={{ fontWeight: '600', fontSize: '1rem' }}>
                {
                    ( json.type !== 'button' && json.type !== 'card' && json.type !== 'field' ) && (
                      <TransComponent i18nKey={ 'general.text' }/>
                    )
                  }
                  {
                    ( json.type === 'button' || json.type === "card" || json.type === 'field' ) && (
                      <TransComponent i18nKey={ `form.desc.${ json.type === 'field' ? json.variant : json.type }`}/>
                    )
                  }
                </span>
              </div>
              <div className="d-flex ml-auto">
                <Button 
                  color={ 'warning' }
                  style={{ padding: '0.35rem' }}
                  onClick={ () => setShowContent( !showContent )}
                >
                  <MdArrowDropDown 
                    className='text-white'
                    style={{ 
                      width: 20, height: 20, 
                      transform: showContent ? 'rotate(180deg)' : 'rotate(0deg)' 
                    }}
                  />
                </Button>
                <Button 
                  color={ 'danger' } 
                  style={{ padding: '0.35rem' }}
                  className={ 'ml-2' }
                  onClick={ () => onClickDismiss()}
                >
                  <IoIosClose style={{ width: 20, height: 20 }}/>
                </Button>
              </div>
            </div>
            <Collapse isOpen={ showContent }>
              <Form className='mt-4'>
                <Row>
                  <Col lg={ 12 }>
                    <FormGroup>
                      <Label>
                        <TransComponent i18nKey={ `form.fields.field-label`}/>
                      </Label>
                      <RTEInput
                        data= { json.label }
                        onChange={ val => onChangeFieldForm( 'label', val )}
                      />
                    </FormGroup>
                  </Col>
                  {/* {
                    json.type === 'field' && 
                    json.variant === 'file' && (
                      <Col lg={ 12 }>
                        <FormGroup>
                          <Label className='d-block'>
                            <TransComponent i18nKey={ `form.fields.button-label`}/>
                          </Label>
                          <RTEInput
                            data={ json.btn_label }
                            onChange={( _, editor ) => onChangeFieldForm( 'btn_label', editor.getData() )}
                          />
                        </FormGroup>
                      </Col>
                    )
                  } */}
                </Row>
              </Form>
            </Collapse>
          </CardBody>
        </Card>
      </div>
    )
  }

  export default EditCard
