import React from 'react'
import _ from 'lodash'

import EditCard from './EditCard'
import TabSelection from './TabSelection'

export default function EditSections({
  formData,
  currentLang,
  selectedEdit,
  currentTab,
  
  setCurrentTab,
  setSelectedEdit,
  onChangeFormData,
  onChangeFieldForm,
  onChangeButtonForm
}) {
  
  return (
    <>
      <TabSelection
        currentTab={ currentTab }
        setCurrentTab={ setCurrentTab }
      />
      {
        selectedEdit === 'title' && (
          <EditCard 
            currentLang={ currentLang }
            json={{
              ... formData[ currentTab ].title,
              type: 'title'
            }}
            onClickDismiss={ () => setSelectedEdit( null )}
            onChangeFieldForm={ ( key, val ) => {
              let tempTitle = _.cloneDeep( formData[ currentTab ].title )
              tempTitle[ key ] = val

              onChangeFormData({
                ... formData,
                [ currentTab ]: {
                  ... formData[ currentTab ],
                  title: tempTitle
                }
              })
            }}
          />
        )
      }
      {
        selectedEdit === 'term_content' && (
          <EditCard 
            currentLang={ currentLang }
            json={{
              ... formData[ currentTab ].term_content,
              type: 'textarea'
            }}
            onClickDismiss={ () => setSelectedEdit( null )}
            onChangeFieldForm={ ( key, val ) => {
              onChangeFormData({
                ... formData,
                [ currentTab ]: {
                  ... formData[ currentTab ],
                  term_content: {
                    label: val,
                    style: formData[ currentTab ].term_content.style
                  }
                }
              })
            }}
          />
        )
      }
      {
        typeof selectedEdit === 'string' && 
        selectedEdit.indexOf( 'button' ) > -1 && (
          <>
            <EditCard
              currentLang={ currentLang } 
              json={ formData[ currentTab ][ selectedEdit ] }
              onClickDismiss={ () => setSelectedEdit( null )}
              onChangeFieldForm={ ( key, val ) => onChangeButtonForm( key, val, selectedEdit  )}
            />
          </>
        )
      }
      {
        selectedEdit && 
        typeof selectedEdit === 'object' && (
          <EditCard
            currentLang={ currentLang } 
            json={ 
              selectedEdit.cardIndex 
                ? formData[ currentTab ][ selectedEdit.contentContext ][ selectedEdit.cardIndex ][ selectedEdit.contentContext ][ selectedEdit.fieldIndex ] 
                : formData[ currentTab ][ selectedEdit.contentContext ][ selectedEdit.fieldIndex ]
            }
            onClickDismiss={ () => setSelectedEdit( null )}
            onChangeFieldForm={ ( key, val ) => onChangeFieldForm( key, val, selectedEdit.fieldIndex, selectedEdit.cardIndex , selectedEdit.contentContext )}
          />
        )
      }
    </>
  )
}
