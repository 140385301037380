import React from 'react'
import { 
  Card, CardBody
} from 'reactstrap'
import ReactHTMLParser from 'react-html-parser'

import CustomButton from './CustomButton'
import TransComponent from 'components/Trans'
import RenderedButton from './RenderedButton'

export default function ReportingProcedure({
  formData,
  selectedEdit,
  mode,

  onHandleChangeSelectedEdit
}) {

  const { case_success_content } = formData
  return (
    <Card>
      <CardBody>
        {
          case_success_content.content.map(( contentItem, contentIndex ) => (
            <>
              <div 
                className={ `d-flex align-items-center flex-wrap transition_200 ${ selectedEdit === null ? 'bkp-form-renderer-title-hover' : '' }` }
                style={{
                  ... selectedEdit && 
                  selectedEdit.fieldIndex === contentIndex && {
                    boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
                    border: '1px solid #467bc6',
                    borderStyle: 'dotted'
                  }
                }}
              
              >
                { ReactHTMLParser( contentItem.label ) }
                {
                  contentIndex !== 0 && (
                    <strong>{ ` : xxxxx` }</strong>
                  )
                }
                {
                  mode !== 'view' && (
                    <CustomButton
                      type={ 'edit' }
                      onClick={() => {
                        onHandleChangeSelectedEdit({
                          cardIndex: null,
                          contentContext: 'content',
                          fieldIndex: contentIndex
                        })
                      }}
                    />
                  )
                }
              </div>
              {
                contentItem.type === 'title' && (
                  <hr />
                ) 
              }
            </>
          ))
        }
        {/* <div className="d-flex mt-3">
          <TransComponent i18nKey={ 'form.fields.case_id' }/>
          <strong>{ ` : xxxxx` }</strong>
        </div>
        <div className="d-flex mt-3">
          <TransComponent i18nKey={ 'form.fields.login_link' }/>
          <strong>{ ` : xxxxx` }</strong>
        </div> */}
        <RenderedButton
          mode={ mode }
					buttonProps={ case_success_content.download_case_summary_button }
          buttonStyle={{ marginTop: 10 }}
          selectedEdit={ selectedEdit }
          buttonKey={ 'download_case_summary_button' }
          onClickEditButton={ () => onHandleChangeSelectedEdit( 'download_case_summary_button' )}
				/>
      </CardBody>
    </Card>
  )
}
