import React, { Component } from 'react'

import { Get, Post, Delete } from 'utils/axios'
import { requestSuccess, requestError } from 'utils/requestHandler'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      
      companyRepresentative: null,
      lawyerOptions: [],

      caseLawyer: [],
      selectedDeleteCaseLawyer: null,
      showDeleteLawyerModal: false,
      showAddLawyerModal: false
    }

    onChangeUserHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })

    getLawyer = () => Get(
      `/users/get_lawyers`,
      this.getLawyerSuccess,
      this.getLawyerError,
      this.load
    )
    getLawyerSuccess = payload => this.setState({ lawyerOptions: payload })
    getLawyerError = error => requestError( error )

    getCompanyUser = id => Get(
    `/companies/${ id }/users`,
      this.getCompanyUserSuccess,
      this.getCompanyUserError,
      this.load
    )
    getCompanyUserSuccess = payload => {
      payload?.[0] && this.getSelectedUser( payload[0].user_id )
    }
    getCompanyUserError = error => requestError( error )

    getSelectedUser = id => Get(
      `/users/${ id }`,
      this.getSelectedUserSuccess,
      this.getSelectedUserError,
      this.load
    )
    getSelectedUserSuccess = payload => this.setState({ 
      companyRepresentative: payload 
    })
    getSelectedUserError = error => requestError ( error )

    getSelectCaseLawyers = caseID => Get(
      `/cases/${ caseID }/users`,
      this.getSelectCaseLawyersSuccess,
      this.getSelectCaseLawyersError,
      this.load
    )
    getSelectCaseLawyersSuccess = payload => this.setState({ caseLawyer: payload })
    getSelectCaseLawyersError = error => requestError( error )

    addCaseLawyer = ( caseID, userID ) => Post(
      `/cases/${ caseID }/users`,
      { user_id: userID },
      () => this.addCaseLawyerSuccess( caseID ),
      this.addCaseLawyerError,
      this.load
    )
    addCaseLawyerSuccess = caseID => {
      requestSuccess( 'Lawyer added successfully.' )
      this.getSelectCaseLawyers( caseID )
      this.setState({ showAddLawyerModal: false })
    }
    addCaseLawyerError = error => requestError( error )

    deleteCaseLawyer = ( caseID, userID ) => Delete(
      `/cases/${ caseID }/users/${ userID }`,
      () => this.deleteCaseLawyerSuccess( caseID ),
      this.deleteCaseLawyerError,
      this.load
    )
    deleteCaseLawyerSuccess = id => { 
      requestSuccess( 'Lawyer removed successfully.' )
      this.getSelectCaseLawyers( id )
      this.setState({ showDeleteLawyerModal: false })
    }
    deleteCaseLawyerError = error => requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadUser={ this.state.loading }
          companyRepresentative={ this.state.companyRepresentative }
          lawyerOptions={ this.state.lawyerOptions }
          caseLawyer={ this.state.caseLawyer }
          selectedDeleteCaseLawyer={ this.state.selectedDeleteCaseLawyer }
          showDeleteLawyerModal={ this.state.showDeleteLawyerModal }
          showAddLawyerModal={ this.state.showAddLawyerModal }

          onChangeUserHOC={ this.onChangeUserHOC }
          getLawyer={ this.getLawyer }
          getCompanyUser={ this.getCompanyUser }

          getSelectCaseLawyers={ this.getSelectCaseLawyers }
          addCaseLawyer={ this.addCaseLawyer }
          deleteCaseLawyer={ this.deleteCaseLawyer }
        />
      )
    }
  }
  return WithHOC
}

export default HOC