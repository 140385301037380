import CryptoJS from 'crypto-js'
import getDomainURL from 'utils/api-non-login'
import { encryptionKey } from 'utils/encryptionHandler'

const Lang = {
  ALERT: {
    'en': 'Alert',
    'de': 'Alarm',
  },
  TITLE: {
    'en': 'Whistleblower Message',
    'de': 'Hinweisgeber Meldung'
  },
  WHAT_IS_YOUR_SUSPICION: {
    'en': 'What is your suspicion?',
    'de': 'Worauf bezieht sich Ihr Verdacht?'
  },
  ASK_FOR_AFFECTED_DEPARTMENT: {
    'en': 'Please give the name of the affected department',
    'de': 'Bitte geben Sie den Namen der betroffenen Abteilung an'
  },
  ASK_FOR_COUNTRY: {
    'en': 'In which country did the incident take place?',
    'de': 'In welchem Land hat sich der Vorfall ereignet?'
  },
  ASK_FOR_CITY: {
    'en': 'In which city did the incident occur?',
    'de': 'In welcher Stadt hat sich der Vorfall ereignet?'
  },
  IS_ANONYMOUS: {
    'en': 'Stay anonymous',
    'de': 'Anonym bleiben'
  },
  CONTACT_INFO: {
    'en': 'Contact information',
    'de': 'Kontaktinformationen'
  },
  EMAIL: {
    'en': 'Email',
    'de': 'E-Mail'
  },
  NAME: {
    'en': 'First and last name',
    'de': 'Vor- und Nachname'
  },
  CONTACT: {
    'en': 'Phone number',
    'de': 'Telefonnummer'
  },
  PASSWORD: {
    'en': 'Password',
    'de': 'Passwort'
  },
  REPEAT_PASSWORD: {
    'en': 'Repeat Password',
    'de': 'Passwort wiederholen'
  },
  SUBMIT: {
    'en': 'Submit',
    'de': 'Senden'
  },
  SUBMITTED_SUCCESSFULLY: {
    'en': 'Your report was successfully submitted and will be processed promptly. With your password and case number you can follow up the case at any time and send us additional messages. Please keep your case number and password in a safe place. If lost, access cannot be restored for anonymous users.',
    'de': 'Ihre Meldung wurde erfolgreich übermittelt und wird zeitnah bearbeitet. Mit ihrem Passwort und ihrer Fallnummer können sie den Fall jederzeit weiter verfolgen und uns zusätzliche Nachrichten übermitteln. Bitte bewahren sie ihre Fallnummer und das Passwort gut auf. Bei Verlust kann bei anonymen Benützern der Zugang nicht wieder hergestellt werden.'
  },
  UNMATCH_PASSWORD: {
    'en': 'The passwords are not identical.',
    'de': 'Die Passwörter sind nicht identisch.'
  },
  PASSWORD_TOO_SHORT: {
    'en': 'The password requires at least 8 letters.',
    'de': 'Das Passwort benötigt mindestens 8 Buchstaben.'
  },
  MISSING_INFORMATION: {
    'en': 'Some information is missing.',
    'de': 'Es fehlen einige Informationen.'
  },
  CLOSE: {
    'en': 'Close',
    'de': 'Schließen'
  },
  SAFE_AND_ANONYMOUS: {
    'en': 'Your data is safe & anonymous!',
    'de': 'Ihre Daten sind sicher & anonym!'
  },
  HUNDRED_PERCENT_ANONYMOUS_REPORTNG: {
    'en': '100% anonymous reporting',
    'de': '100% anonyme Meldung'
  },
  CERTIFIED_DATA_SECURITY: {
    'en': 'certified data security',
    'de': 'zertifizierte Datensicherheit'
  },
  INDEPENDENT_OMBUDSMAN: {
    'en': 'independent ombudsman service',
    'de': 'unabhängige Ombudsstelle'
  },
  WHAT_IS_THE_REPORTING_PRECEDURE: {
    'en': 'What is the reporting procedure?',
    'de': 'Wie ist der Ablauf einer Meldung?'
  },
  WHAT_IS_THE_REPORTING_PRECEDURE_CONTENT_1: {
    'de': 'Wenn Sie eine Meldung übermitteln, haben Sie die Möglichkeit, ein sicheres Postfach zu erstellen und so für weitere Rückfragen zur Verfügung zu stehen.',
    'en': 'When you submit a report, you have the option of creating a secure mailbox so that you are available for further queries.'
  },
  WHAT_IS_THE_REPORTING_PRECEDURE_CONTENT_2: {
    'de': 'Wir empfehlen, sich auf diese Weise verfügbar zu halten, da wir den Fall möglicherweise nicht ohne weitere Informationen von Ihnen abschließen können.',
    'en': 'We recommend keeping yourself available this way as we may not be able to close the case without further information from you.'
  },
  WHAT_IS_THE_REPORTING_PRECEDURE_CONTENT_3: {
    'de': 'Wenn Sie ein Postfach erstellen, wird Ihnen eine Fallnummer zugeteilt, und Sie müssen ein Passwort (PIN) wählen. Diese Fallnummer und dieses Passwort (PIN) müssen Sie verwenden, um Zugang zum Postfach zu erhalten und um nachzusehen, ob Sie Fragen erhalten haben.',
    'en': 'When you create a mailbox, you will be assigned a case number and you will need to choose a password (PIN). You will need to use this case number and password (PIN) to gain access to the mailbox and to see if you have received any questions..'
  },
  WHAT_IS_THE_REPORTING_PRECEDURE_CONTENT_4: {
    'de': 'Unabhängig davon, ob Sie anonym bleiben oder Ihren Namen angeben, bitten wir Sie, ein Postfach zu erstellen. Dies macht es einfacher und sicherer für uns, mit Ihnen zu kommunizieren.',
    'en': 'Regardless of whether you remain anonymous or give your name, we ask you to create a mailbox. This makes it easier and safer for us to communicate with you.'
  },
  WHAT_IS_THE_REPORTING_PRECEDURE_CONTENT_5: {
    'de': 'Wenn Sie es wünschen, bleibt sämtliche Kommunikation mit uns anonym.',
    'en': 'If you wish, all communication with us will remain anonymous.'
  },
  WHAT_IS_THE_REPORTING_PRECEDURE_ELABORATION: {
    'en': 'When you submit a message, you have the option of creating a secure mailbox so that you are available for further queries. We recommend keeping yourself available this way as we may not be able to close the case without further information from you.When you create a mailbox, you will be assigned a case number and you will need to choose a password (PIN). You will need to use this case number and password (PIN) to gain access to the mailbox and to see if you have received any questions.Regardless of whether you remain anonymous or give your name, we ask you to create a mailbox. This makes it easier and safer for us to communicate with you.If you wish, all communication with us will remain anonymous.',
    'de': 'Wenn Sie eine Meldung übermitteln, haben Sie die Möglichkeit, ein sicheres Postfach zu erstellen und so für weitere Rückfragen zur Verfügung zu stehen. Wir empfehlen, sich auf diese Weise verfügbar zu halten, da wir den Fall möglicherweise nicht ohne weitere Informationen von Ihnen abschließen können. Wenn Sie ein Postfach erstellen, wird Ihnen eine Fallnummer zugeteilt, und Sie müssen ein Passwort (PIN) wählen. Diese Fallnummer und dieses Passwort (PIN) müssen Sie verwenden, um Zugang zum Postfach zu erhalten und um nachzusehen, ob Sie Fragen erhalten haben. Unabhängig davon, ob Sie anonym bleiben oder Ihren Namen angeben, bitten wir Sie, ein Postfach zu erstellen. Dies macht es einfacher und sicherer für uns, mit Ihnen zu kommunizieren. Wenn Sie es wünschen, bleibt sämtliche Kommunikation mit uns anonym.'
  },
  WILL_DATA_REMAIN_ANONYMOUS: {
    'en': 'Will my data remain anonymous?',
    'de': 'Bleiben meine Daten anonym?'
  },
  WILL_DATA_REMAIN_ANONYMOUS_CONTENT_1: {
    'en': 'To ensure your anonymity, you need to do the following:',
    'de': 'Um Ihre Anonymität sicherzustellen, müssen Sie folgendes tun:'
  },
  WILL_DATA_REMAIN_ANONYMOUS_CONTENT_2: {
    'en': `If possible, do not run the report from your employer's computer.`,
    'de': 'Erstellen Sie den Bericht, wenn möglich, nicht von einem Computer Ihres Arbeitgebers aus.'
  },
  WILL_DATA_REMAIN_ANONYMOUS_CONTENT_3: {
    'en': 'Do not use a PC that is connected to the company network / intranet of the company.',
    'de': 'Verwenden Sie keinen PC, der ans Firmennetzwerk/Intranet der Firma angeschlossen ist.'
  },
  WILL_DATA_REMAIN_ANONYMOUS_CONTENT_4: {
    'en': 'Call up the reporting system by entering the URL address directly in the browser and not by clicking a link.',
    'de': 'Rufen Sie das Meldesystem durch direktes Eingeben der URL-Adresse in den Browser und nicht über das Klicken eines Links auf.'
  },
  WILL_DATA_REMAIN_ANONYMOUS_CONTENT_5: {
    'en': 'Do not write personal information on the report',
    'de': 'Schreiben Sie keine persönlichen Daten in den Bericht.'
  },
  POWERED_BY: {
    'en': 'powered by',
    'de': 'powered by'
  },
  LOGIN_TO_VIEW_CASE: {
    'en': 'Login Link',
    'de': 'Login-Link'
  },
  CASE_ID: {
    'en': 'Case No.',
    'de': 'Fallnummer'
  },
  CASE_LOGIN: {
    'en': 'Case Login',
    'de': 'Fall-Login'
  },
  UPLOAD_FILE: {
    'en': 'Upload file and attachment',
    'de': 'Datei und Anhang hochladen'
  },
  CASE_ATTACHMENT: {
    'en': "Case Attachment",
    'de': "Anhang",
  },
  CREATE_ANOTHER_CASE: {
    'en': 'Create Another Case',
    'de': 'Erstellen Sie einen weiteren Fall',
  },
  DOWNLOAD_CASE_SUMMARY: {
    'en': 'Download Case Summary',
    'de': 'Fallinformationen herunterladen'
  },
  PASSWORD_NO_CHAR: {
    'en': 'The password requires at least one letter.',
    'de': 'Das Passwort benötigt mindestens einen Buchstaben.'
  },
  PASSWORD_NO_NUM: {
    'en': 'The password needs at least one number.',
    'de': 'Das Passwort benötigt mindestens eine Zahl.'
  },
  PASSWORD_NO_SYMBOL: {
    'en': 'The password requires at least one special character.',
    'de': 'Das Passwort benötigt mindestens ein Sonderzeichen..'
  }
}

const CASE_LOGIN_URL = "https://whistleblowing-compliant.eu/#/case/login"
const CASE_LOGIN_URL_FOR_STAGING = "https://staging.whistleblowing-compliant.eu/#/case/login"
let tempUniqueToken = 'sSA4sm3wsjkdRRG'

const BKPForm = () => {
  let selectedLanguage = document.querySelector( '#bkp-plugin' ).dataset.locale.split('-')[0]
  let style = document.createElement( 'style' )
  let css = `
    .bkp-form {
      font-family: 'Open Sans';
    }
  `
  style.innerHTML = css
  let scriptElement = document.createElement( 'script' )
  scriptElement.setAttribute( 'src', 'https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.0.0/crypto-js.min.js' )

  let caseLoginURL = CASE_LOGIN_URL
  if ( [ 'localhost', 'staging' ].some( substr => { return window.location.hostname.indexOf(substr) >= 0 }) ) {
    caseLoginURL = CASE_LOGIN_URL_FOR_STAGING
    tempUniqueToken = 'f8p7CbQ9thiODlZ'
  }

  let ModalContent = ( id, content, header = '' ) => (
    `<div class="bkp-modal" id="${ id }" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">${ header }</h5>
            <button type="button" class="btn btn-close-modal" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            ${ content }
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary btn-close-modal" data-dismiss="modal">
              <span class="multilang-text" data-text-code="CLOSE"></span>
            </button>
          </div>
        </div>
      </div>
    </div>`
  )

  

  let HTMLString = `
  <div class="container pb-3">
    <div class="row bkp-row">
      <div class="col-md-7">
        <select id="bkp-form-language-selector" class="float-end form-select"> 
          <option value='de'>Deutsch</option>
          <option value='en'>English</option>
        </select>
        <div class="bkp-form">
          <h2>
            <label class="multilang-text mb-3" data-text-code="TITLE"></label>
          </h2>
          <div id="notification"></div>
          <div class="form-group">
            <label class="multilang-text" data-text-code="WHAT_IS_YOUR_SUSPICION"></label>
            <span class="text-danger">*</span>
            <textarea class="form-control" id="bkp-case-desc" rows="5" required></textarea>
          </div>
          <div class="form-group pb-3" mt-2>
            <div class="d-flex justify-content-between align-items-end flex-wrap">
              <h5 class="multilang-text mb-0" data-text-code="CASE_ATTACHMENT"></h5>
              <button class="btn btn-secondary btn-sm mr-2" 
                onclick="document.getElementById('bkp-form-file-upload').click()">
                <span class="multilang-text" data-text-code="UPLOAD_FILE">
              </button>
              <input type='file' 
                id="bkp-form-file-upload" 
                multiple={ true }
                accept="image/jpeg, image/jpg,image/png,application/pdf"
                style="display:none">
            </div>
            <div id="bkp-form-file" class="card p-3 mt-3">
            </div>
          </div>
          <div class="form-group pb-3">
            <label class="multilang-text" data-text-code="ASK_FOR_AFFECTED_DEPARTMENT">:</label>
            <span class="text-danger">*</span>
            <input id="bkp-company-department" class="form-control" type="text" />
          </div>
          <div class="form-group pb-3">
            <label class="multilang-text" data-text-code="ASK_FOR_COUNTRY"></label>
            <span class="text-danger">*</span>

            <input id="bkp-country" class="form-control" type="text" />
          </div>
          <div class="form-group pb-3">
            <label class="multilang-text" data-text-code="ASK_FOR_CITY"></label>
            <span class="text-danger">*</span>
            <input id="bkp-city" class="form-control" type="text" />
          </div>
          <div class="form-group pb-3">
            <input id="bkp-is-anonymous" type="checkbox" style="margin-right: 6px"/><span class="multilang-text" data-text-code="IS_ANONYMOUS"></span> 
          </div>
          <div id="bkp-form-not-anonymous">
            <h5 class="multilang-text mb-3" data-text-code="CONTACT_INFO"></h5>
            <div class="card p-3 mb-4">
              <div class="form-group pb-3">
                <label class="multilang-text" data-text-code="EMAIL"></label>
                <span class="text-danger">*</span>
                <input id="bkp-email" class="form-control" type="email" />
              </div>
              <div class="form-group pb-3">
                <label class="multilang-text" data-text-code="NAME"></label>
                <span class="text-danger">*</span>
                <input id="bkp-name" class="form-control" type="text" />
              </div>
              <div class="form-group pb-3">
                <label class="multilang-text" data-text-code="CONTACT"></label>
                <span class="text-danger">*</span>
                <input id="bkp-contact" class="form-control" type="text" />
              </div>
            </div>
          </div>
          <div class="">
            <h5 class="multilang-text mb-3" data-text-code="PASSWORD"></h5>
            <div class="card p-3 mb-4">

              <div class="form-group pb-3">
                <label class="multilang-text" data-text-code="PASSWORD"></label>
                <span class="text-danger">*</span>
                <input id="bkp-password" class="form-control" type="password" />
              </div>
              <div class="form-group pb-3">
                <label class="multilang-text" data-text-code="REPEAT_PASSWORD"></label>
                <span class="text-danger">*</span>
                <input id="bkp-password-repeat" class="form-control" type="password" />
              </div>
            </div>
          </div>
          <div id="bkp-form-submit-button-cont">
            <button id="bkp-form-submit-button" class="btn bkp-btn-primary mt-3">
              <span class="multilang-text" data-text-code="SUBMIT"></span>
            </button>
          </div>
        </div>
        <div class="card card-body" id="bkp-form-submit-status">
          <p class="multilang-text" data-text-code="SUBMITTED_SUCCESSFULLY"></p>
          <strong class="multilang-text mt-3" data-text-code="CASE_ID"></strong>: <span id="case-id"></span>
          <br/>
          <strong class="multilang-text" data-text-code="LOGIN_TO_VIEW_CASE"></strong>: <a href=${ caseLoginURL } target="_blank">${ caseLoginURL }</a>
          <button 
            class="btn bkp-btn-primary ml-auto multilang-text d-block mt-3" 
            data-text-code="DOWNLOAD_CASE_SUMMARY" 
            id="download_case_button"
          />
        </div>
      </div>
      <div class="col-md-5 bkp-form">
        <div class="card card-body info-card">
          <button type="button" class="btn bkp-btn-primary mb-5" onclick="window.open('${ caseLoginURL }')">
           <span class="multilang-text" data-text-code="CASE_LOGIN"></span>
          </button>
          <h3 class="multilang-text" data-text-code="SAFE_AND_ANONYMOUS"></h3>
          <br/>
          <h3>
            <img src="https://whistleblower-center.proof-point.com/assets/secure-icon.png" width="50">
            <span class="multilang-text" data-text-code="HUNDRED_PERCENT_ANONYMOUS_REPORTNG"></span>
          </h3>
          <br/>
          <h3>
            <img src="https://whistleblower-center.proof-point.com/assets/secure-icon.png" width="50">
            <span class="multilang-text" data-text-code="CERTIFIED_DATA_SECURITY"></span>
          </h3>
          <br/>
          <h3>
            <img src="https://whistleblower-center.proof-point.com/assets/secure-icon.png" width="50">
            <span class="multilang-text" data-text-code="INDEPENDENT_OMBUDSMAN"></span>
          </h3>
          <br/>
          <strong class="mb-2"><u role="button" id="bkp-form-reporting-procedure"><span class="multilang-text" data-text-code="WHAT_IS_THE_REPORTING_PRECEDURE"></span></u></strong>
          <strong><u role="button"><span class="multilang-text" id="bkp-form-data_anonymous" data-text-code="WILL_DATA_REMAIN_ANONYMOUS"></span></u></strong>
          <br/>
          <div class="d-flex flex-row align-items-end justify-content-end">
            <span style="margin-right: 10px;"><span class="multilang-text" data-text-code="POWERED_BY"></span>: </span>
            <img src="https://whistleblower-center.proof-point.com/assets/BKP-Logo.png" width="100" style="opacity: 80%;">
          </div>
          <br/>
        </div>
      </div>
    </div>
    ${
      ModalContent(
        'bkp-form-check-failed-modal',
        `<span class="multilang-text" data-text-code="MISSING_INFORMATION"></span>
        <span class="multilang-text mt-2" id="password_unmatch" data-text-code="UNMATCH_PASSWORD"></span>
        <span class="multilang-text mt-2" id="password_too_short" data-text-code="PASSWORD_TOO_SHORT"></span>
        <span class="multilang-text mt-2" id="password_no_char" data-text-code="PASSWORD_NO_CHAR"></span>
        <span class="multilang-text mt-2" id="password_no_num" data-text-code="PASSWORD_NO_NUM"></span>
        <span class="multilang-text mt-2" id="password_no_symbol" data-text-code="PASSWORD_NO_SYMBOL"></span>
        `,
        `<p class="multilang-text" data-text-code="ALERT"/>`
      )
    }
    ${ 
      ModalContent(
        'bkp-form-report_procedure_modal',
        `<ul>
          <li class="multilang-text" data-text-code="WHAT_IS_THE_REPORTING_PRECEDURE_CONTENT_1"></li>
          <li class="multilang-text" data-text-code="WHAT_IS_THE_REPORTING_PRECEDURE_CONTENT_2"></li>
          <li class="multilang-text" data-text-code="WHAT_IS_THE_REPORTING_PRECEDURE_CONTENT_3"></li>
          <li class="multilang-text" data-text-code="WHAT_IS_THE_REPORTING_PRECEDURE_CONTENT_4"></li>
          <li class="multilang-text" data-text-code="WHAT_IS_THE_REPORTING_PRECEDURE_CONTENT_5"></li>
        </ul>`,
        `<p class="multilang-text" data-text-code="WHAT_IS_THE_REPORTING_PRECEDURE"/>`
      )
    }
    ${ 
      ModalContent(
        'bkp-form-anonymous_modal',
        `<p class="multilang-text" data-text-code="WILL_DATA_REMAIN_ANONYMOUS_CONTENT_1"/>
        <ul>
          <li class="multilang-text" data-text-code="WILL_DATA_REMAIN_ANONYMOUS_CONTENT_2"></li>
          <li class="multilang-text" data-text-code="WILL_DATA_REMAIN_ANONYMOUS_CONTENT_3"></li>
          <li class="multilang-text" data-text-code="WILL_DATA_REMAIN_ANONYMOUS_CONTENT_4"></li>
          <li class="multilang-text" data-text-code="WILL_DATA_REMAIN_ANONYMOUS_CONTENT_5"></li>
        </ul>`,
        `<p class="multilang-text" data-text-code="WILL_DATA_REMAIN_ANONYMOUS"/>`
      )
    }
    
    <div id="bg-loader-bkp">
      <div class="loader"></div>
    </div>
    <style>
    #bkp-form-language-selector {
      width: fit-content;
    }
    #bg-loader-bkp {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;
      align-items: center;
      justify-content: center;
    }
    #bkp-form-submit-status {
      display: none;
    }
    #bkp-form-report_procedure_modal {
      display: none;
    }
    #bkp-form-anonymous_modal {
      display: none;
    }
    .loader {
      border: 16px solid #f3f3f3; /* Light grey */
      border-top: 16px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 120px;
      height: 120px;
      animation: spin 2s linear infinite;
    }
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    .bkp-form {
      margin-bottom: 20px;
    }
    .bkp-form .info-card {
      background-color: #edf1f4; 
      padding: 20px;
    }
    .bkp-form h3 {
      font-weight: 800;
    }
    .bkp-form a {
      text-decoration: none;
      color: #000;
    }
    .bkp-btn-primary {
      color: white !important;
      background: #6390cf !important;
    }
    .bkp-row{
      padding-top: 30px;
    }
    .bkp-form a:hover {
      color: #0000ff;
    }
    .bkp-form .form-group {
      margin-bottom: 1rem;
    }
    #bkp-form-file{
      display: none;
    }
    .bkp-form-file-cont {
      position: relative;
      width: min-content;
    }
    .bkp-form-file-cont .bkp-remove-btn {
      position: absolute;
      right: -12px;
      font-size: 10px;
      top: 5px;
      padding: 0.2rem 0.4rem;
      margin-top: -10px;
    }
    /* modal */
    .bkp-modal {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999999;
      display: none;
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      outline: 0;
    }
    .bkp-modal .modal-dialog {
      margin: 10% auto;
    }
    #bkp-form-submit-button-cont {
      display: flex !important;
    }
    #bkp-form-submit-button {
      margin-left: auto !important;
    }
    </style>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
  </div>
  `
  document.querySelector( '#bkp-plugin' ).innerHTML = HTMLString
  document.querySelector( '#bkp-plugin' ).appendChild( style )
  document.head.appendChild( scriptElement )
  
  let reportingClose = document.querySelectorAll( '#bkp-form-report_procedure_modal .btn-close-modal' )
  for ( let i = 0 ; i < reportingClose.length; i++ ) {
    reportingClose[i].addEventListener('click', (e) => {
      document.querySelector( '#bkp-form-report_procedure_modal' ).style.display = 'none'
    })
  }

  document.querySelector( '#bkp-form-reporting-procedure' ).addEventListener( 'click', function( e ) {
    document.querySelector( '#bkp-form-report_procedure_modal' ).style.display = 'block'
  })

  //file upload hanlding
  let fileArray = []
  var fileInput = document.getElementById("bkp-form-file-upload")
  let tempBadgeSection = document.getElementById('bkp-form-file')

  fileInput.addEventListener('change', event => {
    //remove all children except the span
    
    if ( event.target.files.length > 0 ){
      tempBadgeSection.style.display = 'block'

      for (var i = 0; i < event.target.files.length; i++) {
        let reader = new FileReader()
        reader.onload = e => {
          fileArray.push({ 
            file: e.target.result,
            file_name: event.target.files[ 0 ]?.name??`file_${ i }`
          })
        } 
        reader.readAsDataURL( event.target.files[ i ] )
  
        let tempBadgeDiv = document.createElement( 'div' )
        tempBadgeDiv.classList.add( 'bkp-form-file-cont' )
        
        let tempBadge = document.createElement( 'span' )
        tempBadge.classList.add('badge', 'badge-primary', 'mr-1', 'p-2', 'mb-2' )
        tempBadge.textContent = event.target.files[ i ]?.name??`file_${ i }`
        
        let tempBadgeRemove = document.createElement( 'button' )
        tempBadgeRemove.textContent = 'x'
        tempBadgeRemove.id = `bkp-remove-btn-${ i }`
        tempBadgeRemove.classList.add( 'btn', 'btn-danger', 'btn-sm', 'bkp-remove-btn' )
        tempBadgeRemove.addEventListener( 'click', function (e) {
          for (let index = 0; index < tempBadgeSection.children.length; index++) {
            if ( tempBadgeSection.children[index] == tempBadgeRemove.parentElement ){
              tempBadgeSection.removeChild( tempBadgeRemove.parentElement )
              fileArray.splice( index, 1 )
            }
          }
  
          if ( tempBadgeSection.children.length === 0 ){
            tempBadgeSection.style.display = 'none'
          }
        })
  
        tempBadgeDiv.append( tempBadge, tempBadgeRemove )
        tempBadgeSection.appendChild( tempBadgeDiv )
      }
    }
  })
  

  let anonymousClose = document.querySelectorAll( '#bkp-form-anonymous_modal .btn-close-modal' )
  for ( let i = 0 ; i < anonymousClose.length; i++ ) {
    anonymousClose[i].addEventListener('click', (e) => {
      document.querySelector( '#bkp-form-anonymous_modal' ).style.display = 'none'
    })
  }

  document.querySelector( '#bkp-form-data_anonymous' ).addEventListener( 'click', function( e ) {
    document.querySelector( '#bkp-form-anonymous_modal' ).style.display = 'block'
  })


  function deleteChild( nodeElement ) {
    for (var i = 0; i < nodeElement.children.length; i++) {
      if( i !== 0 ){
        nodeElement.removeChild(nodeElement.children[i]);
      } 
    }
  }

  function addTextInSelectedLang( selectedLanguage ) {
    let multilangTexts = document.querySelectorAll( '#bkp-plugin .multilang-text' )
    for ( let i = 0 ; i < multilangTexts.length; i++ ) {
      multilangTexts[i].innerHTML = Lang[ multilangTexts[i].dataset.textCode ][ selectedLanguage ]
    }
  }
  addTextInSelectedLang( selectedLanguage )
  document.getElementById('bkp-form-language-selector').value = selectedLanguage 
  
  document.querySelector( '#bkp-is-anonymous' ).addEventListener( 'click', function( e ) {
    if( e.target.checked ) {
      document.querySelector( '#bkp-form-not-anonymous' ).style.display = 'none'
    } else {
      document.querySelector( '#bkp-form-not-anonymous' ).style.display = 'block'
    } 
  })

  // document.querySelector( '#bkp-form-recreate-button' ).addEventListener( 'click', function( e ) {
  //   var elements = document.querySelectorAll("input, textarea");
  //   fileArray = []
  //   for (var index=0; index < elements.length; index++) {
  //     elements[index].value = "";
  //   }
  //   let tempTag = document.createElement( 'p' )
  //   var textNode = document.createTextNode( Lang[ 'MISSING_FILE' ][ document.getElementById('bkp-form-language-selector').value ] ); 

  //   tempTag.setAttribute( "id", "bkp-missing-file" );
  //   tempTag.setAttribute( "class", "multilang-text" );
  //   tempTag.setAttribute( "data-text-code", "MISSING_FILE" );
    
  //   tempTag.appendChild( textNode )

  //   tempBadgeSection.replaceChildren( tempTag )
    
  //   document.querySelector( '#bkp-form-not-anonymous' ).style.display = 'block'
  //   document.querySelector( '#bkp-is-anonymous' ).checked = false
  //   document.querySelector( '#bkp-form-submit-status' ).style.display = 'none'
  //   document.querySelector( '.bkp-form' ).style.display = 'block'
  // })

  let tempCaseId = ''

  document.querySelector( '#bkp-form-submit-button' ).addEventListener( 'click', function( e ) {
    let token = document.querySelector( '#bkp-plugin' ).dataset.accessToken
    let description = document.querySelector( '#bkp-case-desc' ).value
    let company_department = document.querySelector( '#bkp-company-department' ).value
    let country = document.querySelector( '#bkp-country' ).value
    let city = document.querySelector( '#bkp-city' ).value
    let is_anonymous = document.querySelector( '#bkp-is-anonymous' ).checked
    let email = document.querySelector( '#bkp-email' ).value
    let name = document.querySelector( '#bkp-name' ).value
    let contact = document.querySelector( '#bkp-contact' ).value
    let password = document.querySelector( '#bkp-password' ).value
    let password_repeat = document.querySelector( '#bkp-password-repeat' ).value
    let send_check = true
    let password_unmatch = false
    let password_too_short = false
    let password_no_num = false
    let password_no_char = false
    let password_no_symbol = false

    if( description === '' ) {
      send_check = false
      document.querySelector( '#bkp-case-desc' ).classList.add( 'border', 'border-danger' )
    } else {
      document.querySelector( '#bkp-case-desc' ).classList.remove( 'border', 'border-danger' )
    }

    if ( password.length < 8 || password_repeat.length < 8 ){
      password_too_short = true
      send_check = false
    } else {
      password_too_short = false
    }

    if( password === '' || password.length < 8 ) {
      send_check = false
      document.querySelector( '#bkp-password' ).classList.add( 'border', 'border-danger' )
    } else {
      document.querySelector( '#bkp-case-desc' ).classList.remove( 'border', 'border-danger' )
    }

    if( password_repeat === '' || password_repeat.length < 8 ) {
      send_check = false
      document.querySelector( '#bkp-password-repeat' ).classList.add( 'border', 'border-danger' )
    } else {
      document.querySelector( '#bkp-case-desc' ).classList.remove( 'border', 'border-danger' )
    }

    if ( password !== password_repeat ){
      send_check = false
      password_unmatch = true
    } else {
      password_unmatch = false
    }

    if ( password.search(/[0-9]/) < 0 || password_repeat.search(/[0-9]/) < 0 ){
      send_check = false
      password_no_num = true
    } else {
      password_no_num = false
    }

    if ( password.search(/[a-zA-Z]/i) < 0 || password_repeat.search(/[a-zA-Z]/i) < 0 ){
      send_check = false
      password_no_char = true
    } else {
      password_no_char = false
    }

    if ( password.search(/[!@#\$%\^&\*_]/) < 0 || password_repeat.search(/[!@#\$%\^&\*_]/) < 0 ){
      send_check = false
      password_no_symbol = true
    } else {
      password_no_symbol = false
    }
    
    if( !is_anonymous ) {
      if( email === '' ) {
        send_check = false
        document.querySelector( '#bkp-email' ).classList.add( 'border', 'border-danger' )
      } else {
        document.querySelector( '#bkp-email' ).classList.remove( 'border', 'border-danger' )
      }
      if( name === '' ) {
        send_check = false
        document.querySelector( '#bkp-name' ).classList.add( 'border', 'border-danger' )
      } else {
        document.querySelector( '#bkp-name' ).classList.remove( 'border', 'border-danger' )
      }
      if( contact === '' ) {
        document.querySelector( '#bkp-contact' ).classList.add( 'border', 'border-danger' )
      } else {
        document.querySelector( '#bkp-contact' ).classList.remove( 'border', 'border-danger' )
      }
    }
    if( send_check ) {
      let data = {
        description: description,
        company_id: 1,
        company_department: company_department,
        country: country,
        city: city,
        is_anonymous: is_anonymous,
        email: email,
        name: name,
        contact: contact,
        password: password,
        password_repeat: password_repeat, 
        unique_token: tempUniqueToken
      }
      let encryptedData = CryptoJS.AES.encrypt( JSON.stringify(data), encryptionKey()).toString()
      let encryptedPath = CryptoJS.AES.encrypt( '/v1/cases', encryptionKey()).toString()
      let encrypedFiles = CryptoJS.AES.encrypt( JSON.stringify( fileArray ), encryptionKey()).toString()

      encryptedPath = encryptedPath.replace(/\//g, "*");

      document.querySelector( '#bg-loader-bkp' ).style.display = 'flex'
      fetch( `${ getDomainURL() }/v1/directors/${ encryptedPath }`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        referrer: 'no-referrer',
        body: JSON.stringify({ 'secret': encryptedData, "files": encrypedFiles })
      }).then( async ( response ) => {
        document.querySelector( '#bg-loader-bkp' ).style.display = 'none'
        const payload = await response.json()
        if ( response.ok ) {
          tempCaseId = payload.uid
          document.querySelector( '#bkp-form-submit-status #case-id' ).innerHTML = payload.uid
          document.querySelector( '#bkp-form-submit-status' ).style.display = 'block'
          document.querySelector( '.bkp-form' ).style.display = 'none'
          document.getElementById('bkp-form-submit-status').scrollIntoView({ behavior: 'smooth', block: 'center' })
        } else {
          alert( payload.message )
        }
      }).catch( err => {
        document.querySelector( '#bg-loader-bkp' ).style.display = 'none'
      })
    } else {
      let modal = document.querySelector( '#bkp-form-check-failed-modal' ) 
      let closeModalButtons = document.querySelectorAll( '#bkp-form-check-failed-modal .btn-close-modal' )
      let passwordTooShort = document.querySelector( '#password_too_short' ) 
      
      let passwordNoChar = document.querySelector( '#password_no_char' ) 
      let passwordNoNum = document.querySelector( '#password_no_num' ) 
      let passwordNoSymbol = document.querySelector( '#password_no_symbol' ) 
      let passwordUnmatch = document.querySelector( '#password_unmatch' ) 

      if ( password_unmatch ){
        passwordUnmatch.style.display = 'block' 
      } else {
        passwordUnmatch.style.display = 'none' 
      }

      if ( password_too_short ){
        passwordTooShort.style.display = 'block' 
      } else {
        passwordTooShort.style.display = 'none' 
      }

      if ( password_no_num ){
        passwordNoNum.style.display = 'block' 
      } else {
        passwordNoNum.style.display = 'none' 
      }
      
      if ( password_no_char ){
        passwordNoChar.style.display = 'block' 
      } else {
        passwordNoChar.style.display = 'none' 
      }

      if ( password_no_symbol ){
        passwordNoSymbol.style.display = 'block' 
      } else {
        passwordNoSymbol.style.display = 'none' 
      }

      modal.style.display = 'block'
      send_check = false

      for ( let i = 0 ; i < closeModalButtons.length; i++ ) {
        closeModalButtons[i].addEventListener('click', (e) => {
          modal.style.display = 'none'
          for ( let i = 0 ; i < closeModalButtons.length; i++ ) {
            closeModalButtons[i].removeEventListener( 'click', ()=>{} )
          }
        })
      } 
    }
  })

  document.querySelector( '#download_case_button').addEventListener( 'click', function(e) {
    let token = document.querySelector( '#bkp-plugin' ).dataset.accessToken
    document.querySelector( '#bg-loader-bkp' ).style.display = 'flex'
    fetch( `${ getDomainURL() }/cases/${ tempCaseId }/download`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }).then( async ( response ) => {
      const resultBlob = await response.blob()
      var a = document.createElement("a");
      document.body.appendChild(a);
      let url = window.URL.createObjectURL(resultBlob); 
      a.style = "display: none";
      a.href = url;
      a.download = selectedLanguage === 'de' ? `Fallinformationen_${ tempCaseId }` : `Case_Information_${ tempCaseId }`
      a.click();
      window.URL.revokeObjectURL(url);
      document.querySelector( '#bg-loader-bkp' ).style.display = 'none'
    }).catch( err => {
      document.querySelector( '#bg-loader-bkp' ).style.display = 'none'
    })
  })

  document.querySelector( '#bkp-form-language-selector' ).addEventListener("change", function(e) {
    selectedLanguage = this.value
    addTextInSelectedLang( selectedLanguage )
    document.getElementById('bkp-form-language-selector').value = selectedLanguage 
  })
}

export default BKPForm