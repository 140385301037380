import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Moment from 'moment'
import { Delete, Post, Put, Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      remarks: [],
      selectedRemark: {},

      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false
    }

    onChangeHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    onChangeRemarkHOC = ( context, val ) => this.setState({ [context]: val })

    getRemarks = caseID => Get( 
      `/cases/${ caseID }/remarks`,
      this.getRemarksSuccess,
      this.getRemarkError,
      this.load
    )      
    getRemarksSuccess = payload => {
      let tempPayload = _.map( payload, item => ({
        ... item,
        updated_at: Moment( item.updated_at ).format( 'DD-MM-yyyy' )
      }))
      this.setState({ remarks: tempPayload })
    }
    getRemarkError = error => this.requestError( error )
    
    createRemark = ( caseID, payload ) => Post(
      `/cases/${ caseID }/remarks`,
      payload,
      this.createRemarkSuccess,
      this.createRemarkError,
      this.load
    )
    createRemarkSuccess = payload => {
      this.requestSuccess( 'Remark created succesfully.' )
      this.getRemarks( payload.case_id )
      this.setState({ showCreateModal: false })
    }
    createRemarkError = error => this.requestError( error )

    updateRemark = ( caseID, remarkID, payload ) => Put(
      `/cases/${ caseID }/remarks/${ remarkID }`,
      payload,
      this.updateRemarkSuccess,
      this.updateRemarkError,
      this.load
    )
    updateRemarkSuccess = payload => {
      this.requestSuccess( 'Remark updated successfully.' )
      this.getRemarks( payload.case_id )

      this.setState( prevState => ({ 
        selectedRemark: {
          ... prevState.selectedRemark,
          updated_at: Moment( payload.updated_at ).format( 'DD-MM-yyyy' )
        }
      }))
    }
    updateRemarkError = error => this.requestError( error )
    
    deleteRemark = ( caseID, remarkID ) => Delete(
      `/cases/${ caseID }/remarks/${ remarkID }`,
      () => this.deleteRemarkSuccess( caseID ),
      this.deleteRemarkError,
      this.load
    ) 
    deleteRemarkSuccess = caseID => {
      this.requestSuccess( 'Remark deleted successfully.') 
      this.getRemarks( caseID )
      this.setState({ showDeleteModal: false })
    }
    deleteRemarkError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          onLoadRemark={ this.state.loading }
          remarks={ this.state.remarks }
          selectedRemark={ this.state.selectedRemark }
          showEditModal={ this.state.showEditModal }
          showDeleteModal={ this.state.showDeleteModal }
          showCreateModal={ this.state.showCreateModal }

          onChangeRemarkHOC={ this.onChangeRemarkHOC }
          getRemarks={ this.getRemarks }
          createRemark={ this.createRemark }
          updateRemark={ this.updateRemark }
          deleteRemark={ this.deleteRemark }
        />
      )
    }
  }
  return WithHOC
}

export default HOC