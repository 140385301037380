import React, { useEffect, useState, useMemo } from 'react'
import { 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup, Label, Input
} from 'reactstrap'
import _ from 'lodash'
import TransComponent from 'components/Trans'

const FormContent = ({
  mode,
  
  onClose,
  selectedRole,
  onClickSubmit
}) => {
  const [ roleName, onChangeRoleName ] = useState( '' )

  useEffect(() => {
    mode === 'Edit' && onChangeRoleName( selectedRole?.name??'' )
  }, [ selectedRole ])

  let disabledSubmit = useMemo(() => {
    return !roleName 
  }, [roleName])

  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        <TransComponent i18nKey={ `role.title.${ mode == 'Create' ? 'create': 'update' }`}/>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
        <FormGroup>
          <Label>
            <TransComponent i18nKey="role.fields.name"/>
            <span className="text-danger">*</span>
          </Label>
          <Input
            type={ 'text' }
            value={ roleName }
            onChange={ e => onChangeRoleName( e.target.value )}
          />
        </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          className="mr-2"
          disabled={ disabledSubmit }
          onClick={() => onClickSubmit({
            name: roleName
          })}>
           <TransComponent i18nKey="general.submit"/>
        </Button>
      </ModalFooter>
    </>
  )
}

export default FormContent