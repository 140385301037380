import { GET_DICTIONARY_ROLE } from 'actions/type'

const initialState = {
  roles: []
}

export default ( state = initialState, action ) => {
  switch ( action.type ) {

    case GET_DICTIONARY_ROLE: return ({
      ... state,
      roles: action.payload
    })

    default: return state
  }
}
