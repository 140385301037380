import React, { Component } from "react";
import { connect } from "react-redux";

import { storeItem } from "utils/tokenStore";
import getDomainURL from "utils/api-non-login";

import Axios from "axios";

const HOC = (WrappedComponent) => {
	class WithHOC extends Component {
		state = {
			loading: false,
			showPassword: false,
			errorMessage: "",
		};

		onChangeHOC = (val, context) => this.setState({ [context]: val });

		onClickLogin = ({ email, password }) => {
			this.setState({ loading: true }, () => {
				Axios.post(`${getDomainURL()}/login`, {
					email,
					password,
					encode_type: "JWT",
				})
					.then((response) => {
						this.setState({ loading: false });
						storeItem("BPK_ACCESS_TOKEN", response.data.token);
						storeItem("BPK_USER_TYPE", "non-case");
						this.props.history.push("/dashboard/case-mngmt");
					})
					.catch((err) => {
						this.setState({
							errorMessage:
								err.response?.data?.message ??
								"This combination of email and password is incorrect.",
							loading: false,
						});
					});
			});
		};

		render = () => {
			return (
				<WrappedComponent
					{...this.props}
					showPassword={this.state.showPassword}
					onLoadLogin={this.state.loading}
					errorMessage={this.state.errorMessage}
					onChangeHOC={this.onChangeHOC}
					onClickLogin={this.onClickLogin}
				/>
			);
		};
	}
	const mapStateToProps = (state) => ({ data: state });
	return connect(mapStateToProps)(WithHOC);
};
export default HOC;
