import React, { Component } from 'react'
import { connect } from 'react-redux'
import Hamburger from 'react-hamburgers'

import {
  setEnableClosedSidebar,
  setEnableMobileMenu,
  setEnableMobileMenuSmall
} from 'reducers/ThemeOptions'
import { getItem } from 'utils/tokenStore'

import MobileMenu from '../Menu/MobileMenu'

class Logo extends Component {
  state = {
    active: false,
    mobile: false,
    activeSecondaryMenuMobile: false
  }

  componentDidMount = () => {
    if( getItem( 'BPK_USER_TYPE' ) === 'case' ) {
      this.props.setEnableClosedSidebar( true )
    } else {
      this.props.setEnableClosedSidebar( false )
    }
  }

  render = () => {
    return (
      <>
        <div className="app-header__logo">
          {/* <div className="logo-src" /> */}
          {/* <img src={ icon } style={{ width: 40 }}></img> */}
          <div className="header__pane ml-auto">
            <div onClick={() => this.props.setEnableClosedSidebar( !this.props.enableClosedSidebar )}>
              {
                getItem( 'BPK_USER_TYPE' ) === 'non-case' && (
                  <Hamburger
                    active={ !this.props.enableClosedSidebar }
                    type="elastic"
                    onClick={() => this.setState({ active: !this.state.active })}
                  />
                )
              }
            </div>
          </div>
        </div>
        <MobileMenu />
      </>
    )
  }
}

const mapStateToProps = state => ({
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
})

export default connect( mapStateToProps, {
  setEnableClosedSidebar,
  setEnableMobileMenu,
  setEnableMobileMenuSmall
})( Logo )