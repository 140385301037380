import { combineReducers } from 'redux'

import ThemeOptions from './ThemeOptions'
import ProfileReducer from './profile'
import AjaxReducer from './ajax'
import DictionaryReducer from './dictionary'

export default combineReducers({
  AjaxReducer,
  ThemeOptions,
  ProfileReducer,
  DictionaryReducer
})