import React from 'react'
import TransComponent from 'components/Trans'

export const Footer = ({}) => {
  return (
    <div className="app-page-title d-flex justify-content-around" 
      style={{ marginTop: 70, marginBottom: 0, gap: 50, padding: '60px 30px' }}
    >
      <div style={{ textAlign: 'left' }}>
        <button 
          onClick={ () => window.open( `https://www.whistleblowing-compliant.eu/datenschutz` )}
          style={{ 
            color: '#495057', opacity: 0.6, 
            fontSize: '0.8rem', fontWeight: 500, 
            background: 'transparent', border: 'none' 
          }}
        >
          <TransComponent i18nKey={ 'general.data-protection' }/>
        </button>
      </div>
      <div style={{ textAlign: 'left' }}>
        <button 
          style={{ 
            color: '#495057', opacity: 0.6, 
            fontSize: '0.8rem', fontWeight: 500, 
            background: 'transparent', border: 'none' 
          }}
          onClick={ () => window.open( `https://www.whistleblowing-compliant.eu/impressum` )}
        >
          <TransComponent i18nKey={ 'general.imprint' }/>
        </button>
      </div>
    </div>
  )
}
export default Footer;