import React, { useState } from 'react'
import { 
  Input, FormGroup, Label,
  ModalBody, ModalFooter, ModalHeader,
  Button
} from 'reactstrap'

import TransComponent from 'components/Trans'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

export default function CreateRemark ({
  data,
  selectedCase,
  onLoadRemark,

  createRemark,
  onClose
}) {
  
  const [ tempRemark, setRemark ] = useState( '' )

  return (
    <>
      <ModalHeader toggle={ () => onClose() }>
        <TransComponent i18nKey={ 'lawyer_case.title.create_remark' }/>
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>
            <TransComponent i18nKey={ 'lawyer_case.fields.remark_desc' }/>
          </Label>
          <Input
            type={ 'textarea'}
            value={ tempRemark } 
            rows={ 10 }
            onChange={ e => setRemark( e.target.value )}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          disabled={ !tempRemark }
          onClick={() => createRemark( 
            selectedCase.id,
            {
              user_id: data.ProfileReducer.profile.id,
              content: tempRemark
            }
          )}>
          <TransComponent i18nKey="general.submit"/>
        </Button>
      </ModalFooter>
      { onLoadRemark && <LoadingOverlay/> }
    </>
  )
}
