import React, { useState } from 'react'
import {
	Card, CardBody,
  Label, FormGroup, Input, Button, Form,
  Collapse
} from 'reactstrap'
import { MdPhone, MdArrowDropDown } from 'react-icons/md'
import { IoIosClose } from 'react-icons/io'
import _ from 'lodash'

import TransComponent from 'components/Trans'

export default function GeneralEditCard({
  selectedEdit,
  laywerPhoneJson,
  
  onChangeField,
  onClickDismiss
}) {

  const [ showContent, setShowContent ] = useState( true )

  return (
    <>
      <Card 
        className={ 'w-100 mt-4 mb-3' } 
        style={{ 
          ... !selectedEdit && {
            position: '-webkit-sticky',
            position: 'sticky', 
            top: 40
          }
        }}>
        <CardBody>
          <div 
            style={{ marginTop: -40 }}
            className={ 'd-flex align-items-center justify-content-around' }
          >
            <div className='d-flex align-items-center shadow rounded bg-white'  
              style={{ 
                width: 'max-content', 
                padding: '0.4rem',
                minWidth: 120, 
              }}
            >
              <div className='card p-2 bg-warning'>
                <MdPhone className='text-white' style={{ width: 20, height: 20 }}/>
              </div>
              <span className='ml-2 mr-1 text-warning' style={{ fontWeight: '600', fontSize: '1rem' }}>
              <TransComponent i18nKey={ `general.phone`}/>
              </span>
            </div>
            <div className="d-flex ml-auto">
              <Button 
                color={ 'warning' }
                style={{ padding: '0.35rem' }}
                onClick={ () => setShowContent( !showContent )}
              >
                <MdArrowDropDown 
                  className='text-white'
                  style={{ width: 20, height: 20, transform: showContent ? 'rotate(180deg)' : 'rotate(0deg)' }}
                />
              </Button>
              <Button 
                  color={ 'danger' } 
                  style={{ padding: '0.35rem' }}
                  className={ 'ml-2' }
                  onClick={ () => onClickDismiss()}
                >
                  <IoIosClose style={{ width: 20, height: 20 }}/>
                </Button>
            </div>
          </div>
          <Collapse isOpen={ showContent }>
            <Form className="mt-4">
              <FormGroup check>
                <Input
                  type={'checkbox'}
                  checked={laywerPhoneJson.use_individual_number}
                  onChange={() => onChangeField({
                    ... laywerPhoneJson,
                    use_individual_number: !laywerPhoneJson.use_individual_number
                  })}
                >
                </Input>
                <Label check>
                  <TransComponent i18nKey={'form.fields.use_individual_phone'}/>
                </Label>
              </FormGroup>
              {
                laywerPhoneJson.use_individual_number && (
                <FormGroup className='mt-2'>
                  <Label>
                    <TransComponent i18nKey={'general.phone'}/>
                  </Label>
                  <Input
                    checked={laywerPhoneJson.individual_number}
                    onChange={e => onChangeField({
                      ... laywerPhoneJson,
                      individual_number: e.target.value
                    })}
                  >
                  </Input>
                </FormGroup>
                )
              }
            </Form>
          </Collapse>
        </CardBody>
      </Card>
    </>
  )
}
