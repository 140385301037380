import React, { Component } from 'react'
import { compose } from 'redux'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { 
  Modal, ModalHeader, ModalBody,
  Row, Col, 
  Card, CardBody, 
  Button, UncontrolledTooltip
} from 'reactstrap'
import _ from 'lodash'
import TransComponent from 'components/Trans'
import PageTitle from 'components/Title/PageTitle'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import FormBuilderForm from './Form/index'
import BKPTable from 'components/Table'
import SearchForm from 'components/searchForm'

import FormsHOC from './actions/index'
import { convertObjToBase64 } from 'utils/convertbase64'

class Forms extends Component {

  componentDidMount = () => {
    const { role_id } = this.props.data.ProfileReducer.profile

    if ( role_id === 4 ) {
      return this.props.history.push( '/dashboard/case-mngmt' )
    }
    
    let NonCompanyUser = [ 1, 2 ].indexOf( this.props.data.ProfileReducer.profile.role_id ) > -1
    if ( NonCompanyUser ){
      this.props.getCompanies()
      this.props.getForms(convertObjToBase64({"page": 0}))
    } else {
      this.props.getCompanyForm( this.props.data.ProfileReducer.profile.company_id )
    }
  }

  componentDidUpdate = pp => {
    if(pp.companies !== this.props.companies){
      this.props.onChangeFormHOC('searchParams', [
        {
          ... this.props.searchParams[0],
          options: this.props.companies
        }
      ])
    }
  }

  renderNonCompanyUserContent = () => {
    return (
      <>
        <PageTitle
          heading={ <TransComponent i18nKey="form.title.main"/> }
          subheading={ <TransComponent i18nKey="form.desc.main"/> }
          icon="pe-7s-culture icon-gradient bg-happy-itmeo"
          buttons={[]}
        />
        <ReactCSSTransitionGroup
          component="div"
          transitionName="TabsAnimation"
          transitionAppear={true}
          transitionAppearTimeout={0}
          transitionEnter={false}
          transitionLeave={false}>
          <Row>
            <Col md={12}>
              {
                [ 1, 2 ].indexOf( this.props.data.ProfileReducer.profile.role_id ) > -1 && (
                  <SearchForm
                    per_page={10}
                    searchParams={this.props.searchParams}
                    onChangeHOCState={this.props.onChangeFormHOC}
                    getListAPI={val => this.props.getForms(val)}
                  />
                )
              }
              <Card className="main-card mb-3">
                <CardBody>
                  <BKPTable
                    pageSize={ 10 }
                    data={ this.props.forms }
                    columns={[
                      {
                        Header: <TransComponent i18nKey="form.fields.company"/>,
                        accessor: 'company_name'
                      },
                      {
                        Header: <TransComponent i18nKey="general.action"/>,
                        accessor: 'id',
                        Cell: (row) => {
                          return (
                            <>
                              <Button
                                id={ `ViewForm_${ row.original.id }`}
                                className="mr-2 btn-icon btn-icon-only"
                                color="primary"
                                onClick={() => {
                                  this.props.getSelectedForm( row.original.id )
                                  this.props.getSelectedCompany(row.original.company_id)
                                }}
                              >
                                <i className={ `btn-icon-wrapper pe-7s-info` }> </i>
                              </Button>
                              <UncontrolledTooltip target={ `ViewForm_${ row.original.id }`} placement="top">
                                <TransComponent i18nKey="form.tooltip.view"/>
                              </UncontrolledTooltip>
                            </>
                          )
                        }
                      }
                    ]
                  }
                  showPagination={ true } />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ReactCSSTransitionGroup>
        <Modal
          isOpen={ this.props.showUpdateModal }
          size={ 'xl' }
          style={{ maxWidth: '100%', margin: 0 }}
          >
          <ModalHeader toggle={ () => this.props.onChangeFormHOC( 'showUpdateModal', false )}>
            <TransComponent i18nKey={ 'form.title.update' }/>
          </ModalHeader>
          <ModalBody style={{ position: 'relative' }}>
            <FormBuilderForm
              onLoadForms={ this.props.onLoadForms }
              selectedForm={ this.props.selectedForm }
              selectedCompany={this.props.selectedCompany}
              onClickSubmit={ this.props.updateForm }
            />
          </ModalBody>
          { this.props.onLoadForms && <LoadingOverlay />}
        </Modal>
      </>
    )
  }

  render = () => {
    const { role_id } = this.props.data.ProfileReducer.profile

    return (
      <>
        { role_id !== 4 && this.renderNonCompanyUserContent() }
        {
          role_id === 4 && 
          !_.isEmpty( this.props.selectedForm ) && (
            <>
              <Card>
                <CardBody>
                  <FormBuilderForm
                    onLoadForms={ this.props.onLoadForms }
                    selectedForm={ this.props.selectedForm }
                    onClickSubmit={ this.props.updateForm }
                  />
                </CardBody>
              </Card>
            </>
          )
        }
        { this.props.onLoadForms && <LoadingOverlay />}
      </>
    )
  }
}

export default compose(
  FormsHOC
)(Forms)