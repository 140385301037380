import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import GeneralTransEN from 'assets/translation18/general/en.json'
import GeneralTransDE from 'assets/translation18/general/de.json'
import GeneralTransPL from 'assets/translation18/general/pl.json'
import GeneralTransCZ from 'assets/translation18/general/cz.json'
import GeneralTransFR from 'assets/translation18/general/fr.json'

import RoleTransEN from 'assets/translation18/role/en.json'
import RoleTransDE from 'assets/translation18/role/de.json'

import UserTransEN from 'assets/translation18/user/en.json'
import UserTransDE from 'assets/translation18/user/de.json'

import MenuTransEN from 'assets/translation18/menu/en.json'
import MenuTransDE from 'assets/translation18/menu/de.json'

import LoginTransEN from 'assets/translation18/login/en.json'
import LoginTransDE from 'assets/translation18/login/de.json'
import LoginTransPL from 'assets/translation18/login/pl.json'
import LoginTransCZ from 'assets/translation18/login/cz.json'
import LoginTransFR from 'assets/translation18/login/fr.json'

import CompanyTransEN from 'assets/translation18/company/en.json'
import CompanyTransDE from 'assets/translation18/company/de.json'

import LandingTransEN from 'assets/translation18/landing/en.json'
import LandingTransDE from 'assets/translation18/landing/de.json'

import ResetEN from 'assets/translation18/reset/en.json'
import ResetDE from 'assets/translation18/reset/de.json'

import CaseTypeTransEN from 'assets/translation18/case_type/en.json'
import CaseTypeTransDE from 'assets/translation18/case_type/de.json'

import CaseTransEN from 'assets/translation18/cases/en.json'
import CaseTransDE from 'assets/translation18/cases/de.json'

import CategoryTransEN from 'assets/translation18/company-category/en.json'
import CategoryTransDE from 'assets/translation18/company-category/de.json'

import LawyerCaseEN from 'assets/translation18/lawyer-case/en.json'
import LawyerCaseDE from 'assets/translation18/lawyer-case/de.json'
import LawyerCasePL from 'assets/translation18/lawyer-case/pl.json'
import LawyerCaseCZ from 'assets/translation18/lawyer-case/cz.json'
import LawyerCaseFR from 'assets/translation18/lawyer-case/fr.json'

import CookieCategoryEN from 'assets/translation18/cookie-category/en.json'
import CookieCategoryDE from 'assets/translation18/cookie-category/de.json'

import CookieContentEN from 'assets/translation18/cookie-content/en.json'
import CookieContentDE from 'assets/translation18/cookie-content/de.json'

import TranslationEN from 'assets/translation18/language-translation/en.json'
import TranslationDE from 'assets/translation18/language-translation/de.json'

import FormBuilderEN from 'assets/translation18/form-builder/en.json'
import FormBuilderDE from 'assets/translation18/form-builder/de.json'

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'de',
    resources: {
      en: {
        translation: {
          general: GeneralTransEN,
          role: RoleTransEN,
          user: UserTransEN,
          menu: MenuTransEN,
          login: LoginTransEN,
          company: CompanyTransEN,
          landing: LandingTransEN,
          reset: ResetEN,
          case_type:  CaseTypeTransEN,
          case: CaseTransEN,
          company_category: CategoryTransEN,
          lawyer_case: LawyerCaseEN,
          cookie_category: CookieCategoryEN,
          cookie_content: CookieContentEN,
          translation: TranslationEN,
          form: FormBuilderEN
        }
      },
      de: {
        translation: {
          general: GeneralTransDE,
          role: RoleTransDE,
          user: UserTransDE,
          menu: MenuTransDE,
          login: LoginTransDE,
          company: CompanyTransDE,
          landing: LandingTransDE,
          reset: ResetDE,
          case_type: CaseTypeTransDE,
          case: CaseTransDE,
          company_category: CategoryTransDE,
          lawyer_case: LawyerCaseDE,
          cookie_category: CookieCategoryDE,
          cookie_content: CookieContentDE,
          translation: TranslationDE,
          form: FormBuilderDE
        }
      },
      fr: {
        translation: {
          general: GeneralTransFR,
          login: LoginTransFR,
          lawyer_case: LawyerCaseFR
        }
      },
      cz: {
        translation: {
          general: GeneralTransCZ,
          login: LoginTransCZ,
          lawyer_case: LawyerCaseCZ
        }
      },
      pl: {
        translation: {
          general: GeneralTransPL,
          login: LoginTransPL,
          lawyer_case: LawyerCasePL
        }
      },
    }
  });

export default i18n;