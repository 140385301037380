import React, { Component } from 'react'
import { toast } from 'react-toastify'
import { Get, Post, Delete } from 'utils/axios'
import _ from 'lodash'
import Moment from 'moment'

import TransComponent from 'components/Trans'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showCreateAttachment: false,
      showDeleteAttachment: false,
      caseAttachements: [],
      selectedAttachement: null
    }

    onChangeAttachmentHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ loading: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getCaseAttachement = id => Get(
      `/cases/${ id }/attachments`,
      this.getCaseAttachementSuccess,
      this.getCaseAttachementError,
      this.load
    )
    getCaseAttachementSuccess = payload => {
      let temp = _.map( payload, item => {
        
        let tempURL = item.url.split( '.' )
        return ({
          ... item,
          messageType: 'file',
          sender: item.sender 
            ? <span>
                <span>{ item.sender }</span>
                { this.props.selectedCase.name === item.sender ? <span>(<TransComponent i18nKey={ 'general.whistleblower' }/>)</span> : '' }
              </span>
            : <span><TransComponent i18nKey={ 'general.anonymous' }/> (<TransComponent i18nKey={ 'general.whistleblower' }/>)</span>,
          name: item.file_name,
          created_at: Moment( item.created_at ).format( 'DD-MM-YY HH:mm' ),
          createAtUnix: Moment( item.created_at ).unix(),
          type: this.fileTypeChecker( tempURL[ tempURL.length - 1 ] )
        })
      })

      this.setState({ caseAttachements: temp })
    }
    getCaseAttachementError = err => this.requestError( err )

    fileTypeChecker = substr => {
      if ( substr === 'pdf' ){
        return 'PDF Document'
      }

      if ( [ 'png', 'jpg', 'jpeg' ].indexOf( substr ) > -1 ){
        return `Image ${ substr.toUpperCase() }`
      }

      return 'Others'
    }

    deleteCaseAttachment = id => Delete(
      `/cases_attachments/${ id }`,
      this.deleteCaseAttachmentSuccess,
      this.deleteCaseAttachmentError,
      this.load
    )
    deleteCaseAttachmentSuccess = () => {
      this.requestSuccess( 'Case Attachment has been deleted successfully.' )
      this.setState({ showDeleteAttachment: false })
      this.getCaseAttachement( this.props.selectedCase.id )
    }
    deleteCaseAttachmentError = error => this.requestError( error )

    createCaseAttachment = ( caseId, dataToSubmit ) => Post(
      `/cases/${ caseId }/attachments`,
      dataToSubmit,
      this.createCaseAttachmentSuccess,
      this.createCaseAttachmentError,
      this.load
    )
    createCaseAttachmentSuccess = () => {
      this.setState({ showCreateAttachment: false })
      this.requestSuccess( 'New Case Attachment has been created successfully.' )
      this.getCaseAttachement( this.props.selectedCase.id )
    }
    createCaseAttachmentError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          showDeleteAttachment={ this.state.showDeleteAttachment }
          showCreateAttachment={ this.state.showCreateAttachment }
          selectedAttachement={ this.state.selectedAttachement }
          caseAttachements={ this.state.caseAttachements }
          onLoadAttachment={ this.state.loading }
          
          onChangeAttachmentHOC={ this.onChangeAttachmentHOC }
          getCaseAttachement={ this.getCaseAttachement }
          deleteCaseAttachment={ this.deleteCaseAttachment }
          createCaseAttachment={ this.createCaseAttachment }
        />
      )
    }
  }
  return WithHOC
}

export default HOC