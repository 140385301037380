import React, { Component } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,       
    }

    load = param => this.setState({ loading: param })
    requestError = error => this.setState({ errorMessage: error })
    requestSuccess = success => toast.success( success )    

    submitForgotPassword = ( data ) => {}

    render = () => {
      return (
        <WrappedComponent
          { ...this.props }
          onLoadForgotPassword={ this.state.loading }                 
          submitForgotPassword={ this.submitForgotPassword } />
      )
    }
  }
  return WithHOC
}

export default HOC