import React from 'react'
import { 
  Card, CardBody
} from 'reactstrap'
import ReactHTMLParser from 'react-html-parser'

import CustomButton from './CustomButton'
import RenderedButton from './RenderedButton'

export default function ReportingProcedure({
  formData,
  currentLang,
  selectedEdit,

  onHandleChangeSelectedEdit
}) {

  const { case_success_content } = formData
  return (
    <Card style={{ background: case_success_content.backgroundStyle[ 'background' ]}}>
      <CardBody>
        {
          case_success_content.content.map(( contentItem, contentIndex ) => (
            <>
              <div 
                className={ `d-flex align-items-center flex-wrap transition_200 ${ selectedEdit === null ? 'bkp-form-renderer-title-hover' : '' }` }
                style={{
                  ... selectedEdit && 
                  selectedEdit.fieldIndex === contentIndex && {
                    boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
                    border: '1px solid #467bc6',
                    borderStyle: 'dotted'
                  }
                }}
              
              >
                { ReactHTMLParser( contentItem.label[ currentLang ] ) }
                {
                  contentIndex !== 0 && (
                    <strong>{ ` : xxxxx` }</strong>
                  )
                }
                <CustomButton
                  type={ 'edit' }
                  onClick={() => {
                    onHandleChangeSelectedEdit({
                      cardIndex: null,
                      contentContext: 'content',
                      fieldIndex: contentIndex
                    })
                  }}
                />
              </div>
            </>
          ))
        }
        <RenderedButton
          currentLang={ currentLang }
          selectedEdit={ selectedEdit }
					buttonProps={ case_success_content.download_case_summary_button }
          buttonStyle={{ marginTop: 10 }}
          buttonKey={ 'download_case_summary_button' }
          onClickEditButton={ () => onHandleChangeSelectedEdit( 'download_case_summary_button' )}
				/>
      </CardBody>
    </Card>
  )
}
