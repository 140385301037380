import React, { createRef } from 'react'
import {
	Card, CardBody
} from 'reactstrap'
import {
  DragDropContext,
  Draggable, Droppable,
} from 'react-beautiful-dnd'
import _ from 'lodash'
import ReactHTMLParser from 'react-html-parser'

import CustomButton from './CustomButton'

import RenderedField from './RenderedField'
import RenderedCard from './RenderedCard'
import RenderedButton from './RenderedButton'
import reorder from 'utils/reorder'
import '../../index.scss'

const FormRenderer = ({
	formData,
	currentLang,
	groupMode,
	selectedToGroup,
	selectedEdit,
	duplicatedKeyError,

	setSelectedDelete,
	setDeletePath,
	onHandleChangeSelectedEdit,
	onChangeFormData,
	onChangeFieldForm,
  onHandleCheckGroup
}) => {
	const cardBottomRef = createRef( null )

	const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: '0px 10px',
    background: isDragging ? "lightgreen" : "unset",
    ...draggableStyle
  });

	const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : 'inherit'
  });

	const onDragEndField = result => {
    if (result.type === "droppableItem") {
			// handle field
			onHandleDroppableItem( result )
    } else if (result.type === "droppableSubItem") {
			// handle card child field
			onHandleDroppableSubItem( result )
    }
  }

	// handlle drop action of stand alone fields
  const onHandleDroppableItem = ({ 
		destination,
		source, 
		combine
	}) => {
    if( !destination && !combine ) {
      return;
    }

		let tmp = _.cloneDeep( formData.form_content.content )

    if ( combine ) {
			let tempCardIndex = _.findIndex( tmp, { key: combine.draggableId })

			if ( tmp[ source.index ][ 'type' ] === 'field' && 
			tmp[ tempCardIndex ][ 'content' ] ){
				let tempClone = _.cloneDeep( tmp[ source.index ])

				tmp[ tempCardIndex ][ 'content' ].push( tempClone )
				_.remove( tmp, ( _, itemIndex ) => itemIndex === source.index )
			}
		} else {
			tmp = reorder( tmp, source.index, destination.index )
		}

		onChangeFormData({
			... formData,
			form_content: {
				... formData.form_content,
				content: tmp
			}
		})
  }

	// handle drop action of fields in the card 
	const onHandleDroppableSubItem = ({
		destination,
		source,
	}) => {
		let tempContent = _.cloneDeep( formData.form_content.content )
		const { index: SourceFieldIndex } = source
		
		if ( !destination ) {
			let tempField = _.cloneDeep( tempContent[ source.droppableId ][ 'content' ][ SourceFieldIndex ] )
			_.remove( 
				tempContent[ source.droppableId ][ 'content' ], 
				( _, itemIndex ) => itemIndex === SourceFieldIndex )

			tempContent.push( tempField )
      cardBottomRef.current.scrollIntoView({ behavior: "smooth", block: "center" })
		} else if ( source.droppableId === destination.droppableId ){
			tempContent[ destination.droppableId ][ 'content' ] = reorder( 
				tempContent[ destination.droppableId ][ 'content' ],
				SourceFieldIndex,
				destination.index	
			)
		}
		else if ( source.droppableId !== destination.droppableId ){
			tempContent[ destination.droppableId ][ 'content' ].splice( destination.index, 0, tempContent[ source.droppableId ][ 'content' ][ SourceFieldIndex ])
			_.remove( 
				tempContent[ source.droppableId ][ 'content' ], 
				( _, itemIndex ) => itemIndex === SourceFieldIndex )
		}

		onChangeFormData({
			... formData,
			form_content: {
				... formData.form_content,
				content: tempContent
			}
		})
	}

  return (
		<>
			<Card style={{ background: formData.form_content.backgroundStyle[ 'background' ]}}>
				<CardBody>
					<div 
						className={ `d-flex align-items-center transition_200 ${ !groupMode ? 'bkp-form-renderer-title-hover' : '' }` }
						style={{
							... selectedEdit === 'title' && {
								boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
								border: '1px solid #467bc6',
								borderStyle: 'dotted'
							}
						}}
					>
						<div 
							className={ `mb-2` }
							style={{ fontWeight: 600 }}
						>
							{
								ReactHTMLParser( formData.form_content.title.label[ currentLang ] )
							}
						</div>
						{
							!groupMode && (
								<CustomButton
									type={ 'edit' }
									onClick={() => onHandleChangeSelectedEdit( 'title' )}
								/>
							)
						}
					</div> 
					<DragDropContext 
						onDragEnd={ onDragEndField }
						onDragStart={ () => onHandleChangeSelectedEdit( null )}
					>
						<Droppable 
							type={`droppableItem`}
							droppableId="header-menu-droppable" 
							isCombineEnabled={ true }
						>
							{( provided, snapshot ) => (
								<div
									ref={provided.innerRef}
									className={ 'rounded' }
									style={{ ... getListStyle( snapshot.isDraggingOver ), paddingTop: 30, paddingBottom: 10 }}
									{...provided.droppableProps}
								>
								{
									formData.form_content.content.map(( json, jsonIndex ) => (
										<Draggable
											key={ json.key }
											isDragDisabled={ groupMode || selectedEdit !== null }
											draggableId={ json.key }
											index={ jsonIndex }>
											{( provided, snapshot ) => (
												<div
													ref={ provided.innerRef }
													{ ...provided.dragHandleProps }
													{ ...provided.draggableProps }
													className={ 'rounded' }
													style={ getItemStyle(
														snapshot.isDragging,
														provided.draggableProps.style
													)}
												>
													{
														json.type === 'card' && (
															<RenderedCard
																editIndex={{
																	cardIndex: null,
																	contentContext: 'content',
																	fieldIndex: jsonIndex
																}}
																formData={ formData }
																json={ json } jsonIndex={ jsonIndex }
																duplicatedKeyError={ duplicatedKeyError }
																currentLang={ currentLang }
																onHandleChangeSelectedEdit={ onHandleChangeSelectedEdit }
																setSelectedDelete={ setSelectedDelete }
																setDeletePath={ setDeletePath }
																selectedEdit={  selectedEdit }
																selectedToGroup={ selectedToGroup }
																groupMode={ groupMode }

																onChangeFieldForm={ onChangeFieldForm }
																onHandleCheckGroup={ onHandleCheckGroup }
																getListStyle={ getListStyle }
																getItemStyle={ getItemStyle }
															/>
														)
													}
													{
														json.type === 'field' && (
															<RenderedField 
																editIndex={{
																	cardIndex: null,
																	contentContext: 'content',
																	fieldIndex: jsonIndex
																}}
																formData={ formData }
																mode={ 'edit' } index={ jsonIndex }
																duplicatedKeyError={ duplicatedKeyError }
																currentLang={ currentLang }
																selectedEdit={ selectedEdit }
																hasGroupedInCard={ false }
																selectedToGroup={ selectedToGroup }
																groupMode={ groupMode }
																json={ json }
																onClickRemove={ () => {
																	setSelectedDelete( json.key )
																	setDeletePath({
																		cardIndex: null,
																		contentContext: 'content',
																		fieldIndex: jsonIndex
																	})
																}}
																onClickField={ () => {
																	onHandleChangeSelectedEdit({
																		cardIndex: null,
																		contentContext: 'content',
																		fieldIndex: jsonIndex
																	})
																}}
																onChangeFieldForm={( key, val ) => onChangeFieldForm( key, val, jsonIndex )}
																onHandleCheckGroup={ onHandleCheckGroup }
															/>
														)
													}
												</div>
											)}
										</Draggable>
									))
								}
								{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
					<RenderedButton
						groupMode={ groupMode }
						currentLang={ currentLang }
						selectedEdit={ selectedEdit }
						buttonProps={ formData.form_content.submit_button }
						buttonKey={ 'submit_button' }
						onClickEditButton={ () => onHandleChangeSelectedEdit( 'submit_button' )}
					/>

					<div 
						className={ `d-flex align-items-center transition_200 ${ !groupMode ? 'bkp-form-renderer-title-hover' : '' }` }
						style={{
							... selectedEdit === 'title' && {
								boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
								border: '1px solid #467bc6',
								borderStyle: 'dotted'
							}
						}}
					>
						<div 
							className={ `mb-2` }
							style={{ fontWeight: 600 }}
						>
							{
								ReactHTMLParser( formData.form_content.term_content.label[ currentLang ] )
							}
						</div>
						{
							!groupMode && (
								<CustomButton
									type={ 'edit' }
									onClick={() => onHandleChangeSelectedEdit( 'term_content' )}
								/>
							)
						}
					</div> 
				</CardBody>
			</Card>
			<div style={{ float:"left", clear: "both" }} ref={ cardBottomRef }/>
		</>
	)
}

export default FormRenderer