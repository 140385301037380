import React, { useState } from 'react'
import {
  FormGroup,
  Label, Button, Input
} from 'reactstrap'

import i18n from 'i18next'
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import TransComponent from 'components/Trans'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
const AddTypeModal = ({
  types = [],
  onLoadCaseTypes,

  onClickClose,
  onClickSubmit
}) => {
  const [ tempType, onChangeType ] = useState( '' )
  return (
    <>
      <ModalHeader toggle={ () => onClickClose() }>
        Add New Case Type
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>
            Type
          </Label>
          <Input
            type={ 'select' }
            value={ tempType }
            onChange={ e => {
              onChangeType( e.target.value )
            }}>
            <option></option>
            { types.map( data => (
              <option key={ data.id } value= { data.id }>
                { i18n.language === 'de' ? data.de_name : data.en_name }
              </option>
            ))}
          </Input>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button 
          disabled={ !tempType }
          color="primary" 
          onClick={() => onClickSubmit( tempType )}
        >
          <TransComponent i18nKey="general.submit"/>
        </Button>
      </ModalFooter>
      { onLoadCaseTypes && <LoadingOverlay/> }
    </>
  )
}


export default AddTypeModal;