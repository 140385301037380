import React, { useEffect, useState } from 'react'
import { 
  Input, FormGroup, Label,
  ModalBody, ModalHeader, ModalFooter,
  Button
} from 'reactstrap'

import TransComponent from 'components/Trans'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'

export default function EditRemark ({
  data,
  selectedCase,
  selectedRemark,
  onLoadRemark,

  updateRemark,
  onClose
}) {

  const [ tempRemark, setRemark ] = useState( '' )
  useEffect(() => {
    setRemark( selectedRemark.content )
  }, [selectedRemark])

  return (
    <>
      <ModalHeader toggle={ () => onClose()}>
        <TransComponent i18nKey={ 'lawyer_case.title.edit_remark' }/>
      </ModalHeader>
      <ModalBody>
        <div className='d-flex mb-3 flex-wrap'>
          <span>
            <small>
              <TransComponent i18nKey={ 'lawyer_case.fields.written_by' }/>:
            </small>
            <br />
            <strong>
              { selectedRemark?.user??'' }
            </strong>
          </span>
          <span className='ml-auto mr-2' style={{ textAlign: 'end' }}>
            <small>
              <TransComponent i18nKey={ 'lawyer_case.fields.last_modified' }/>:
            </small>
            <br />
            <strong>
              { selectedRemark?.updated_at??'' }
            </strong>
          </span>
        </div>
        <hr />
        <FormGroup>
          <Label>
            <TransComponent i18nKey={ 'lawyer_case.fields.remark_desc' }/>
          </Label>
          <Input
            type={ 'textarea'}
            value={ tempRemark } 
            rows={ 15 }
            disabled={ selectedCase.is_closed || data.ProfileReducer.profile.id !== selectedRemark.user_id }
            onChange={ e => setRemark( e.target.value )}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          disabled={ 
            selectedCase.is_closed || 
            data.ProfileReducer.profile.id !== selectedRemark.user_id ||
            !tempRemark
          }
          onClick={() => updateRemark(
            selectedCase.id, 
            selectedRemark.id,
            {
              user_id: data.ProfileReducer.profile.id,
              content: tempRemark
            }
          )}>
          <TransComponent i18nKey="general.submit"/>
        </Button>
      </ModalFooter>
      { onLoadRemark && <LoadingOverlay/> }
    </>
  )
}
