import React, { Component } from 'react'
import { connect } from 'react-redux'

import Axios from 'axios'
import { storeItem } from 'utils/tokenStore';
import getDomainURL from 'utils/api-non-login'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      loading: false,
      showPassword: false,
      errorMessage: ''
    }

    onChangeHOC = ( val, context ) => this.setState({ [context]: val })

    onClickLogin = dataToSubmit => {
      this.setState({ loading: true }, () => {
        Axios.post(
        `${ getDomainURL() }/case_login`, 
        dataToSubmit
        ).then( response => {
            this.setState({ loading: false })
            storeItem( 'BPK_ACCESS_TOKEN', response.data.token )
            storeItem( 'BPK_USER_TYPE', 'case' )
            this.props.history.push( '/dashboard/case-mngmt' )
          }).catch( err => {
            this.setState({ 
              errorMessage: err.response?.data?.message??'This combination of Case No. and password is incorrect.', 
              loading: false 
            })
        });
      })
    }

    render = () => {
      return (
        <WrappedComponent 
          { ... this.props }
          showPassword={ this.state.showPassword }
          errorMessage={ this.state.errorMessage }
          onLoadLogin={ this.state.loading }
          onChangeHOC={ this.onChangeHOC }
          onClickLogin={ this.onClickLogin }/>
      )
    }
  }
  const mapStateToProps = state => ({ data: state })
  return connect( mapStateToProps )( WithHOC );
}
export default HOC