import React, { Component } from 'react'
import { toast } from 'react-toastify'

import { Get } from 'utils/axios'

const HOC = ( WrappedComponent ) => {
  class WithHOC extends Component {
    state = {
      onLoadForm: false,
      forms: []
    }

    onChangeHOC = ( key, val ) => this.setState({ [key]: val })

    load = param => this.setState({ onLoadForm: param })
    requestError = error => toast.error( error )
    requestSuccess = success => toast.success( success )

    getForms = () => Get( 
      `/forms`,
      this.getFormsSuccess,
      this.getFormsError,
      this.load
    )
    getFormsSuccess = payload => this.setState({ forms: payload.data })
    getFormsError = error => this.requestError( error )

    render = () => {
      return (
        <WrappedComponent
          { ...this.props } 
          forms={ this.state.forms }
          onLoadForm={ this.state.load }

          getForms={ this.getForms }
        />
      )
    }
  }
  return WithHOC
}

export default HOC