import React, { useEffect, useState } from 'react'
import { 
  Form,
  Row, Col,
  FormGroup, Label, Input,
  ModalHeader, ModalBody
} from 'reactstrap'
import _ from 'lodash'
import TransComponent from 'components/Trans'

export const DefaultForm = {
  name: '',
  contact: '',
  locale: 'de'
}

export const DefaultFields = [
  { 
    required: true, value: 'name'
  },
  { 
    required: true, value: 'contact'
  },
  { 
    required: true, value: 'unique_token'
  },
  { 
    required: true, value: 'locale'
  }
]

const FormContent = ({
  selectedCompany,

  onClose
}) => {
  const [ companyForm, onChangeCompanyForm ] = useState( DefaultForm )

  useEffect(() => {
    onChangeCompanyForm( selectedCompany )
  }, [ selectedCompany ])
  
  return (
    <>
      <ModalHeader toggle={ () => onClose()}>
          <TransComponent i18nKey={ `lawyer_case.fields.company_card` }/>  
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={ e => e.preventDefault()}>
            <Row>
              {
                DefaultFields.map(( field, index ) => (
                  <Col key={ `field-${ index }`} md={ 12 }>
                    <FormGroup>
                      <Label className={ 'd-flex' }>
                        <TransComponent i18nKey={ `company.fields.${ field.transKey ? field.transKey : field.value }`}/>
                      </Label>
                      <Input
                        disabled={ true }
                        value={ companyForm[ field.value ] }
                      />
                    </FormGroup>
                  </Col>
                ))
              }
            </Row>
          </Form>
        </ModalBody>
    </>
  )
}

export default FormContent