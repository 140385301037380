import React, { useEffect, useState } from 'react'
import { 
  ModalBody, ModalFooter, ModalHeader,
  Input, Label, 
  Button, FormGroup
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import TransComponent from 'components/Trans'

export default function AddLawyer({
  onLoadUser,
  lawyerOptions,
  selectedCase,
  caseLawyer,

  getLawyer,
  addCaseLawyer,
  onClose
}) {

  const [ selectedLawyer, onChangeLawyer ] = useState( null )
  const [ tempOptions, onChangeOption ] = useState([])

  useEffect(() => {
    let temp = []

    lawyerOptions.map( option => {
      _.findIndex( caseLawyer, lawyer => lawyer.user_id === option.id ) < 0 && 
      temp.push( option )
    })
    onChangeOption( temp )
  }, [lawyerOptions])

  useEffect(() => {
    getLawyer()
  }, [])

  return (
    <>
      <ModalHeader toggle={ () => onClose()}>
        <TransComponent i18nKey={ 'lawyer_case.title.create_lawyer' }/>
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>
            <TransComponent i18nKey={ 'lawyer_case.fields.select_lawyer' }/>
          </Label>
          <Input
            type={ 'select' }
            value={ selectedLawyer }
            onChange={ e => onChangeLawyer( +e.target.value )}
          >
            <option value={ 0 }></option>
            { tempOptions.map( lawyer => (
              <option key={ lawyer.id } value= { lawyer.id }>{ `${ lawyer.first_name } ${ lawyer.last_name }` }</option>
            ))}
          </Input>
        </FormGroup>
        { onLoadUser && <LoadingOverlay/> }
      </ModalBody>
      <ModalFooter>
        <Button 
          disabled={ !selectedLawyer }
          color="primary" 
          onClick={() => addCaseLawyer( selectedCase.id, selectedLawyer )}
        >
          <TransComponent i18nKey="general.submit"/>
        </Button>
      </ModalFooter>
    </>
  )
}
