import React, { useState, useCallback, useMemo } from 'react'
import { 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  Row, Col,
  FormText,
  FormGroup, Label, Input,
} from 'reactstrap'
import i18n from 'i18next'
import _ from 'lodash'
import { registerPlugin } from "react-filepond"
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css"

import TransComponent from 'components/Trans'
import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import { DefaultFields, DefaultForm } from './asset'

registerPlugin( FilePondPluginImageExifOrientation, FilePondPluginImagePreview )

const FormContent = ({
  onLoadCompanies,
  caseCategory,
  forms,

  onClose,
  onClickSubmit
}) => {
  const [ companyForm, onChangeCompanyForm ] = useState({
    ... DefaultForm,
    form_id: null
  })
  
  let disableSubmit = useMemo(() => {
    return _.keys( companyForm ).some( key => {
      if ( key === 'form_id' ){
        return false
      }
      
      return !companyForm[ key ]
    })
  }, [companyForm])

  const onChangeField = useCallback(( context, val ) => {
    onChangeCompanyForm({
      ... companyForm,
      [context]: val
    })
  }, [companyForm])
  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        <TransComponent i18nKey={ `company.title.create`}/>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <Row>
            {
              DefaultFields.map(( field, index ) => (
                <Col key={ `field-${ index }`} lg={ field.col || 12 }>
                  <FormGroup>
                    <Label className={ 'd-flex' }>
                      <TransComponent i18nKey={ `company.fields.${ field.transKey ? field.transKey : field.value }`}/>
                      <span className="text-danger">*</span>
                    </Label>
                    {
                      field.type === 'text' && (
                        <Input
                          type={ field.type === 'password' ? field.currentType : field.type }
                          value={ companyForm[ field.value ] }
                          onChange={ e => {
                            onChangeField( field.value, e.target.value )
                          }} 
                        />
                      )
                    }
                    {
                      field.type === 'select' && (
                        <Input
                          type={ 'select' }
                          value={ companyForm[ field.value ] }
                          onChange={ e => onChangeField( field.value, e.target.value )}
                        >
                          <option value= { '' }>{ '' }</option>
                          {( field.value === 'locale' ? [ 'en', 'de' ] : caseCategory ).map( option => (
                            <>
                              {
                                field.value === 'locale' && (
                                  <option key={ option } value= { option }>{ option }</option>
                                )
                              }
                              {
                                field.value !== 'locale' && (
                                  <option key={ option.id } value= { option.id }>
                                    { i18n.language === 'de' ? option.de_name : option.en_name }
                                  </option>
                                )
                              }
                            </>
                          ))}
                        </Input>
                      )
                    }
                  </FormGroup>
                </Col>
              ))
            }
            <Col lg={ 6 }>
              <FormGroup>
                <Label>
                  <TransComponent i18nKey={ `general.form` }/>
                </Label>
                <Input 
                  type='select'
                  value={ companyForm.form_id }
                  onChange={ e => onChangeField( 'form_id', +e.target.value )}
                >
                  <option value=""></option>
                  {
                    forms.map( formChild => (
                      <option key={ `formOption${ formChild.id }`} value={ formChild.id }>
                        { formChild.company_name }
                      </option>
                    ))
                  }
                </Input>
                <FormText>
                  <TransComponent i18nKey={ `company.desc.form_template` }/>
                </FormText>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary"
          className="mr-2"
          disabled={ disableSubmit }
          onClick={() => onClickSubmit( companyForm )
        }>
          <TransComponent i18nKey={ `general.submit`}/>
        </Button>
      </ModalFooter>
      { onLoadCompanies && <LoadingOverlay/> }
    </>
  )
}

export default FormContent