import { Button } from 'reactstrap'
import { MdModeEditOutline } from 'react-icons/md'
import { BsFillTrashFill } from 'react-icons/bs'

const EditButton = ({
  type,
  onClick
}) => {
  return (
    <Button 
      color={ type === 'delete' ? 'danger' : 'warning' } 
      className='ml-2'
      style={{ borderRadius: '50%', padding: '0.125rem 0.3rem', height: 'fit-content' }} 
      onClick={ () => onClick()}
    >
      {
        type === 'edit' && (
          <MdModeEditOutline className='text-white' style={{ width: 13, height: 13 }}/>
        ) 
      }
      {
        type === 'delete' && (
          <BsFillTrashFill className='text-white' style={{ width: 13, height: 13 }}/>
        )
      }
    </Button>  
  )
}

export default EditButton;