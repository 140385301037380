import React from 'react'
import { Button, Card, CardBody } from 'reactstrap'
import { TabEditOptions } from '../../assets'

import TransComponent from 'components/Trans'

export default function TabSelection({
  currentTab,
  setCurrentTab
}) {
  
  return (
    <Card className='mb-5'>
      <CardBody>
        <h5 style={{ fontSize: '0.8rem' }} className='mb-3'>
          <TransComponent i18nKey={ 'form.title.edit_tab' }/>
        </h5>
        <div className="bkp-form-tab-selection-grid">
        {
          TabEditOptions.map( item => (
            <button 
              key={ item.value}
              className={ `bkp-form-tab-selection-btn ${ currentTab === item.value ? 'bkp-form-tab-selection-btn-selected' : '' }` }
              onClick={ () => setCurrentTab( item.value )}
            >
              { item.icon }
              <p>
                <TransComponent i18nKey={ `form.desc.${ item.value }` }/>
              </p>
            </button>
          ))
        }
        </div>
      </CardBody>
    </Card>
  )
}
