import { GET_PROFILE } from 'actions/type'
import Axios from 'axios'
import { clearItem, getItem } from 'utils/tokenStore'
import {
  beginAjaxCall,
  ajaxCallError,
  ajaxCallSuccess
} from './ajax'
import getDomainURL from 'utils/api'

export const getProfile = () => dispatch => {
  dispatch( beginAjaxCall() )
  Axios.defaults.headers = {
    common : {
      Authorization: `Bearer ${ getItem( 'BPK_ACCESS_TOKEN' ) }`
    },
    'Access-Control-Allow-Origin': '*'
  }
  Axios.get(
    `${ getDomainURL() }/users/profile`
  ).then( response => {
    dispatch( getProfileSuccess( response.data ) )
    dispatch( ajaxCallSuccess() )
  }).catch( error => {
    if( error.response.status === 401 ){
      alert( 'Due to inactivity, your session has expired, please login again.' )
      clearItem( 'BPK_ACCESS_TOKEN' )
      window.location.reload()
    }
    dispatch( ajaxCallError( error.response.data.message ) );
  })
}

export const getProfileSuccess = payload => ({
  type: GET_PROFILE,
  payload
})