import React, { useEffect, useState, useCallback } from 'react'
import { 
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import _ from 'lodash'

import LoadingOverlay from 'components/Indicator/LoadingOverlay'
import TransComponent from 'components/Trans'

const Create = ({
  selectedType,
  mode,
  onLoadTypes,

  onClickClose,
  onClickSubmit
}) => {

  const [caseType, onChangeCaseType] = useState({
    en: '',
    de: ''
  })

  useEffect(() => {
    selectedType && onChangeCaseType ({
      en: selectedType.en_name,
      de: selectedType.de_name
    })
  }, [])

  const onChangeField = useCallback(( context, val ) => {
    onChangeCaseType({
      ... caseType,
      [context]: val
    })
  }, [caseType])

  return (
    <>
      <ModalHeader style={{ textTransform: 'capitalize' }} toggle={() => onClickClose()}>
        <TransComponent i18nKey={ `case_type.title.${ mode }` }/>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={ e => e.preventDefault()}>
          <FormGroup>
            <Label>
              <TransComponent i18nKey="case_type.fields.de_name"/>
              <span className="text-danger"> *</span>
            </Label>
            <Input
              type="text"
              value={ caseType.de }
              onChange={ e => onChangeField( 'de', e.target.value )} />
          </FormGroup>
          <FormGroup>
            <Label>
              <TransComponent i18nKey="case_type.fields.en_name"/>
              <span className="text-danger"> *</span>
            </Label>
            <Input
              type="text"
              value={ caseType.en }
              onChange={ e => onChangeField( 'en', e.target.value )} />
          </FormGroup>
        </Form>
        { onLoadTypes && <LoadingOverlay /> }
      </ModalBody>
      <ModalFooter>
        <Button 
          color="primary" 
          onClick={() => onClickSubmit({
            name: caseType
          })}
        >
          <TransComponent i18nKey="general.submit"/>
        </Button>
      </ModalFooter>
    </>
  )
}

export default Create